import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import TutorialVideo from "../components/TutorialVideo";

class Tutorial extends React.Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Tutorial</Typography>
            <br />
            <Typography align="center" variant="body1">
              This two minute tutorial gives you a high level overview of
              Winston.
            </Typography>
          </Grid>
          <Grid container justify="center" style={{ marginTop: 20 }}>
            <div style={{ height: 280, width: 500 }}>
              <TutorialVideo
                analyticsLabel={"Onboarding"}
                url={this.props.videoUrl}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

Tutorial.propTypes = {
  videoUrl: PropTypes.string,
};

export default withRouter(Tutorial);
