import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import {
  getAllDaysFromRange,
  getAllWeeksFromRange,
  getDateThreeMonthsAgo,
  getPercentageChange,
  getRandomInt,
  getRandomIndex,
  getWeekMapKey,
} from "../utils/utils";

export const MOMENT_FORMAT = "YYYY-MM-DD";
const FAKE_PROJECT_NAMES = ["Learn React", "LeetCode", "Winston"];

export const FILTER_OPTIONS = {
  WEEK: 7,
  TWO_WEEKS: 14,
  MONTH: 30,
  ALL_TIME: null,
};

/**
 * Generates fake data used for demo
 */
export const generateFakeData = () => {
  const sessions = [];
  const tasks = [];
  /* Get date range from 6 months ago */
  const allDates = getAllDaysFromRange({
    earliestDate: getDateThreeMonthsAgo(),
    latestDate: new Date(),
  });
  /* Loop through date range */
  allDates.forEach((date) => {
    /* Create b/w 0-3 sessions each day */
    const numSessions = getRandomInt({ max: 3, min: 1 });
    for (let i = 0; i < numSessions; i++) {
      let startMax;
      let startMin;
      /* TO DO -- delete this, start time doesn't even matter I don't think... */
      if (i === 0) {
        startMax = 10;
        startMin = 8;
      } else if (i === 1) {
        startMax = 16;
        startMin = 14;
      } else {
        startMax = 20;
        startMin = 18;
      }
      const sessionId = uuidv4();
      const startTime = moment(date).set({
        hour: getRandomInt({ max: startMax, min: startMin }),
        minute: getRandomInt({ max: 59, min: 0 }),
      });
      const endTime = moment(startTime.toDate())
        .add(getRandomInt({ max: 2, min: 0 }), "hours")
        .add(getRandomInt({ max: 59, min: 5 }), "minutes");
      /* Create 1-3 tasks per session */
      const randomTasks = generateRandomTasks({
        numTasks: getRandomInt({ max: 3, min: 1 }),
        sessionId,
        startTime,
      });
      sessions.push({
        endTime: firebase.firestore.Timestamp.fromDate(endTime.toDate()),
        projects: randomTasks.map(({ projectName }) => projectName),
        /* TO DO -- does this matter? */
        sessionId,
        startTime: firebase.firestore.Timestamp.fromDate(startTime.toDate()),
        taskIds: randomTasks.map(({ id }) => id),
      });
      tasks.push(...randomTasks);
    }
  });
  /* Generate random tasks + taskIds */
  return {
    sessions,
    tasks,
  };
};

const generateRandomTasks = ({ numTasks, sessionId, startTime }) => {
  const tasks = [];
  for (let i = 0; i < numTasks; i++) {
    /* TO DO -- do I need to give diff start times? */
    const task = generateRandomTask({ sessionId, startTime });
    tasks.push(task);
  }
  return tasks;
};

const generateRandomTask = ({ sessionId, startTime }) => {
  return {
    actualTime: getRandomInt({
      max: 3700,
      min: 1000,
    }),
    completed: true,
    date: firebase.firestore.Timestamp.fromDate(startTime.toDate()),
    difficulty: getRandomInt({ max: 10, min: 2 }),
    focus: getRandomInt({ max: 10, min: 2 }),
    /* TO DO -- does this matter? */
    id: uuidv4(),
    notes: "Should have done XYZ",
    projectName: FAKE_PROJECT_NAMES[getRandomIndex(FAKE_PROJECT_NAMES.length)],
    /* TO DO -- does this matter? */
    sessionId,
    showTask: true,
    /* This value doesn't matter */
    time: 1800,
    value: "[UX] Improve delete button + save changes.",
  };
};

/**
 * Process task data for hours per day. This data
 * will be used to create line and stacked graphs.
 */
export const getHoursPerDayData = ([...tasks]) => {
  const hoursPerDayMap = { Overall: {} };
  // const focusPerDayMap
  const filteredTasks = tasks.filter((t) => t.date);
  /* https://stackoverflow.com/questions/11526504/minimum-and-maximum-date */
  let earliestDate = new Date(8640000000000000);
  /* For each task */
  filteredTasks.forEach((task) => {
    /* Count time for that task */
    const {
      actualTime,
      date: timeStamp,
      difficulty,
      focus,
      projectName = "",
    } = task;
    if (isNaN(actualTime)) {
      return;
    }
    /* Found new earliest date */
    if (timeStamp.toDate() < earliestDate) {
      earliestDate = timeStamp.toDate();
    }
    let date = moment(timeStamp.toDate()).format(MOMENT_FORMAT);
    /**
     * Update overall time for hours per day map
     * Map will look like this:
     * {
     *    deepWorkTool: {
     *      [11/2/2020]: {
     *        actualTime: 1000,
     *        focus: [9, 3]
     *      },
     *      [11/3/2020]: {
     *        actualTime: 2000
     *        focus: [3, 7]
     *      },
     *    },
     *    Overall: {
     *      ...
     *    }
     * }
     */
    if (hoursPerDayMap.Overall[date]) {
      hoursPerDayMap.Overall[date].actualTime += actualTime;
      if (focus && difficulty) {
        hoursPerDayMap.Overall[date].focus.push(focus);
        hoursPerDayMap.Overall[date].difficulty.push(difficulty);
      }
    } else {
      hoursPerDayMap.Overall[date] = {
        actualTime,
        focus: focus ? [focus] : [],
        difficulty: difficulty ? [difficulty] : [],
      };
    }
    /* Update project name for hours per day map */
    if (projectName) {
      /* Add property if it doesn't exist */
      if (!hoursPerDayMap[projectName]) {
        hoursPerDayMap[projectName] = {};
      }
      if (hoursPerDayMap[projectName][date]) {
        hoursPerDayMap[projectName][date].actualTime += actualTime;
      } else {
        hoursPerDayMap[projectName][date] = {
          actualTime,
        };
      }
    }
  });
  const difficultyData = [];
  const focusData = [];
  const hoursPerDayData = {};
  /* Pad dates for graphing */
  const allDates = getAllDaysFromRange({
    earliestDate,
    latestDate: new Date(),
  }).map((d) => moment(d).format(MOMENT_FORMAT));
  for (const date of allDates) {
    for (const [, obj] of Object.entries(hoursPerDayMap)) {
      if (!obj[date]) {
        obj[date] = {
          actualTime: 0,
          difficulty: [0],
          focus: [0],
        };
      }
    }
  }
  /**
   * {
   *    deepWorkTool: {
   *      [11/2/2020]: 1000,
   *      [11/3/2020]: 2000,
   *    }
   * }
   * ==>
   * {
   *    deepWorkTool: [
   *      {
   *        date: 11/2/2020,
   *        hours: 1,
   *      },
   *      {
   *        date: 11/3/2020,
   *        hours: 2
   *      }
   *    ]
   * }
   */
  const availColors = [
    /* https://material.io/design/material-studies/rally.html#color */
    "#1EB980",
    "#42C6FF",
    "#A932FF",
    "#FFDC78",
    "#FF857C",
    "#B6FFF2",
    /* Hopefully this won't be used! https://learnui.design/tools/data-color-picker.html */
    "#0000ff",
    "#665191",
    "#a05195",
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
  ];
  const colorMap = {};
  for (const [projectName, obj] of Object.entries(hoursPerDayMap)) {
    for (const [date, data] of Object.entries(obj)) {
      if (!hoursPerDayData[projectName]) {
        hoursPerDayData[projectName] = [];
      }
      if (!colorMap[projectName]) {
        colorMap[projectName] = availColors.shift();
      }
      hoursPerDayData[projectName].push({
        date: moment(date).toDate(),
        hours: data.actualTime / 3600,
      });
      /* Calc focus/difficulty data */
      if (projectName === "Overall") {
        if (data.focus.length && data.difficulty.length) {
          focusData.push({
            date: moment(date).toDate(),
            focus:
              data.focus.reduce((acc, val) => acc + val, 0) / data.focus.length,
          });
          difficultyData.push({
            date: moment(date).toDate(),
            difficulty:
              data.difficulty.reduce((acc, val) => acc + val, 0) /
              data.difficulty.length,
          });
        } else {
          focusData.push({
            date: moment(date).toDate(),
            focus: 0,
          });
          difficultyData.push({
            date: moment(date).toDate(),
            difficulty: 0,
          });
        }
      }
    }
  }
  /* Map and sort */
  for (const [, arr] of Object.entries(hoursPerDayData)) {
    arr.sort((a, b) => a.date - b.date);
  }
  focusData.sort((a, b) => a.date - b.date);
  return {
    colorMap,
    difficultyData,
    focusData,
    hoursPerDayData: { ...hoursPerDayData },
  };
};

export const getHoursPerWeekData = ([...tasks]) => {
  const hoursPerWeekMap = { Overall: {} };
  const filteredTasks = tasks.filter((t) => t.date);
  /* https://stackoverflow.com/questions/11526504/minimum-and-maximum-date */
  let earliestDate = new Date(8640000000000000);
  /* For each task */
  filteredTasks.forEach((task) => {
    /* Count time for that task */
    const { actualTime, date: timeStamp, projectName = "" } = task;
    /* Found new earliest date */
    if (timeStamp.toDate() < earliestDate) {
      earliestDate = timeStamp.toDate();
    }
    /**
     * Update map that tracks hours per week
     * Map will look like this:
     * {
     *    deepWorkTool: {
     *      [11/2/2020]: 1000,
     *      [11/9/2020]: 2000,
     *    },
     *    Overall: {
     *      [11/2/2020]: 1000,
     *      [11/9/2020]: 2000,
     *    }
     * }
     */
    const date = getWeekMapKey(timeStamp.toDate());
    /* Update overall time */
    if (hoursPerWeekMap.Overall[date]) {
      hoursPerWeekMap.Overall[date] += actualTime;
    } else {
      hoursPerWeekMap.Overall[date] = actualTime;
    }
    if (projectName) {
      /* Add property if it doesn't exist */
      if (!hoursPerWeekMap[projectName]) {
        hoursPerWeekMap[projectName] = {};
      }
      if (hoursPerWeekMap[projectName][date]) {
        hoursPerWeekMap[projectName][date] += actualTime;
      } else {
        hoursPerWeekMap[projectName][date] = actualTime;
      }
    }
  });
  const hoursPerWeekData = {};
  /**
   * {
   *    deepWorkTool: {
   *      [11/2/2020]: 1000,
   *      [11/3/2020]: 2000,
   *    }
   * }
   * ==>
   * {
   *    deepWorkTool: [
   *      {
   *        date: 11/2/2020,
   *        hours: 1,
   *      },
   *      {
   *        date: 11/3/2020,
   *        hours: 2
   *      }
   *    ]
   * }
   */
  /* Pad dates for graphing */
  const allWeeks = getAllWeeksFromRange({
    earliestDate,
    latestDate: new Date(),
  });
  for (const date of allWeeks) {
    for (const [, obj] of Object.entries(hoursPerWeekMap)) {
      if (!obj[date]) obj[date] = 0;
    }
  }
  for (const [projectName, obj] of Object.entries(hoursPerWeekMap)) {
    for (const [date, seconds] of Object.entries(obj)) {
      if (!hoursPerWeekData[projectName]) {
        hoursPerWeekData[projectName] = [];
      }
      hoursPerWeekData[projectName].push({
        date: moment(date).toDate(),
        hours: seconds / 3600,
      });
    }
  }
  /* Map and sort */
  for (const [, arr] of Object.entries(hoursPerWeekData)) {
    arr.sort((a, b) => a.date - b.date);
  }
  return { ...hoursPerWeekData };
};

export const getHoursPerMonthData = (hoursPerDayData, numMonths) => {
  /* Use "Overall" key */
  const overallData = hoursPerDayData["Overall"];
  const hoursPerMonthMap = {};
  /* Loop thru array and add to map accordingly */
  overallData.forEach(({ date, hours }) => {
    /* Get month/year from moment */
    const key = moment(date).format("MM-YYYY");
    if (hoursPerMonthMap[key]) {
      hoursPerMonthMap[key] += hours;
    } else {
      hoursPerMonthMap[key] = hours;
    }
  });
  const monthArr = Object.keys(hoursPerMonthMap);
  const hoursPerMonthData = monthArr.map((key, index) => ({
    dateStr: key,
    hours: hoursPerMonthMap[key].toFixed(1),
    percentageChange: monthArr[index - 1]
      ? getPercentageChange({
          newNum: hoursPerMonthMap[key],
          originalNum: hoursPerMonthMap[monthArr[index - 1]],
        }).toFixed(1)
      : 0,
  }));
  return hoursPerMonthData.length > numMonths
    ? hoursPerMonthData.splice(hoursPerMonthData.length - numMonths)
    : hoursPerMonthData;
};

export const getAvgSessionPerWeekData = ([...sessions]) => {
  const weekMap = {};

  /* Filter by making sure start and end time exist */
  const filteredSessions = sessions.filter(
    ({ endTime, startTime }) => endTime && startTime
  );

  let earliestDate = new Date(8640000000000000);
  filteredSessions.forEach(({ endTime, startTime }) => {
    /* Found new earliest date */
    if (startTime.toDate() < earliestDate) {
      earliestDate = startTime.toDate();
    }

    const durationMs = endTime.toDate() - startTime.toDate();
    const key = getWeekMapKey(startTime.toDate());

    /* Add to weekMap array */
    if (!weekMap[key]) {
      weekMap[key] = {
        duration: 0,
        numSessions: 0,
      };
    }
    weekMap[key].duration += durationMs;
    weekMap[key].numSessions += 1;
  });

  /* Pad dates for graphing */
  // if (moment().isSame(moment(earliestDate), "week")) {
  //   earliestDate = moment().subtract(1, "month").toDate();
  // }
  const allWeeks = getAllWeeksFromRange({
    earliestDate,
    latestDate: new Date(),
  });
  for (const date of allWeeks) {
    const key = getWeekMapKey(date);
    if (!weekMap[key]) {
      weekMap[key] = {
        duration: 0,
        numSessions: 1,
      };
    }
  }

  /* Go thru each week */
  const avgSessionArr = [];
  for (const [date, data] of Object.entries(weekMap)) {
    const avgDuration = data.duration / data.numSessions;
    avgSessionArr.push({
      date: moment(date).toDate(),
      hours: avgDuration / (1000 * 3600),
    });
  }
  avgSessionArr.sort((a, b) => a.date - b.date);
  return [...avgSessionArr];
};

/**
 * Return Overall's array from this structure:
 *
 * {
 *    overall: [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *    ]
 * }
 *
 * ==>
 * [
 *   [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *    ]
 *  ]
 */
export const filterLineGraphData = ({ numDays, overallData }) => {
  /* Handle no data case */
  if (!overallData.Overall) {
    return [];
  }
  if (numDays === null) {
    return overallData.Overall.map((data) => ({ ...data }));
  } else {
    const endDate = moment().subtract(numDays, "days").toDate();
    /* Filter each internal array by date */
    return overallData.Overall.map((data) => ({ ...data })).filter(
      (data) => data.date > endDate
    );
  }
};

/**
 * Create one array per project from this structure:
 *
 * {
 *    deepWorkTool: [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *    ],
 *    overall: [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *    ]
 * }
 *
 * ==>
 * [
 *   [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *   ],
 *   [
 *      {
 *        date: 11/2/2020,
 *        hours: 1,
 *      },
 *      ...
 *    ]
 *  ]
 */
export const filterStackGraphData = ({ colorMap, numDays, overallData }) => {
  let selectedData = [],
    legend = [];
  if (numDays === null) {
    for (const [projectName, dataArr] of Object.entries(overallData)) {
      if (projectName !== "Overall") {
        /* Push the array of dates & hours */
        selectedData.push(dataArr);
        /* Add to legend */
        legend.push(projectName);
      }
    }
  } else {
    const endDate = moment().subtract(numDays, "days").toDate();
    for (const [projectName, dataArr] of Object.entries(overallData)) {
      if (projectName !== "Overall") {
        /* Filter each internal array by date */
        const filtered = dataArr.filter((data) => data.date > endDate);
        /* Check if time was spent */
        const timeSpent = filtered.some(({ hours }) => hours > 0);
        /* Push the array of dates & hours if there are any */
        timeSpent && selectedData.push(filtered);
        /* Keep legend in sync */
        timeSpent &&
          legend.push({
            fill: colorMap[projectName],
            projectName,
          });
      }
    }
  }
  return { legend, selectedData };
};

export const filterAvgSessionPerWeekLineGraph = ({ numDays, overallData }) => {
  if (numDays === null) {
    return [...overallData];
  } else {
    const endDate = moment().subtract(numDays, "days").toDate();
    /* Filter each internal array by date */
    return overallData.filter((data) => data.date > endDate);
  }
};
