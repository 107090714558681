import React from "react";
import ReactPlayer from "react-player";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { fetchMediaURL } from "../firebase";

import Footer from "./Footer";
import { getMuiPropTypes } from "../utils/utils";

const styles = () => ({
  bodyText: {
    fontSize: "1.1rem",
    paddingBottom: 10,
  },
});

class DeepWorkInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      urlFullTutorial: "",
    };
  }

  async componentDidMount() {
    const urlFullTutorial = await fetchMediaURL(
      "media/full-tutorial-final.mp4"
    );
    this.setState({
      urlFullTutorial,
    });
  }

  getSignUpBtnText = () => {
    return (
      <Grid
        alignItems="center"
        container
        onClick={() => this.props.history.push("/signup")}
        style={{ cursor: "pointer" }}
      >
        <Typography
          align="left"
          style={{ color: "#1EB980", fontSize: "1rem" }}
          variant="body1"
        >
          <b>Try Winston for Free</b>
        </Typography>
        <ArrowForwardIosIcon
          style={{
            color: "#1EB980",
            fontSize: "0.9rem",
            marginLeft: 2,
          }}
        />
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="md">
        <Grid container>
          <Grid item sm={1} xs={false}></Grid>
          <Grid item sm={10} xs={12}>
            <Typography
              style={{ fontWeight: 600, paddingBottom: 20 }}
              variant="h4"
            >
              What is Deep Work?
            </Typography>
            <Typography align="left" className={classes.bodyText}>
              Cal Newport, who coined the term, and defines it as:
              <br />
              <br />
              <i>
                {`"Professional activity performed in a state of distraction-free concentration that push your cognitive capabilities to their limit. These efforts create new value, improve your skill, and are hard to replicate."`}
              </i>
              <br />
              <br />
              In other words, deep work is high value, challenging work that is
              accomplished by blocking off periods of time to focus without
              distraction. This idea has been discussed countless times:
              <ul>
                <li>
                  Paul Graham{" "}
                  <a
                    href="http://www.paulgraham.com/makersschedule.html"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  about maker's schedules vs manager's schedules, explaining
                  that creative tasks <b>require</b> long periods of focused
                  attention each day.
                </li>
                <li>
                  Tim Ferriss{" "}
                  <a
                    href="https://tim.blog/2013/11/03/productivity-hacks/"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  about blocking of 2-3 hours per day to work on your most
                  important tasks. He expands on this idea further in{" "}
                  <a href="https://amzn.to/36OTqWP" style={{ color: "white" }}>
                    The 4-Hour Work Week
                  </a>
                  .
                </li>
                <li>
                  {" "}
                  Cal Newport{" "}
                  <a href="https://amzn.to/3mWf6FW" style={{ color: "white" }}>
                    talks
                  </a>{" "}
                  about choosing ambitious tasks to work on during time blocks
                  with no distractions.
                </li>
              </ul>{" "}
              The list goes on and on.
            </Typography>
            <Typography
              style={{ fontWeight: 600, paddingTop: 40, paddingBottom: 20 }}
              variant="h4"
            >
              Should I Try It?
            </Typography>
            <Typography align="left" className={classes.bodyText}>
              In our hyper-connected world, there's never been a more important
              time to practice deep work. Even if you don't use Winston, I'd
              recommend experimenting with at least one 1.5-2 hour deep work
              session a day for two weeks (it takes time to get used to). If the
              results are promising, stick with it!
              <br />
              <br />
              More specifically, we've found that if you are regularly having
              one or more of the following experiences, deep work will very
              likely help you:{" "}
              <ul>
                <li>
                  You have to master a difficult skill in a short amount of time
                  (this is how I got into it).
                </li>{" "}
                <li>
                  You go to the library for eight hours and actually get an hour
                  of work done.
                </li>{" "}
                <li>
                  You start coding and then Slack notifications completely
                  disrupt your flow state.
                </li>
                <li>
                  You're writing a blog post and thirty minutes in a friend
                  calls you, leading you to lose your train of thought.
                </li>
                <li>
                  You're putting in a lot of time, but not seeing your results
                  dramatically improve.
                </li>
                <li>You feel busy, but not highly effective or productive.</li>
              </ul>
            </Typography>
            {this.props.user ? <></> : this.getSignUpBtnText()}
            <Typography
              style={{ fontWeight: 600, paddingTop: 40, paddingBottom: 20 }}
              variant="h4"
            >
              How Do I Deep Work?
            </Typography>
            <Typography align="left" className={classes.bodyText}>
              {
                "The two most challenging parts of deep work are developing the ability to focus for long periods of time and getting your co-workers, family, etc. to understand your need for it. With that in mind, I'd recommend:"
              }
              <ul>
                <li>
                  {" "}
                  Blocking off 1.5-2 hours for focused work at the same time
                  every day for 2-3 weeks to build the habit of deep work.{" "}
                </li>
                <li>
                  {" "}
                  Choosing a time that is easy to add to an existing routine
                  (i.e. after your morning shower).{" "}
                </li>
                <li>
                  {" "}
                  Choosing a time that is quiet at work or at home (i.e. early
                  in the morning).{" "}
                </li>
                <li>
                  Communicating to your family, co-workers, etc. why you need
                  this disconnected time to be truly productive.
                </li>
                <li>
                  {" "}
                  <b>
                    Turning off your phone, slack, etc., finding a quiet space,
                    and commiting to no distractions for this period.
                  </b>
                </li>
                <li>
                  {" "}
                  Opening up Winston (
                  <a
                    href={"https://trywinston.com/learn"}
                    style={{ color: "white" }}
                  >
                    watch
                  </a>{" "}
                  our tutorial).{" "}
                </li>
                <li> Writing down your tasks for the session. </li>
                <li>
                  {" "}
                  Setting a goal for the length of your session (and expanding
                  over time){" "}
                </li>
                <li>
                  {" "}
                  Monitoring your analytics and holding yourself accountable
                  after each session{" "}
                </li>
              </ul>
              Feel free to{" "}
              <a href="mailto:arjun@trywinston.com" style={{ color: "white" }}>
                email me
              </a>{" "}
              if you have any questions!
            </Typography>
            <br />
            {this.props.user ? <></> : this.getSignUpBtnText()}
            {/* <Typography
                style={{ fontWeight: 600, paddingBottom: 10 }}
                variant="h4"
              >
                The 80/20 Rule
              </Typography>
              <Typography align="left" className={classes.bodyText}>
                Winston makes use of the 80/20 rule as it relates to
                productivity. What's the 20% of tracking you need to do to get
                80% of the value?
                <br />
                <br />
                We've found through research and our own case studies that{" "}
                <b>
                  engaging in deep work and tracking your data is the 20% that
                  gives you almost all of the benefit.
                </b>{" "}
                In other words, you're going to massively improve your
                productivity by consistently doing deep work and monitoring your
                sessions without making any other changes to your lifestyle.{" "}
                <br />
                <br />
                <b>This is good news.</b> You don't have to drive yourself
                insane trying to optimize everything, you just need to focus on
                hitting your deep work goals each day.
              </Typography> */}
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography
                  align="center"
                  style={{
                    fontWeight: 600,
                    paddingTop: 40,
                    paddingBottom: 20,
                  }}
                  variant="h4"
                >
                  How Do I Use Winston?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReactPlayer
                  controls={true}
                  height="100%"
                  url={this.state.urlFullTutorial}
                  width="100%"
                />
              </Grid>
            </Grid>
            <br />
            {this.props.user ? <></> : this.getSignUpBtnText()}
            <Typography
              style={{ fontWeight: 600, paddingTop: 40, paddingBottom: 20 }}
              variant="h4"
            >
              More Resources
            </Typography>
            <Typography align="left" className={classes.bodyText}>
              {
                "As aforementioned, the idea of deep work is discussed in almost every reputable source on peak performance, mastery, and learning. Here's a shortlist of resources this idea has been discussed:"
              }
              <ul>
                <li>
                  Paul Graham{" "}
                  <a
                    href="http://www.paulgraham.com/makersschedule.html"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  about time blocking.
                </li>
                <li>
                  Tim Ferriss{" "}
                  <a
                    href="https://tim.blog/2013/11/03/productivity-hacks/"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  about time blocking.
                </li>
                <li>
                  {" "}
                  Cal Newport{" "}
                  <a href="https://amzn.to/3mWf6FW" style={{ color: "white" }}>
                    talks
                  </a>{" "}
                  about deep work.
                </li>
                <li>
                  {" "}
                  Peak{" "}
                  <a href="https://amzn.to/36NUgmG" style={{ color: "white" }}>
                    talks
                  </a>{" "}
                  about deliberate practice.
                </li>
                <li>
                  Ian Fleming{" "}
                  <a
                    href="https://lithub.com/ian-fleming-explains-how-to-write-a-thriller/"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  about writing for a three hour period in the morning and an
                  hour at night.
                </li>
                <li>
                  James Clear{" "}
                  <a
                    href="https://jamesclear.com/daily-routines-writers"
                    style={{ color: "white" }}
                  >
                    talks
                  </a>{" "}
                  {
                    "about writers' daily routines (time blocking and no distractions)."
                  }
                </li>
              </ul>{" "}
            </Typography>
          </Grid>
          <Grid item sm={1} xs={false}></Grid>
          {this.props.user ? (
            <></>
          ) : (
            <>
              <Grid item xs={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Wanna Give Winston a Shot?
                </Typography>
                <Typography className={classes.bodyText}>
                  {"There's no one more obsessed with deep work than us."}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  onClick={() => this.props.history.push("/signup")}
                >
                  Start Free Trial
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 50 }}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
                <Footer />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    );
  }
}

DeepWorkInfo.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(DeepWorkInfo));
