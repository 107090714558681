import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  createUserWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase/auth";
import { logAuthEvent } from "../analytics/index";

import { Desktop } from "../responsive";
import { getMuiPropTypes } from "../utils/utils";

import Loading from "../components/Loading";

const styles = () => ({
  buttonGoogle: {
    background: "#2D46B9",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      errorMessage: "",
      firstName: "",
      firstNameError: false,
      googleError: false,
      lastName: "",
      lastNameError: false,
      loading: false,
      password: "",
      passwordError: "",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  keyPress = (e) => {
    e.key === "Enter" && this.createUserWithEmailAndPassword();
  };

  createUserWithEmailAndPassword = async () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const { email, firstName, lastName, password } = this.state;

        if (!firstName) {
          this.setState({
            firstNameError: true,
            errorMessage: "Please enter your first name.",
          });
          return;
        }

        if (!lastName) {
          this.setState({
            lastNameError: true,
            errorMessage: "Please enter your last name.",
          });
          return;
        }

        const {
          emailError,
          errorMessage,
          passwordError,
          userSignedIn,
        } = await createUserWithEmailAndPassword({
          email,
          firstName,
          lastName,
          password,
        });

        /* Got a successful sign up */
        if (userSignedIn) {
          logAuthEvent({ action: "Sign Up", label: "Email/Password" });
        } else {
          this.setState({
            emailError,
            errorMessage,
            passwordError,
          });
        }
      }
    );
  };

  handleSignInWithGoogle = async () => {
    const {
      errorMessage,
      googleError,
      userSignedIn,
    } = await signInWithGoogle();
    /* Got a successful sign up */
    if (userSignedIn) {
      logAuthEvent({ action: "Sign Up", label: "Google" });
    } else {
      this.setState({
        errorMessage,
        googleError,
      });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Container maxWidth="md">
        <Grid
          alignItems="center"
          container
          justify="center"
          style={{ paddingTop: "10%" }}
        >
          <Grid item md={5} xs={12}>
            <Typography variant="h3">Winston</Typography>
            <br />
            <Typography variant="h6">Get better at deep work.</Typography>
          </Grid>
          <Desktop>
            <Grid item md={2}>
              <Grid container justify="center">
                <Divider style={{ height: 300 }} orientation="vertical" />
              </Grid>
            </Grid>
          </Desktop>
          <Grid item md={5} xs={12}>
            <TextField
              fullWidth
              id="firstName"
              label="First Name"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.firstNameError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.lastNameError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <TextField
              fullWidth
              id="email"
              label="Email"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.emailError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <TextField
              fullWidth
              id="password"
              label="Password"
              type="password"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.passwordError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              fullWidth
              onClick={this.createUserWithEmailAndPassword}
              variant="contained"
            >
              Sign Up
            </Button>
            <Divider />
            <Button
              classes={{ root: classes.buttonGoogle }}
              style={{ marginTop: 20, marginBottom: 10 }}
              variant="contained"
              fullWidth
              onClick={this.handleSignInWithGoogle}
            >
              Sign Up with Google
            </Button>
            {this.state.googleError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <Button onClick={() => this.props.history.push("/login")}>
              Have an Account? Log in.
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

SignUp.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(SignUp));
