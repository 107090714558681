import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  button: { borderRadius: 25, marginRight: 10 },
  greenBgColor: {
    backgroundColor: theme.palette.customButtons.backgroundColor.default,
    "&:hover": {
      backgroundColor: theme.palette.customButtons.backgroundColor.default,
    },
  },
});

class FilterBtn extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Button
        className={`${classes.button} ${
          this.props.selected ? classes.greenBgColor : ""
        }`}
        onClick={this.props.onClick}
        variant="outlined"
      >
        {this.props.buttonText}
      </Button>
    );
  }
}

FilterBtn.propTypes = {
  ...getMuiPropTypes(),
};

export default withStyles(styles)(FilterBtn);
