import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import TutorialVideo from "../components/TutorialVideo";

class TutorialDialog extends React.Component {
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={this.props.open}
        style={{ minHeight: 400 }}
      >
        <DialogTitle>Deep Work Tutorial</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This is an in-depth tutorial on the "Start Deep Work" page. We
            really recommend watching it as it's more detailed than the
            "overview" tutorial we showed you earlier.
          </DialogContentText>
          <TutorialVideo
            analyticsLabel={"Start Deep Work"}
            height={400}
            url={this.props.url}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TutorialDialog.propTypes = {
  closeDialog: PropTypes.func,
  history: PropTypes.object,
  open: PropTypes.bool,
  url: PropTypes.string,
};

export default withRouter(TutorialDialog);
