import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class ActiveTaskDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>End Session?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We've detected an active task.{" "}
            <b>
              If you exit this page, we will not save the data for this task and
              end your session.
            </b>{" "}
            If you want to save your data for this task, please finish it before
            leaving this page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              /* Session ends w/ redirect */
              this.props.handleCloseDialog({
                activeTask: false,
                redirect: true,
              });
            }}
          >
            End Session
          </Button>
          <Button
            id="close"
            onClick={() => {
              /* Session simply continues w/o redirect */
              this.props.handleCloseDialog({
                activeTask: true,
                redirect: false,
              });
            }}
            variant="contained"
          >
            Continue Task
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ActiveTaskDialog.propTypes = {
  handleCloseDialog: PropTypes.func,
  open: PropTypes.bool,
};

export default withRouter(ActiveTaskDialog);
