import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { isTestEnv } from "../utils/utils";
import { hasActiveSubscription } from "../utils/server";
import { getUserSignUpDate } from "../firebase";
import { signOut } from "../firebase/auth";

class SubRequiredDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lifeTimeDiscount: false,
      showSubDialog: false,
    };
  }

  async componentDidMount() {
    /**
     * Show dialog if user isn't subbed or in free trial
     */
    const signUpDate = (await getUserSignUpDate()) || new Date();
    if (moment().diff(moment(signUpDate), "days") < 14) {
      return;
    }
    if (isTestEnv()) {
      console.log("Not showing sub dialog bc test mode.");
      return;
    }
    const isSubscribed = await hasActiveSubscription();
    if (!isSubscribed) {
      this.setState({
        showSubDialog: true,
      });
    }
  }

  handleSignOut = async () => {
    await signOut();
    this.props.history.push("/");
  };

  render() {
    return (
      <Dialog open={this.state.showSubDialog}>
        <DialogTitle>Subscription Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your free trial is up! If you like using Winston, please choose a
            payment plan. Feel free to email me at arjun@trywinston.com if you
            have any questions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSignOut}>Sign Out</Button>
          <Button
            onClick={() => this.props.history.push("/choose")}
            variant="contained"
          >
            Choose a Plan
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SubRequiredDialog.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SubRequiredDialog);
