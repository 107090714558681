import PropTypes from "prop-types";
import moment from "moment";
import isElectron from "is-electron";
import { objectSet } from "./object";

/**
 * Parsing strings
 */
export const getTagsFromValue = (value) => {
  let tags = [];
  if (value && value.includes("[") && value.includes("]")) {
    /* Regex to get matches, remove brackets */
    tags.push(
      ...value.match(/\[.+?\]/g).map((str) => str.substring(1, str.length - 1))
    );
  }
  return tags;
};

export const convertSentenceForUrl = (sentence) => {
  return sentence.replace(" ", "%20");
};

/**
 * Frequent PropTypes cases
 */
export const getMuiPropTypes = () => {
  return {
    classes: PropTypes.object,
  };
};

/**
 * Time
 * */
export const convertMinsToSecs = (mins) => mins * 60;
export const convertHrsToSecs = (hrs) => hrs * 3600;

/* https://stackoverflow.com/questions/4345045/loop-through-a-date-range-with-javascript */
export const getAllDaysFromRange = ({ earliestDate, latestDate }) => {
  const allDates = [];
  for (
    let d = moment(earliestDate).startOf("day");
    d <= moment(latestDate).startOf("day");
    d.add(1, "day")
  ) {
    allDates.push(d.toDate());
  }
  return allDates;
};

/**
 * Returns already formatted week range array bc
 * duplicates arise if formatting isn't done at this
 * level.
 */
export const getAllWeeksFromRange = ({ earliestDate, latestDate }) => {
  const allWeeks = [];
  for (
    let d = new Date(earliestDate);
    d < latestDate;
    d.setDate(d.getDate() + 1)
  ) {
    const begOfWeek = getWeekMapKey(d);
    !allWeeks.includes(begOfWeek) && allWeeks.push(begOfWeek);
  }
  return allWeeks;
};

export const getHoursAndMinsFromTime = (ms) => {
  const hrs = Math.floor((ms % 86400000) / 3600000); // hours
  const mins = Math.round(((ms % 86400000) % 3600000) / 60000); // minutes
  return { hrs, mins };
};

export const getStartTimeStr = (date) => {
  let hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

export const getTimeElapsedStr = ({ startTime, endTime }) => {
  if (!endTime) return "";
  const diffHrs = moment(endTime).diff(moment(startTime), "hours"); // hours
  const diffMins =
    moment(endTime).diff(moment(startTime), "minutes") - diffHrs * 60; // minutes
  return diffHrs + " hrs " + diffMins + " mins";
};

export const parseTimeString = (timeStr) => {
  const timeArr = timeStr.split(":");
  if (!timeArr.length || timeArr.length > 2) return null;
  const hours = +(timeArr[0] * 60);
  const minutes = +timeArr[1];
  return hours + minutes;
};

export const TIME_MAP = {
  ONE_SECOND: 1000,
  ONE_MIN: 1000 * 60,
  ONE_HOUR: 1000 * 60 * 60,
  ONE_HOUR_AND_A_HALF: 1000 * 60 * 90,
};

/**
 * String
 */
export const replaceAt = ({ index, replacement, timeValue }) => {
  return (
    timeValue.substring(0, index) +
    replacement +
    timeValue.substring(index + replacement.length)
  );
};
/* https://stackoverflow.com/questions/4700226/i-want-to-truncate-a-text-or-line-with-ellipsis-using-javascript */
export const truncate = (input, maxLength) => {
  return input.length > maxLength
    ? `${input.substring(0, maxLength)}...`
    : input;
};

/**
 * Test environment
 */
export const isTestEnv = () => {
  if (isElectron()) {
    return isElectronDev();
  }
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return true;
  }
  return false;
};

const isElectronDev = () => {
  return window.process.argv.slice(-1)[0] === "devMode";
};

/**
 * Misc
 */
export const BREAKPOINTS = {
  XS: 0,
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
};

export const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets)
    /* Filter out stylesheets from extensions: https://betterprogramming.pub/how-to-fix-the-failed-to-read-the-cssrules-property-from-cssstylesheet-error-431d84e4a139 */
    .filter(
      (styleSheet) =>
        !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
    )
    .forEach((styleSheet) => {
      if (styleSheet.cssRules) {
        // for <style> elements
        const newStyleEl = sourceDoc.createElement("style");
        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          // write the text of each rule into the body of the style element
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });
        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) {
        // for <link> elements loading CSS from a URL
        const newLinkEl = sourceDoc.createElement("link");
        newLinkEl.rel = "stylesheet";
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    });
};

/**
 * Checks if task has required properties set.
 * @param {object} task
 * @returns {object} - contains errors
 *
 * TO DO -- massively improve this fn
 * with logic from PayPal
 */
export const errorExistsInTask = (task) => {
  let errors = {};
  /* Check missing properties */
  const requiredTaskProperties = [
    "actualTime",
    "completed",
    "date",
    "difficulty",
    "focus",
    "id",
    "notes",
    "projectName",
    "sessionId",
    "showTask",
    "tags",
    "value",
  ];
  requiredTaskProperties.forEach((prop) => {
    if (!Object.prototype.hasOwnProperty.call(task, prop)) {
      objectSet(errors, `${prop}.code`, `missing`);
    } else {
      /* Optional property */
      if (prop === "notes") {
        return;
      }
      if (task[prop] === null || task[prop] === "") {
        objectSet(errors, `${prop}.code`, `missing`);
      }
    }
  });
  return errors;
};

export const getPercentageChange = ({ newNum, originalNum }) => {
  return ((newNum - originalNum) / originalNum) * 100;
};

export const getDateThreeMonthsAgo = () => {
  return moment().subtract(3, "months").toDate();
};

export const getDateYesterday = () => {
  return moment().subtract(1, "days").toDate();
};

export const getMarksForSlider = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
    label: `${value}`,
    value,
  }));
};

export const getMarksForDifficultySlider = () => {
  return [2, 5, 8].map((value) => ({
    label: `${value === 2 ? "Easy" : value === 5 ? "Medium" : "Hard"}`,
    value,
  }));
};

export const getTaskTimeOptions = () => {
  return [
    {
      display: "5 min",
      time: convertMinsToSecs(5),
    },
    {
      display: "15 min",
      time: convertMinsToSecs(15),
    },
    {
      display: "30 min",
      time: convertMinsToSecs(30),
    },
    {
      display: "45 min",
      time: convertMinsToSecs(45),
    },
    {
      display: "1 hr",
      time: convertHrsToSecs(1),
    },
    {
      display: "1 hr 30 min",
      time: convertHrsToSecs(1.5),
    },
    {
      display: "2 hr",
      time: convertHrsToSecs(2),
    },
    {
      display: "2 hr 30 min",
      time: convertHrsToSecs(2.5),
    },
    {
      display: "3 hr",
      time: convertHrsToSecs(3),
    },
    {
      display: "3 hr 30 min",
      time: convertHrsToSecs(3.5),
    },
    {
      display: "4 hr",
      time: convertHrsToSecs(4),
    },
  ];
};

/**
 * Elegant solution:
 * https://stackoverflow.com/questions/18985475/generate-date-from-week-number-in-moment-js
 * doesn't work because of moment bug. So doing it this way based on:
 * https://stackoverflow.com/questions/63424478/momentjs-monday-by-week-number-bug
 */
export const getWeekMapKey = (date) => {
  const week = moment(date).week();
  return moment(date).clone().week(week).day("Sunday").format("YYYY-MM-DD");
};

/* https://stackoverflow.com/questions/4550505/getting-a-random-value-from-a-javascript-array */
export const getRandomIndex = (length) => Math.floor(Math.random() * length);

/* https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range */
export const getRandomInt = ({ max, min }) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomFloat = ({ max, min }) => {
  return Math.random() * (max - min) + min;
};
