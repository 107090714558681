import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { convertHrsToSecs, getHoursAndMinsFromTime } from "../utils/utils";
// import { hideIntercom, showIntercom } from "../intercom";
import {
  getDailyGoalData,
  getEmailPreferences,
  getNotifPreferences,
  getSoundPreferences,
  requestFirebaseNotificationPermission,
  updateAfternoonNotif,
  updateDailyGoal,
  updateEmailSettings,
  updateMorningNotif,
  updateSoundSettings,
} from "../firebase/index";

import Loading from "../components/Loading";
import ProjectNamesDialog from "../dialogs/ProjectNamesDialog";

const timeOptions = [
  {
    display: "1 hr",
    time: convertHrsToSecs(1),
  },
  {
    display: "2 hr",
    time: convertHrsToSecs(2),
  },
  {
    display: "3 hr",
    time: convertHrsToSecs(3),
  },
  {
    display: "4 hr",
    time: convertHrsToSecs(4),
  },
  {
    display: "5 hr",
    time: convertHrsToSecs(5),
  },
  {
    display: "6 hr",
    time: convertHrsToSecs(6),
  },
  {
    display: "7 hr",
    time: convertHrsToSecs(7),
  },
  {
    display: "8 hr",
    time: convertHrsToSecs(8),
  },
  {
    display: "9 hr",
    time: convertHrsToSecs(9),
  },
  {
    display: "10 hr",
    time: convertHrsToSecs(10),
  },
];

const styles = (theme) => ({
  dashboardLabels: theme.dashboardLabels.h6,
  removeLeftMargin: {
    marginLeft: 0,
  },
  /* Switch --> */
  checked: {},
  switchRoot: {
    /* https://stackoverflow.com/questions/56812652/positioning-material-ui-label-to-the-left-with-left-alignment */
    display: "flex",
    justifyContent: "space-between",
  },
  switchBase: {
    color: "#bdbdbd",
    "&$checked": {
      color: theme.palette.customButtons.backgroundColor.default,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.customButtons.backgroundColor.default,
    },
  },
  track: {},
  /* <-- Switch */
});

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElTimer: null,
      dailyGoal: null,
      email: {
        dailyReport: false,
      },
      loading: true,
      notifs: {
        afternoonNotif: false,
        morningNotif: false,
      },
      showProjectNamesDialog: false,
    };
    // hideIntercom();
  }

  async componentDidMount() {
    let dailyGoal = 4 * 3600;
    const { dailyGoal: dailyGoalFirebase } = await getDailyGoalData();
    if (dailyGoalFirebase) {
      dailyGoal = dailyGoalFirebase;
    }
    const {
      afternoonNotif,
      firebaseBrowserToken = "",
      morningNotif,
    } = await getNotifPreferences();
    const { dailyReport = true } = await getEmailPreferences();
    const endBell = await getSoundPreferences();
    this.setState({
      dailyGoal,
      email: {
        dailyReport,
      },
      endBell,
      loading: false,
      notifs: {
        afternoonNotif: Boolean(afternoonNotif),
        firebaseBrowserToken,
        morningNotif: Boolean(morningNotif),
      },
    });
  }

  componentWillUnmount() {
    // showIntercom();
  }

  handleMenuClose = (id) => {
    this.setState({ [id]: null });
  };

  handleMenuOpen = ({ e, id }) => {
    this.setState({
      [id]: e.currentTarget,
    });
  };

  handleEmailSwitch = (key) => {
    this.setState(
      {
        email: {
          ...this.state.email,
          [key]: !this.state.email[key],
        },
      },
      async () => {
        updateEmailSettings({
          [key]: this.state.email[key],
        });
      }
    );
  };

  handleNotifSwitch = (key) => {
    this.setState(
      {
        notifs: {
          ...this.state.notifs,
          [key]: !this.state.notifs[key],
        },
      },
      async () => {
        if (this.state.notifs[key] && !this.state.notifs.firebaseBrowserToken) {
          const token = await requestFirebaseNotificationPermission();
          this.setState({
            notifs: {
              ...this.state.notifs,
              firebaseBrowserToken: token,
            },
          });
        }
        if (key === "morningNotif") {
          updateMorningNotif(this.state.notifs[key]);
        } else if (key === "afternoonNotif") {
          updateAfternoonNotif(this.state.notifs[key]);
        }
      }
    );
  };

  handleTimerClose = ({ time }) => {
    if (!time) return;
    this.setState(
      {
        anchorElTimer: null,
        dailyGoal: time,
      },
      () => updateDailyGoal(time)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      email: { dailyReport },
      notifs: { afternoonNotif, morningNotif },
    } = this.state;
    let hrs = 0;

    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.dailyGoal) {
      hrs = getHoursAndMinsFromTime(this.state.dailyGoal * 1000).hrs;
    }

    return (
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h6"
            >
              Daily Goal
            </Typography>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="body1"
            >
              Currently <b>{hrs}</b> {`${hrs === 1 ? "hour" : "hours"}`}
            </Typography>
            <Grid container>
              <Button
                onClick={(e) => {
                  this.handleMenuOpen({
                    e,
                    id: "anchorElTimer",
                  });
                }}
                variant="outlined"
              >
                Edit Daily Goal
              </Button>
              <Menu
                anchorEl={this.state.anchorElTimer}
                keepMounted
                open={Boolean(this.state.anchorElTimer)}
                onClose={() => this.handleMenuClose("anchorElTimer")}
              >
                {timeOptions.map(({ display, time }, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        this.handleTimerClose({
                          time,
                        })
                      }
                    >
                      {display}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
            <FormHelperText style={{ paddingTop: 10 }}>
              Change the daily goal that shows up on your dashboard.
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h6"
            >
              Email
            </Typography>
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.removeLeftMargin,
                root: classes.switchRoot,
              }}
              control={
                <Switch
                  checked={dailyReport}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={() => this.handleEmailSwitch("dailyReport")}
                />
              }
              label="Daily Report"
              labelPlacement="start"
            />
            <FormHelperText style={{ marginBottom: 15, paddingRight: 50 }}>
              Get an email every morning with your accomplishments from the
              previous day.
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h6"
            >
              Notifications
            </Typography>
            {/* MORNING NOTIF */}
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.removeLeftMargin,
                root: classes.switchRoot,
              }}
              control={
                <Switch
                  checked={morningNotif}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={() => this.handleNotifSwitch("morningNotif")}
                />
              }
              label="Morning Reminders"
              labelPlacement="start"
            />
            <FormHelperText style={{ marginBottom: 15, paddingRight: 50 }}>
              Get a reminder to do deep work each weekday morning.
            </FormHelperText>
            {/* AFTERNOON NOTIF */}
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.removeLeftMargin,
                root: classes.switchRoot,
              }}
              control={
                <Switch
                  checked={afternoonNotif}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={() => this.handleNotifSwitch("afternoonNotif")}
                />
              }
              label="Afternoon Reminders"
              labelPlacement="start"
              style={{ textAlign: "left" }}
            />
            <FormHelperText style={{ paddingRight: 50 }}>
              Get a reminder to do deep work each weekday afternoon.
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h6"
            >
              Projects
            </Typography>
            <Grid container>
              <Button
                onClick={() => {
                  this.setState({
                    showProjectNamesDialog: true,
                  });
                }}
                variant="outlined"
              >
                Edit Project Names
              </Button>
            </Grid>
            <FormHelperText style={{ paddingTop: 10 }}>
              View/edit your list of project names.
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h6"
            >
              Sound
            </Typography>
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.removeLeftMargin,
                root: classes.switchRoot,
              }}
              control={
                <Switch
                  checked={this.state.endBell}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={() => {
                    this.setState(
                      {
                        endBell: !this.state.endBell,
                      },
                      () => updateSoundSettings(this.state.endBell)
                    );
                  }}
                />
              }
              label="End Timer Bell"
              labelPlacement="start"
              style={{ textAlign: "left" }}
            />
            <FormHelperText style={{ paddingRight: 50 }}>
              Control if the bell rings when a task's timer expires.
            </FormHelperText>
          </Grid>
        </Grid>
        <div>
          <ProjectNamesDialog
            handleClose={() => this.setState({ showProjectNamesDialog: false })}
            open={this.state.showProjectNamesDialog}
          />
        </div>
      </Container>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Settings);
