import axios from "axios";

import { getStripeCustomerId } from "../firebase";
import { getUID } from "../firebase/auth";
import { isTestEnv } from "./utils";

const BASE_URL = isTestEnv()
  ? "http://localhost:5001/deepwork-c7713/us-central1/deepWork"
  : "https://us-central1-deepwork-c7713.cloudfunctions.net/deepWork";
const BASE_URL_STRIPE = `${BASE_URL}/stripe`;
const BASE_URL_CALENDAR = `${BASE_URL}/calendar`;
const BASE_URL_AUTH = `${BASE_URL}/auth`;
const BASE_URL_NOTIFS = `${BASE_URL}/notifications`;
const JSON_HEADERS_OBJ = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Updates sessions/tasks with new project name
 */
export const editTaskProjectNames = async ({ newName, originalName }) => {
  const endpoint = "/updateProjectName";
  const data = JSON.stringify({
    newName,
    originalName,
    uid: getUID(),
  });
  const response = await axios.post(
    `${BASE_URL}${endpoint}`,
    data,
    JSON_HEADERS_OBJ
  );
  console.log(
    "[editTaskProjectNames] response: ",
    JSON.stringify(response, null, 2)
  );
};

export const generateAuthToken = async ({ electronId, token }) => {
  const endpoint = "/desktop-auth";
  const data = JSON.stringify({
    electronId,
    token,
  });
  try {
    const response = await axios.post(
      `${BASE_URL_AUTH}${endpoint}`,
      data,
      JSON_HEADERS_OBJ
    );
    return response.data.success;
  } catch (error) {
    console.log("[generateAuthToken] ", error);
    return false;
  }
};

/**
 * Requests server with Stripe session ID to get
 * customer's stripe ID.
 */
export const getCustomerIdFromSession = async (sessionId) => {
  /* TO DO -- ERROR HANDLING HERE */
  const endpoint = `/checkout-session?sessionId=${sessionId}`;
  const response = await axios.get(`${BASE_URL_STRIPE}${endpoint}`);
  return response.data.customer;
};

/**
 * Requests server for checkout session data.
 * Returns the relevant info so that Stripe can
 * redirect to their checkout page.
 * https://stripe.com/docs/billing/subscriptions/checkout#add-redirect
 */
export const getStripeCheckout = async (priceId) => {
  const data = JSON.stringify({
    priceId,
  });
  const response = await axios.post(
    `${BASE_URL_STRIPE}/create-checkout-session`,
    data,
    JSON_HEADERS_OBJ
  );
  return response.data.id;
};

/**
 * Requests server for link to customer's billing portal.
 * Then redirects to that page.
 */
export const handleCustomerPortal = async () => {
  const endpoint = "/customer-portal";
  const customerId = await getStripeCustomerId();
  const reqData = JSON.stringify({
    customerId,
  });
  const response = await axios.post(
    `${BASE_URL_STRIPE}${endpoint}`,
    reqData,
    JSON_HEADERS_OBJ
  );
  const data = await response.json();
  window.location.href = data.url;
};

/**
 * Determine whether user has active subscription
 */
export const hasActiveSubscription = async () => {
  console.log("[hasActiveSubscription] Checking active subscription...");
  try {
    const response = await axios.get(
      `${BASE_URL_STRIPE}/${getUID()}/hasActiveSubscription`
    );
    return response.data.isSubscribed;
  } catch (error) {
    console.log("[hasActiveSubscription] Error: ", error);
    alert(
      "Please refresh the page as there was an error fetching your subscription status. If this issue persists email me at arjun@trywinston.com."
    );
  }
  return false;
};

/**
 * Saves users selected times + matches
 * them up when possible.
 * @param selectedTimes - array of objects
 * @param timezone - user's timezone
 */
export const saveSelectedTimes = async ({ selectedTimes, timezone }) => {
  const endpoint = "/save-selected-times";
  const data = JSON.stringify({
    selectedTimes: JSON.stringify(selectedTimes),
    timezone,
    uid: getUID(),
  });
  const response = await axios.post(
    `${BASE_URL}${endpoint}`,
    data,
    JSON_HEADERS_OBJ
  );
  if (response.data.error) {
    alert("Error trying to find you a partner, please try again later.");
    return null;
  }
  return response.data.map((obj) => ({ ...obj }));
};

export const saveStripeData = async (customerId) => {
  const endpoint = "/saveCustomerData";
  const data = JSON.stringify({
    customer: customerId,
    uid: getUID(),
  });
  const response = await axios.post(
    `${BASE_URL_STRIPE}${endpoint}`,
    data,
    JSON_HEADERS_OBJ
  );
  console.log("[saveStripeData] response status: ", response.data.status);
};

/**
 * Adds event to user's calendar via
 * Google Calendar API.
 */
export const sendCalInvite = async () => {
  const endpoint = "/addEvent";
  const data = JSON.stringify({
    uid: getUID(),
  });
  try {
    await axios.post(`${BASE_URL_CALENDAR}${endpoint}`, data, JSON_HEADERS_OBJ);
  } catch (error) {
    console.log("Error creating cal invite: ", error);
  }
};

export const shouldSendNotification = async () => {
  const endpoint = "/should-send";
  const data = JSON.stringify({
    uid: getUID(),
  });
  try {
    const response = await axios.post(
      `${BASE_URL_NOTIFS}${endpoint}`,
      data,
      JSON_HEADERS_OBJ
    );
    return response.data.shouldSendNotif;
  } catch (error) {
    console.log("Error creating cal invite: ", error);
    return false;
  }
};
