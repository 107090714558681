import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { v4 as uuidv4 } from "uuid";

import {
  errorExistsInTask,
  getHoursAndMinsFromTime,
  getMarksForSlider,
  getTaskTimeOptions,
} from "../utils/utils";

import ProjectNamesMenu from "../components/ProjectNamesMenu";

const timeOptions = getTaskTimeOptions();
const marks = getMarksForSlider();

const textFields = [
  {
    helper: "Describe the task you completed",
    id: "description",
    label: "Task Description",
  },
  // {
  //   helper: "Add any notes you'd like to record about this task",
  //   id: "notes",
  //   label: "Notes (optional)",
  //   rows: 3,
  // },
];

const styles = (theme) => ({
  addPaperBgColor: {
    backgroundColor: theme.palette.background.paper,
  },
  dialogPaper: theme.bigDialogPaper,
});

class AddTaskDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  getInitialState = () => {
    const { sessionId, taskDate } = this.props;
    return {
      anchorElProjectName: null,
      anchorElTimer: null,
      errors: {},
      task: {
        actualTime: null,
        completed: true,
        date: taskDate,
        difficulty: null,
        focus: null,
        id: uuidv4(),
        notes: "",
        projectName: "",
        sessionId,
        showTask: true,
        tags: [],
        value: "",
      },
    };
  };

  /**
   * Close the menu by setting it to null in state.
   */
  closeProjectNameMenu = () => {
    this.setState({
      anchorElProjectName: null,
    });
  };

  /**
   * Open the menu by setting it to the current target.
   */
  openProjectNameMenu = (e) => {
    this.setState({
      anchorElProjectName: e.currentTarget,
    });
  };

  /**
   * Closes the menu associated with the
   * time dropdown.
   */
  closeTimeMenu = () => {
    this.setState({
      anchorElTimer: null,
    });
  };

  /**
   * Opens the menu associated with the
   * time dropdown.
   */
  handleMenuOpen = (e) => {
    this.setState({
      anchorElTimer: e.currentTarget,
    });
  };

  handleTimerClose = ({ time }) => {
    if (!time) return;
    this.setState({
      anchorElTimer: null,
      task: {
        ...this.state.task,
        actualTime: time,
      },
    });
  };

  /**
   * Called when task is added
   */
  handleAddTask = () => {
    const errors = errorExistsInTask(this.state.task);
    if (Object.keys(errors).length) {
      this.setState({
        errors: {
          ...errors,
        },
      });
      return;
    }
    this.props.handleAddTask(this.state.task);
    this.setState({
      ...this.getInitialState(),
    });
  };

  /**
   * Called when add task is cancelled
   */
  handleCancel = () => {
    this.props.handleCancelAddTask();
    this.setState({
      ...this.getInitialState(),
    });
  };

  /**
   * Called when new project is created from ProjectNamesMenu.
   * Sets the new project name as the state for the task
   * that is being added.
   */
  handleCreateNewProject = (newProjectName) => {
    const { projectNames } = this.props;
    if (newProjectName && !projectNames.includes(newProjectName)) {
      this.props.addNewProjectName(newProjectName);
    }
    this.setState({
      task: {
        ...this.state.task,
        projectName: newProjectName,
      },
    });
  };

  /**
   * Called by ProjectNamesMenu when a name is selected.
   * Sets the selectedProjectName as the state for the task
   * that is being added.
   */
  projectNameSelected = (selectedProjectName) => {
    this.setState({
      task: {
        ...this.state.task,
        projectName: selectedProjectName,
      },
    });
  };

  render() {
    const {
      task: { actualTime, projectName },
    } = this.state;
    const { classes, projectNames } = this.props;
    let hrs = 0;
    let mins = 0;
    let hrsText = "";
    let minsText = "";

    if (actualTime) {
      const { hrs: hours, mins: minutes } = getHoursAndMinsFromTime(
        actualTime * 1000
      );
      hrs = hours;
      mins = minutes;
    }

    if (hrs) {
      hrsText = `${hrs} ${hrs === 1 ? "hour" : "hours"}`;
    }

    if (mins) {
      minsText = `${mins} minutes`;
    }

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} open={this.props.open}>
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {textFields.map(({ helper, id, label }) => (
              <Grid className={classes.addPaperBgColor} item key={id} xs={12}>
                <TextField
                  className={classes.addPaperBgColor}
                  error={this.state.errors.value}
                  fullWidth
                  helperText={helper}
                  label={label}
                  multiline
                  onChange={(e) => {
                    this.setState({
                      task: {
                        ...this.state.task,
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            ))}
            <Grid className={classes.addPaperBgColor} item xs={12}>
              {/* Focus Level Form */}
              <FormControl fullWidth>
                <Typography>Focus</Typography>
                <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                  <Slider
                    defaultValue={1}
                    marks={marks}
                    min={1}
                    max={10}
                    onChange={(e, value) =>
                      this.setState({
                        task: { ...this.state.task, focus: value },
                      })
                    }
                    step={1}
                  />
                </div>
                <FormHelperText error={this.state.errors.focus}>
                  How focused were you while you worked on this task?
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid className={classes.addPaperBgColor} item xs={12}>
              {/* Difficulty Form */}
              <FormControl fullWidth>
                <Typography>Difficulty</Typography>
                <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                  <Slider
                    defaultValue={1}
                    marks={marks}
                    min={1}
                    max={10}
                    onChange={(e, value) =>
                      this.setState({
                        task: {
                          ...this.state.task,
                          difficulty: value,
                        },
                      })
                    }
                    step={1}
                  />
                </div>
                <FormHelperText error={this.state.errors.difficulty}>
                  How much did this task push you intellectually?
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid className={classes.addPaperBgColor} item xs={12}>
              <Button
                onClick={this.handleMenuOpen}
                style={{ borderRadius: 25 }}
                variant="outlined"
              >
                {!hrsText && !minsText
                  ? "Choose Task Time"
                  : `${hrsText} ${minsText}`}
              </Button>
              <Menu
                anchorEl={this.state.anchorElTimer}
                keepMounted
                open={Boolean(this.state.anchorElTimer)}
                onClose={this.closeTimeMenu}
              >
                {timeOptions.map(({ display, time }, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        this.handleTimerClose({
                          time,
                        })
                      }
                    >
                      {display}
                    </MenuItem>
                  );
                })}
              </Menu>
              <FormHelperText
                error={this.state.errors.actualTime}
                style={{ paddingTop: 10 }}
              >
                Approx. how long did the task take you?
              </FormHelperText>
            </Grid>
            <Grid className={classes.addPaperBgColor} item xs={12}>
              <Button
                onClick={this.openProjectNameMenu}
                style={{ borderRadius: 25 }}
                variant="outlined"
              >
                {projectName || "Choose Project Name"}
              </Button>
              <ProjectNamesMenu
                anchorElProjectName={this.state.anchorElProjectName}
                handleCloseMenu={this.closeProjectNameMenu}
                handleCreateNewProject={this.handleCreateNewProject}
                projectNames={projectNames}
                projectNameSelected={this.projectNameSelected}
              />
              <FormHelperText
                error={this.state.errors.projectName}
                style={{ paddingTop: 10 }}
              >
                What project was this task associated with?
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleAddTask} variant="contained">
            Add Task
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddTaskDialog.propTypes = {
  addNewProjectName: PropTypes.func,
  classes: PropTypes.object,
  handleCancelAddTask: PropTypes.func,
  handleAddTask: PropTypes.func,
  open: PropTypes.bool,
  projectNames: PropTypes.array,
  sessionId: PropTypes.string,
  taskDate: PropTypes.string,
};

export default withStyles(styles)(AddTaskDialog);
