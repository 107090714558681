import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { createBrowserHistory } from "history";
import { v4 as uuidv4 } from "uuid";

import {
  getElectronAuthRef,
  getSessionCollectionRef,
  getUserDocRef,
} from "./refs";
import { isTestEnv } from "../utils/utils";

const EMAIL_ERRORS = [
  "auth/invalid-email",
  "auth/user-disabled",
  "auth/user-not-found",
  "auth/email-already-in-use",
  "auth/argument-error",
];
const PASSWORD_ERRORS = ["auth/wrong-password", "auth/weak-password"];

export const getUID = () => {
  // console.log(firebase.auth().currentUser.uid);
  return firebase.auth().currentUser.uid;
};

export const createUserWithEmailAndPassword = async ({
  email,
  firstName,
  lastName,
  password,
}) => {
  try {
    /* Create user */
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const { uid } = userCredential.user;
    /* Write basic info to firestore */
    const userDocRef = getUserDocRef();
    const doc = await userDocRef.get();
    if (!doc.exists) {
      await writeInitialData({
        email,
        firstName,
        lastName,
        uid,
      });
      /* Send welcome email */
      const sendSignUpEmail = firebase
        .functions()
        .httpsCallable("sendSignUpEmail");
      sendSignUpEmail({
        email,
        firstName,
      });
    }
    return {
      emailError: "",
      errorMessage: "",
      passwordError: "",
      userSignedIn: true,
    };
  } catch (error) {
    console.log("Error creating user: ", error);
    return {
      emailError: EMAIL_ERRORS.includes(error.code),
      errorMessage: getErrorMessage(error.code),
      passwordError: PASSWORD_ERRORS.includes(error.code),
      userSignedIn: false,
    };
  }
};

export const userSignedIn = (updateAuth) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    console.log("[userSignedIn] Checking user auth...");
    if (user) {
      const newUser = await isNewUser();
      const history = createBrowserHistory();
      updateAuth({
        isNewUser: newUser,
        redirect:
          history.location.pathname === "/signup"
            ? "/onboarding"
            : history.location.pathname === "/login"
            ? "/"
            : null,
        user,
      });
    } else {
      updateAuth({
        isNewUser: false,
        user: null,
      });
    }
  });
};

export const isNewUser = async () => {
  let newUser = true;
  try {
    const snapshot = await getSessionCollectionRef().get();
    newUser = snapshot.empty;
  } catch (error) {
    console.log("[isNewUser] Error checking user status: ", error);
  }
  return newUser;
};

const addElectronAuthListener = (authId) => {
  getElectronAuthRef(authId).onSnapshot(async (doc) => {
    if (doc.exists) {
      console.log(doc.data());
      const { authToken } = doc.data();
      await firebase.auth().signInWithCustomToken(authToken);
      // const credential = await firebase.auth().signInWithCustomToken(authToken);
    }
  });
};

export const electronGoogleAuth = async () => {
  /* Create uuid to look up auth */
  const authId = uuidv4();
  /* Set up listener */
  addElectronAuthListener(authId);
  /* Redirect to browser */
  const baseUrl = isTestEnv()
    ? "http://localhost:3000"
    : "https://trywinston.com";
  const endpoint = `/desktop-auth?redirectId=${authId}`;
  window.open(`${baseUrl}${endpoint}`, "Desktop Auth");
};

export const getGoogleRedirectResult = async () => {
  return await firebase.auth().getRedirectResult();
};

export const googleRedirect = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

export const signInWithGoogle = async () => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    const { email, displayName } = result.user;
    const name = displayName ? displayName.split(" ") : ["", ""];
    /* If this is the first sign in, write intial data */
    const userDocRef = getUserDocRef();
    const doc = await userDocRef.get();
    if (!doc.exists) {
      await writeInitialData({
        email,
        firstName: name[0],
        lastName: name.slice(1).join(" "),
      });
      /* Send welcome email */
      const sendSignUpEmail = firebase
        .functions()
        .httpsCallable("sendSignUpEmail");
      sendSignUpEmail({
        email,
        firstName: name[0],
      });
    }
    return {
      errorMessage: "",
      googleError: false,
      userSignedIn: true,
    };
  } catch (error) {
    console.log("[signInWithGoogle] Error signing in with Google: ", error);
    if (
      error.code === "auth/popup-closed-by-user" ||
      error.code === "auth/cancelled-popup-request"
    ) {
      return;
    }
    return {
      errorMessage: getErrorMessage(error.code),
      googleError: true,
      userSignedIn: false,
    };
  }
};

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    return {
      emailError: false,
      errorMessage: "",
      passwordError: "",
      userSignedIn: true,
    };
  } catch (error) {
    const errorCode = error.code;
    return {
      emailError: EMAIL_ERRORS.includes(errorCode),
      errorMessage: getErrorMessage(errorCode),
      passwordError: PASSWORD_ERRORS.includes(errorCode),
      userSignedIn: false,
    };
  }
};

export const resetPassword = async (email, updateUI) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    updateUI({ success: true });
    console.log("successfully sent reset email");
  } catch (error) {
    console.log("error: ", error);
    updateUI({ error: getErrorMessage(error.code) });
  }
};

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    /* Email */
    case "auth/invalid-email":
      return "Invalid email address.";
    case "auth/user-disabled":
      break;
    case "auth/email-already-in-use":
      return "This email is already in use, try logging in.";
    case "auth/user-not-found":
      return "No user exists with this email address, please double check it.";
    /* Password */
    case "auth/wrong-password":
      return "Invalid password for the given email address.";
    case "auth/weak-password":
      return "Your password should be at least 6 characters long.";
    /* Google */
    case "auth/network-request-failed":
      return "A network error occurred, please try again.";
    // case "auth/cancelled-popup-request":
    //   return "It appears that you clicked the 'Log in with Google' button multiple times. Please only click it once.";
    case "auth/popup-blocked":
      return "It appears that your browser is blocking popups. Please allow them for this website so you can log in with Google.";
    // case "auth/popup-closed-by-user":
    //   return "It appears that you closed the Google sign in pop up without completing it. Feel free to sign up with email/password if you prefer.";
    default:
      return "Unknown error, please try again or email issue to arjun@trywinston.com.";
  }
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {
    console.log("Error signing out: ", err);
  }
};

const writeInitialData = async ({
  email = "",
  firstName = "",
  lastName = "",
}) => {
  const userDocRef = getUserDocRef();
  await userDocRef.set({
    email,
    firstName,
    lastName,
    signUpDate: firebase.firestore.Timestamp.fromDate(new Date()),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
  });
};
