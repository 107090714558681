import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";

import { saveSelectedTimes, sendCalInvite } from "../utils/server";
import { getMuiPropTypes, isTestEnv } from "../utils/utils";
import {
  fetchMediaURL,
  setNotifPreferences,
  requestFirebaseNotificationPermission,
  updateUserCalendarSettings,
} from "../firebase";
import { logAcquisitionEvent } from "../analytics/index";

import Loading from "../components/Loading";
import Settings from "./Settings";
import Tutorial from "./Tutorial";
import PricingInfo from "./PricingInfo";
import FindPartners from "./FindPartners";
import FindMatchesDialog from "../dialogs/FindMatchesDialog";

const INITIAL_SELECTED_TIMES = [];
for (let i = 6; i <= 21; i++) {
  INITIAL_SELECTED_TIMES.push({
    disabled: false,
    selected: false,
    text: i === 12 ? "12 pm" : i < 12 ? `${i} am` : `${i - 12} pm`,
    val: {
      endHr: i + 2,
      endMin: 0,
      startHr: i,
      startMin: 0,
    },
  });
}

class Parent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableNotifs: false,
      findMatchesLoading: false,
      loading: true,
      loadingText: "",
      matches: null,
      page: this.props.partnerPage || 0,
      selectedTimesCount: 0,
      selectedTimes: INITIAL_SELECTED_TIMES.map((obj) => ({ ...obj })),
      sendCalInvite: false,
      showFindMatchesDialog: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  async componentDidMount() {
    this.fetchTutorialUrl();
    this.setState({
      loading: false,
    });
  }

  fetchTutorialUrl = async () => {
    const urlFullTutorial = await fetchMediaURL(
      "media/full-tutorial-final.mp4"
    );
    this.setState({
      urlFullTutorial,
    });
  };

  handleNextBtn = async () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  /**
   * Save the selected times based on
   * component's state.
   */
  saveChosenTimes = () => {
    const selectedTimes = this.state.selectedTimes.filter(
      ({ selected }) => selected
    );
    if (selectedTimes.length === 0) {
      this.props.partnerPage &&
        alert("Please select at least one time to find partners.");
      this.setState({
        page: this.props.partnerPage ? this.state.page : this.state.page + 1,
      });
    } else {
      this.setState(
        {
          findMatchesLoading: true,
          showFindMatchesDialog: true,
        },
        async () => {
          let matches = [];
          if (isTestEnv()) {
            console.log("Returning no matches because test env...");
            matches = [];
          } else {
            matches = await saveSelectedTimes({
              selectedTimes,
              timezone: this.state.timezone,
            });
          }
          this.setState({
            findMatchesLoading: false,
            matches,
          });
        }
      );
    }
  };

  saveSettings = async () => {
    if (this.state.sendCalInvite) {
      sendCalInvite();
    }
    /* Write settings to DB */
    updateUserCalendarSettings(this.state.sendCalInvite);
    setNotifPreferences(this.state.enableNotifs);
    /* Log event */
    logAcquisitionEvent({
      action: "Entered Settings",
      label: "Settings Screen",
    });
  };

  updateNotifSettings = async () => {
    const enableNotifs = !this.state.enableNotifs;
    if (enableNotifs) {
      try {
        /* That function writes token data to firebase */
        await requestFirebaseNotificationPermission();
      } catch (error) {
        console.log("error: ", error);
        alert(
          `Error enabling notifications. If you are on Brave, go to brave://settings/privacy and toggle "Use Google services for push messaging"`
        );
      }
    }
    this.setState({
      enableNotifs,
    });
  };

  updateCalInvite = () => {
    this.setState({
      sendCalInvite: !this.state.sendCalInvite,
    });
  };

  handleTimeSelection = (text) => {
    const selectedTimes = this.state.selectedTimes.map((obj) => ({ ...obj }));
    const index = selectedTimes.findIndex((obj) => obj.text === text);
    const calObj = selectedTimes.splice(index, 1)[0];
    const selected = !calObj.selected;
    selectedTimes.splice(index, 0, {
      ...calObj,
      selected,
    });
    /* Disable next two times since each session is two hours */
    if (selectedTimes[index + 1]) {
      selectedTimes[index + 1].disabled = selected;
    }
    if (selectedTimes[index + 2]) {
      selectedTimes[index + 2].disabled = selected;
    }
    this.setState({
      selectedTimesCount: selectedTimes.filter((obj) => obj.selected).length,
      selectedTimes,
    });
  };

  getPageContent = (page) => {
    switch (page) {
      case 0:
        return (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <PricingInfo />
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <Grid container style={{ marginTop: 40 }} justify="flex-end">
                    <Button
                      onClick={() => {
                        logAcquisitionEvent({
                          action: "Acknowledged Price Info",
                          label: "Onboarding",
                        });
                        this.setState({
                          page: this.state.page + 1,
                        });
                      }}
                      size="large"
                      style={{ margin: 0 }}
                      variant="contained"
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="h4">Desktop App</Typography>
                  <br />
                  <Typography variant="body1">
                    The desktop app provides the best user experience. You can
                    load it for Macs{" "}
                    <a
                      download
                      href="/desktop-apps/Winston-darwin-x64.zip"
                      style={{ color: "white", display: "inline" }}
                    >
                      here
                    </a>{" "}
                    and for Windows{" "}
                    <a
                      href="/desktop-apps/Winston.exe"
                      style={{ color: "white", display: "inline" }}
                    >
                      here
                    </a>
                    . (The files can take up to a minute to begin their download
                    because they're quite big, so don't click the link more than
                    once. If the download doesn't work, please email us at
                    arjun@trywinston.com.)
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <Grid container style={{ marginTop: 40 }} justify="flex-end">
                    <Button
                      onClick={() => {
                        logAcquisitionEvent({
                          action: "Clicked thru Download",
                          label: "Onboarding",
                        });
                        this.setState({
                          page: this.state.page + 1,
                        });
                      }}
                      size="large"
                      style={{ margin: 0 }}
                      variant="contained"
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <Settings
                    enableNotifs={this.state.enableNotifs}
                    sendCalInvite={this.state.sendCalInvite}
                    updateCalInvite={this.updateCalInvite}
                    updateNotifSettings={this.updateNotifSettings}
                  />
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item sm={1} xs={false}></Grid>
                <Grid item sm={10} xs={12}>
                  <Grid container style={{ marginTop: 40 }} justify="flex-end">
                    <Button
                      onClick={() => {
                        this.saveSettings();
                        this.handleNextBtn();
                      }}
                      size="large"
                      style={{ margin: 0 }}
                      variant="contained"
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={1} xs={false}></Grid>
              </Grid>
            </Grid>
          </>
        );
      case 3:
        return (
          <>
            <Grid item xs={12}>
              <FindPartners
                handleTimeSelection={this.handleTimeSelection}
                selectedTimes={this.state.selectedTimes}
                timezone={this.state.timezone}
              />
            </Grid>
            <Grid item style={{ marginTop: 20 }} xs={12}>
              <Grid container justify="flex-end">
                <Button
                  onClick={this.saveChosenTimes}
                  size="large"
                  // style={{ margin: 0 }}
                  variant="contained"
                >
                  {this.props.partnerPage ? "Choose" : "Next"}
                </Button>
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: 10 }} xs={12}>
              <Grid container justify="flex-end">
                <Typography style={{ paddingRight: 8 }}>
                  ({this.state.selectedTimesCount} selected)
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            <Grid item xs={12}>
              <Tutorial videoUrl={this.state.urlFullTutorial} />
            </Grid>
            <Grid item style={{ marginTop: 20 }} xs={12}>
              <Grid container justify="flex-end">
                <Button
                  onClick={() => {
                    logAcquisitionEvent({
                      action: "Completed Onboarding",
                      label: "Tutorial Screen",
                    });
                    this.props.history.push("/");
                  }}
                  size="large"
                  style={{ margin: 0 }}
                  variant="contained"
                >
                  Finish
                </Button>
              </Grid>
            </Grid>
          </>
        );
      default:
        return <div></div>;
    }
  };

  render() {
    const {
      findMatchesLoading,
      loading,
      loadingText,
      matches,
      page,
      showFindMatchesDialog,
    } = this.state;

    if (loading) {
      return <Loading loadingText={loadingText} />;
    }

    return (
      <div style={{ paddingBottom: 50 }}>
        <Container maxWidth={page === 3 ? "md" : "sm"}>
          <Paper style={{ marginTop: 40 }} variant="outlined">
            <Grid container style={{ padding: 40 }}>
              {this.getPageContent(page)}
            </Grid>
          </Paper>
        </Container>
        <FindMatchesDialog
          closeThanksDialog={() =>
            this.setState({
              page: this.props.partnerPage
                ? this.state.page
                : this.state.page + 1,
              selectedTimes: INITIAL_SELECTED_TIMES.map((obj) => ({ ...obj })),
              selectedTimesCount: 0,
              showFindMatchesDialog: false,
            })
          }
          loading={findMatchesLoading}
          matches={matches}
          open={showFindMatchesDialog}
        />
      </div>
    );
  }
}

Parent.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(Parent);
