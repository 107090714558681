import { getHoursAndMinsFromTime } from "../utils/utils";

/**
 * Saves new project name to firebase and
 */
export const addNewProjectName = ({ projectName, projectNames }) => {
  const projectNamesCopy = [...projectNames];
  projectName && projectNamesCopy.push(projectName);
  projectNamesCopy.sort();
  return projectNamesCopy;
};

/**
 * Delete project label for task
 */
export const deleteProjectLabel = ({ id, tasks }) => {
  const tasksCopy = tasks.map((t) => ({ ...t }));
  const { index, task } = getTaskToEdit({ id, tasks: tasksCopy });
  task.projectName = "";
  tasksCopy.splice(index, 1, task);
  return {
    task,
    tasks: tasksCopy,
  };
};

/**
 * Delete task from state and firebase
 */
export const deleteTaskFromArrays = ({ tasks, taskId, taskIds }) => {
  return {
    tasks: tasks.filter(({ id }) => id !== taskId),
    taskIds: taskIds.filter((id) => id !== taskId),
  };
};

/**
 * Update tasks stored in state
 */
export const editTasks = ({ props, tasks, taskIds }) => {
  const { id } = props;
  const tasksCopy = tasks.map((t) => ({ ...t }));
  const taskIdsCopy = [...taskIds];
  /* Find task in the array or create it with defaults */
  const index = tasksCopy.findIndex((task) => task.id === id);
  let taskToEdit = {};
  // debugger;
  if (index === -1) {
    const standardProps = {
      completed: false,
      id,
      sessionId: "toDoList",
    };
    /* Add task and task id to state */
    taskToEdit = {
      ...standardProps,
      ...props,
    };
    tasksCopy.push(taskToEdit);
    // /* If newly shown task, place at either top or second to top */
    // if (props.showTask) {
    //   taskIdsCopy.splice(activeTask ? 1 : 0, 0, taskToEdit.id);
    // } else {
    //   /* Otherwise just put it in back of array */
    //   taskIdsCopy.push(taskToEdit.id);
    // }
    taskIdsCopy.push(taskToEdit.id);
  } else {
    /* Change existing item */
    const { index, task } = getTaskToEdit({ id, tasks: tasksCopy });
    taskToEdit = {
      ...task,
      ...props,
    };
    tasksCopy.splice(index, 1, taskToEdit);
    // /* If newly shown task, place at either top or second to top */
    // if (props.showTask) {
    //   taskIdsCopy.splice(index, 1);
    //   taskIdsCopy.splice(activeTask ? 1 : 0, 0, taskToEdit.id);
    // }
  }
  return {
    tasks: tasksCopy,
    taskIds: taskIdsCopy,
    taskToEdit,
  };
};

/**
 * Find task from state by ID.
 * Return its index and the task itself.
 */
export const getTaskToEdit = ({ id, tasks }) => {
  const index = tasks.findIndex((task) => task.id === id);
  return { index, task: { ...tasks[index] } };
};

export const getTimeLabelText = (task) => {
  if (!task || (!task.actualTime && !task.time)) {
    return "No timer";
  }
  let ms;
  const { actualTime = 0, time = 0 } = task;
  if (actualTime !== 0) {
    ms = actualTime * 1000;
  } else if (time !== 0) {
    ms = time * 1000;
  }
  const { hrs, mins } = getHoursAndMinsFromTime(ms);
  return hrs > 0 ? `${hrs} hr ${mins} min` : `${mins} min`;
};
