import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import ScheduleIcon from "@material-ui/icons/Schedule";

import { getTimeElapsedStr } from "../../utils/utils";

const INITIAL_STATE = {
  openSessionEndedDialog: false,
  timerInterval: null,
  timeElapsed: "N/A",
};

class PreviousSessionDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  getDataArr = ({
    dataPoints,
    endTime,
    completed,
    startTime,
    tasks,
    timeElapsed,
  }) => {
    return dataPoints.map((dataPoint) => {
      switch (dataPoint) {
        case "totalTasks":
          return {
            key: "totalTasks",
            text: tasks.length || 0,
          };
        case "startTime":
          return {
            key: "startTime",
            text: startTime,
          };
        case "timeElapsed":
          return {
            key: "timeElapsed",
            text: timeElapsed,
          };
        case "completed":
          return {
            key: "completed",
            text: completed,
          };
        case "endTime":
          return {
            key: "endTime",
            text: endTime,
          };
        default:
          return { key: "", text: "" };
      }
    });
  };

  handleDateChange = ({ date, type }) => {
    this.props.handleDateChange({ date, type });
  };

  render() {
    const { dataPoints = [], editPreviousTasks, previousSession } = this.props;
    const { endTime, startTime } = previousSession;

    const dataArr = this.getDataArr({
      dataPoints,
      endTime: endTime || "N/A",
      startTime: startTime || "N/A",
      tasks: previousSession.tasks,
      timeElapsed: endTime
        ? getTimeElapsedStr({
            startTime: new Date(startTime),
            endTime: new Date(endTime),
          })
        : "N/A",
    });

    return (
      <Grid container>
        {dataArr.map((dataPoint, index) => {
          let label = "";
          const { key } = dataPoint;
          if (key === "startTime") label = "Start Time: ";
          else if (key === "endTime") label = "End Time: ";
          else if (key === "timeElapsed") label = "Time Elapsed: ";
          else if (key === "totalTasks") label = "Tasks Completed: ";
          const showError =
            key === "startTime" && this.props.errorsStartDate
              ? true
              : key === "endTime" && this.props.errorsEndDate
              ? true
              : false;
          const errorText =
            key === "startTime" && showError
              ? this.props.errorsStartDate
              : key === "endTime" && showError
              ? this.props.errorsEndDate
              : "";
          return (
            // <Grid item key={index}  xs={12}>
            <Grid container style={{ height: 60 }}>
              {key === "startTime" || key === "endTime" ? (
                <KeyboardDateTimePicker
                  ampm={true}
                  error={showError}
                  format="MM/dd/yyyy hh:mm a"
                  helperText={errorText}
                  InputProps={{
                    disableUnderline: !editPreviousTasks,
                    readOnly: !editPreviousTasks,
                  }}
                  keyboardIcon={
                    editPreviousTasks ? <ScheduleIcon /> : <div></div>
                  }
                  label={label}
                  onChange={(date) =>
                    this.handleDateChange({ date, type: key })
                  }
                  value={key === "startTime" ? new Date(startTime) : endTime}
                  variant="inline"
                />
              ) : (
                <>
                  <Grid container>
                    {/* Copied this from start time css */}
                    <InputLabel
                      style={{ transform: "translate(0, 1.5px) scale(0.75)" }}
                    >
                      {label}
                    </InputLabel>
                  </Grid>
                  <Grid container style={{ marginTop: -16 }}>
                    <Typography>{dataPoint.text}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
            // </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default withRouter(PreviousSessionDataTable);
