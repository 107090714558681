import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { editTaskProjectNames } from "../utils/server";
import {
  fetchProjectNames,
  taskExistsWithProjectName,
  updateProjectNames,
} from "../firebase";

import Loading from "../components/Loading";

const styles = (theme) => ({
  textfield: {
    backgroundColor: theme.palette.background.paper,
  },
});

class ProjectNamesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      originalNames: [],
      projectNames: [],
      unchangedNames: [],
    };
  }

  async componentDidMount() {
    const projectNames = await fetchProjectNames();
    const arr = projectNames.map((projectName) => ({
      id: uuidv4(),
      projectName,
    }));
    this.setState({
      originalNames: arr.map((obj) => ({ ...obj })),
      projectNames: arr.map((obj) => ({ ...obj })),
      unchangedNames: arr.map((obj) => ({ ...obj })),
    });
  }

  handleDelete = async (index) => {
    const projectNames = this.state.projectNames.map((obj) => ({ ...obj }));
    const originalNames = this.state.originalNames.map((obj) => ({ ...obj }));
    const { projectName } = projectNames.splice(index, 1)[0];
    if (!originalNames.map((obj) => obj.projectName).includes(projectName)) {
      alert(
        "It looks like you edited this name, please revert it and then try to delete it."
      );
      return;
    }
    /* Check if there are any tasks associated with this name */
    const existingTasks = await taskExistsWithProjectName(projectName);
    if (existingTasks) {
      alert(
        "Can't delete project name because there are existing tasks associated with it."
      );
      return;
    }
    /* Update state */
    originalNames.splice(index, 1);
    this.setState({
      originalNames: [...originalNames],
      projectNames: [...projectNames],
    });
  };

  handleUpdateProjects = () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const projectNames = this.state.projectNames.map((obj) => ({ ...obj }));
        const originalNames = this.state.originalNames.map((obj) => ({
          ...obj,
        }));
        /* Save project name options to firebase */
        const updatedProjectNames = [
          ...new Set(projectNames.map(({ projectName }) => projectName)),
        ];
        try {
          await updateProjectNames(updatedProjectNames);
        } catch (error) {
          console.log("Error saving project names: ", error);
          return;
        }
        /* Change all existing tasks' names */
        /* TO DO - this will cause issues if one attempted edit fails and the others succeed */
        let index = 0;
        for (const originalName of originalNames.map(
          (obj) => obj.projectName
        )) {
          if (originalName !== projectNames[index].projectName) {
            try {
              await editTaskProjectNames({
                newName: projectNames[index].projectName,
                originalName,
              });
              console.log(
                `Successfully changed ${originalName} to ${projectNames[index].projectName}`
              );
            } catch (error) {
              console.log("Error with editing project names: ", error);
            }
          }
          index++;
        }
        const projectNamesForState = updatedProjectNames.map((projectName) => ({
          id: uuidv4(),
          projectName,
        }));
        /* Update state with new project names */
        this.setState(
          {
            loading: false,
            originalNames: projectNamesForState.map((obj) => ({ ...obj })),
            projectNames: projectNamesForState.map((obj) => ({ ...obj })),
            unchangedNames: projectNamesForState.map((obj) => ({ ...obj })),
          },
          () => {
            this.props.handleClose();
          }
        );
      }
    );
  };

  render() {
    const { loading, projectNames } = this.state;
    const { classes } = this.props;

    if (loading) {
      return (
        <Dialog fullWidth={true} maxWidth={"xs"} open={this.props.open}>
          <DialogTitle>Edit Project Names</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This can take a while, please don't refresh the page...
            </DialogContentText>
            <Loading bgColor={"#424242"} />
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <Dialog fullWidth={true} maxWidth={"xs"} open={this.props.open}>
        <DialogTitle>Edit Project Names</DialogTitle>
        <DialogContent>
          <DialogContentText>
            When you edit a project name you will update <b>every</b>{" "}
            session/task in the past with the new project name.
            <br />
            <br />
            You can only delete unused project names that are not associated
            with any sessions/tasks.
          </DialogContentText>
          <List>
            {projectNames.map((obj, index) => (
              <ListItem key={obj.id}>
                <IconButton onClick={() => this.handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
                <TextField
                  className={classes.textfield}
                  defaultValue={obj.projectName}
                  onChange={(e) => {
                    const projectNames = this.state.projectNames.map((obj) => ({
                      ...obj,
                    }));
                    projectNames[index].projectName = e.currentTarget.value;
                    this.setState({
                      projectNames,
                    });
                  }}
                  style={{ marginLeft: 5 }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              /* Reset state */
              this.setState(
                {
                  originalNames: this.state.unchangedNames.map((obj) => ({
                    ...obj,
                  })),
                  projectNames: this.state.unchangedNames.map((obj) => ({
                    ...obj,
                  })),
                },
                () => this.props.handleClose()
              );
            }}
          >
            Cancel
          </Button>
          <Button onClick={this.handleUpdateProjects} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ProjectNamesDialog.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(ProjectNamesDialog);
