import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { resetPassword } from "../firebase/auth";

import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  mainContainer: {
    height: "80%",
  },
  mainGridContainer: {
    width: "100%",
    height: "100%",
  },
  root: {
    backgroundColor: theme.palette.background.default,
  },
  rootFullScreen: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
      success: null,
    };
  }

  componentDidMount() {}

  updateUI = ({ ...stateData }) => {
    this.setState({ ...stateData });
  };

  handleChange = (e) => {
    this.setState({
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.handleResetPassword();
    }
  };

  handleResetPassword = () => {
    const { email } = this.state;
    resetPassword(email, this.updateUI);
  };

  render() {
    const { email, success } = this.state;
    const { classes } = this.props;

    if (success) {
      return (
        <div className={classes.root}>
          <Container
            className={classes.mainContainer}
            maxWidth="md"
            style={{ marginTop: 50 }}
          >
            <Grid
              className={classes.mainGridContainer}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Typography variant="h6">
                  {`Sent reset password email to ${email}. Please check your inbox and spam folder.`}
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={12}>
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  onClick={() => this.props.history.push("/login")}
                >
                  Go to Log In Page
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }

    return (
      <div className={classes.rootFullScreen}>
        <Container className={classes.mainContainer} maxWidth="md">
          <Grid
            className={classes.mainGridContainer}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <TextField
                id="email"
                fullWidth
                label="Email"
                onChange={this.handleChange}
                onKeyPress={(e) => this.keyPress(e)}
              />
              {this.state.error ? (
                <FormHelperText error={true}>{this.state.error}</FormHelperText>
              ) : (
                <div></div>
              )}
              <Button
                style={{ marginTop: 20 }}
                variant="contained"
                fullWidth
                onClick={this.handleResetPassword}
              >
                Reset Password
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(ResetPassword));
