import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { DragDropContext } from "react-beautiful-dnd";

import {
  deleteTask,
  fetchProjectNames,
  fetchToDoListTasks,
  fetchTaskIds,
  getSoundPreferences,
  updateFirebaseSession,
  updateProjectNames,
  updateTask,
  updateTaskIds,
} from "../firebase";
import { getUID, isNewUser as checkNewUser } from "../firebase/auth";
import { getMuiPropTypes, getTimeElapsedStr } from "../utils/utils.js";
import {
  addNewProjectName,
  deleteProjectLabel,
  deleteTaskFromArrays,
  editTasks,
  getTaskToEdit,
  getTimeLabelText,
} from "../task-card/index";
// import { hideIntercom, showIntercom } from "../intercom";
import { logSessionEvent } from "../analytics";
import { fetchMediaURL } from "../firebase";

import CurrentTask from "./CurrentTask";
import TaskCard from "../task-card/TaskCard";
import Column from "./Column";
import Loading from "../components/Loading";
import SubRequiredDialog from "../components/SubRequiredDialog";
import TutorialDialog from "../dialogs/TutorialDialog";

const styles = (theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  h6: theme.content.h6,
  infoIcon: theme.infoIcon.main,
  infoIconRoot: theme.infoIcon.root,
  maxItemSize: {
    flexGrow: 1,
    maxWidth: 576.11,
  },
  negativeMargin: {
    marginTop: -60,
  },
});

class SessionParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
    // hideIntercom();
  }

  componentWillUnmount() {
    // showIntercom();
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        this.fetchTutorial();
        this.updateProjectNames();
        this.fetchSoundSettings();
        const tasks = await fetchToDoListTasks();
        const taskIds = await fetchTaskIds();
        /* HACK PATCH -- data clean */
        const filteredTasks = tasks.filter(
          ({ id, showTask, value }) =>
            showTask && value && value !== "\n" && taskIds.includes(id)
        );
        const filteredIds = taskIds.filter((id) =>
          filteredTasks.some((t) => t.id === id)
        );
        filteredTasks.sort((a, b) => {
          const indexA = filteredIds.findIndex((id) => id === a.id);
          const indexB = filteredIds.findIndex((id) => id === b.id);
          return indexA - indexB;
        });
        this.setState({
          loading: false,
          toDoList: {
            ...this.state.toDoList,
            /* HACK -- data clean */
            tasks: [...new Set(filteredTasks)],
            taskIds: [...new Set(filteredIds)],
          },
        });
      }
    );
    /* TO DO revert changes so that recent sessions are loaded */
    // showContinueSession(this.updateUI);
  }

  fetchSoundSettings = async () => {
    const endBellEnabled = await getSoundPreferences();
    this.setState({
      endBellEnabled,
    });
  };

  fetchTutorial = async () => {
    const isNewUser = await checkNewUser();
    const tutorialUrl = await fetchMediaURL("media/dw-page-tutorial.mov");
    this.setState({
      showTutorial: isNewUser,
      tutorialUrl,
    });
  };

  updateProjectNames = async () => {
    const projectNames = await fetchProjectNames();
    this.setState({ projectNames });
  };

  updateUI = (stateData) => {
    /* TO DO -- check that isNewUser works */
    this.setState({ ...stateData });
  };

  getInitialState = () => {
    return {
      activeSession: false,
      addNewTaskId: uuidv4(),
      currentTask: {},
      /* TO DO -- this may cause errors as should be fetched from preferences */
      endBellEnabled: true,
      finishCurrentTaskDialog: false,
      loading: false,
      open: false,
      openSessionEndedDialog: false,
      projectNames: [],
      recentSession: null,
      /* Data relating to session */
      session: {
        endTime: "",
        projects: [],
        sessionId: uuidv4(),
        startTime: "",
        tasksCompleted: 0,
        taskIds: [],
      },
      showTutorial: false,
      /* Data relating to to do list as it is shown */
      toDoList: {
        completedTasks: [],
        completedTaskIds: [],
        tasks: [],
        taskIds: [],
      },
    };
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * Saves new project name to firebase and
   */
  addNewProjectName = async (projectName) => {
    const projectNames = addNewProjectName({
      projectName,
      projectNames: this.state.projectNames,
    });
    /* Save to firebase */
    await updateProjectNames(projectNames);
    /* Save to state */
    this.setState({ projectNames });
  };

  /**
   * Delete task from state and firebase
   */
  deleteTask = (taskId) => {
    const { tasks, taskIds } = deleteTaskFromArrays({
      tasks: this.state.toDoList.tasks,
      taskId,
      taskIds: this.state.toDoList.taskIds,
    });
    this.setState(
      {
        toDoList: {
          ...this.state.toDoList,
          tasks,
          /* Don't show this id anymore */
          taskIds,
        },
      },
      () => {
        /* Update firebase */
        updateTaskIds(this.state.toDoList.taskIds);
        deleteTask(taskId);
      }
    );
  };

  handleDeleteProjectLabel = (id) => {
    const { task, tasks } = deleteProjectLabel({
      id,
      tasks: this.state.toDoList.tasks,
    });
    /* Clear current task project name if needed */
    if (this.state.addNewTaskId === id) {
      this.setState({
        currentTask: {
          ...this.state.currentTask,
          projectName: "",
        },
      });
    }
    /* Update firebase if it's an existing task */
    if (this.state.addNewTaskId !== id) {
      updateTask(task);
    }
    this.setState({
      toDoList: {
        ...this.state.toDoList,
        tasks,
      },
    });
  };

  /**
   * Update tasks stored in state
   */
  editTasks = (props) => {
    const { tasks, taskIds, taskToEdit } = editTasks({
      activeTask: this.props.activeTask,
      props,
      tasks: this.state.toDoList.tasks,
      taskIds: this.state.toDoList.taskIds,
    });
    /* Set current task */
    if (props.id === this.state.addNewTaskId) {
      this.setState({
        currentTask: {
          ...this.state.currentTask,
          ...props,
        },
      });
    }
    /* Update firebase if task is showing */
    if (taskToEdit.showTask) {
      updateTask(taskToEdit);
      updateTaskIds(taskIds);
    }
    /* Sort */
    tasks.sort((a, b) => {
      const indexA = taskIds.findIndex((id) => id === a.id);
      const indexB = taskIds.findIndex((id) => id === b.id);
      return indexA - indexB;
    });
    /* Update state */
    this.setState({
      toDoList: {
        ...this.state.toDoList,
        tasks,
        taskIds,
      },
    });
  };

  /**
   * Sets new task id for root task
   */
  setNewTaskId = () => {
    this.setState({
      addNewTaskId: uuidv4(),
      /* Reset for new task */
      currentTask: {},
    });
  };

  /**
   * Called when CurrentTask increments a task
   */
  editCurrentTask = (props) => {
    const tasks = [...this.state.toDoList.tasks];
    const taskIds = [...this.state.toDoList.taskIds];
    const completedTasks = [...this.state.toDoList.completedTasks];
    const completedTaskIds = [...this.state.toDoList.completedTaskIds];
    const projects = [...this.state.session.projects];
    const sessionTaskIds = [...this.state.session.taskIds];
    const { index, task } = getTaskToEdit({ id: props.id, tasks });
    const taskToEdit = {
      ...task,
      ...props,
    };
    /* Update task info in state */
    tasks.splice(index, 1, taskToEdit);
    /* If task is completed, remove from tasks and taskIds and push to completed */
    if (props.completed) {
      const idIndex = taskIds.find((id) => id === taskToEdit.id);
      tasks.splice(index, 1);
      taskIds.splice(idIndex, 1);
      completedTasks.push(taskToEdit);
      completedTaskIds.push(taskToEdit.id);
    }
    /* Update project names in session state */
    if (taskToEdit.projectName && !projects.includes(taskToEdit.projectName)) {
      projects.push(taskToEdit.projectName);
    }
    /* Update session data + other state info */
    sessionTaskIds.push(taskToEdit.id);
    this.setState(
      {
        session: {
          ...this.state.session,
          projects,
          tasksCompleted: props.completed
            ? this.state.session.tasksCompleted + 1
            : this.state.session.tasksCompleted,
          taskIds: [...sessionTaskIds],
        },
        toDoList: {
          ...this.state.toDoList,
          completedTasks,
          completedTaskIds,
          tasks,
          taskIds,
        },
      },
      () => {
        /* Update firebase */
        updateTask(taskToEdit);
        updateTaskIds(taskIds);
        this.saveSessionData();
      }
    );
  };

  /**
   * Used when session is inactive and user wants to save data.
   * All this function does is mark the task as completed and save to firebase.
   * There is no need to update state because the session will end immediately
   * after this.
   */
  // markCompleted = (id) => {
  //   const { task } = this.getTaskToEdit(id);
  //   const taskToEdit = {
  //     ...task,
  //     completed: true,
  //   };
  //   /* Update firebase */
  //   updateTask(taskToEdit);
  // }

  /**
   * Save session data
   */
  saveSessionData = () => {
    /* Update state and firebase */
    this.setState(
      {
        session: {
          ...this.state.session,
          endTime: new Date().toISOString(),
        },
      },
      () => {
        updateFirebaseSession({
          ...this.state.session,
        });
      }
    );
  };

  /**
   * This function is ONLY called the first time the
   * start timer button is pressed. It sets the
   * session start time with current time.
   */
  setSessionStartTime = () => {
    logSessionEvent({
      action: "Session Started",
      label: getUID(),
    });
    /* Set up state when timer starts */
    this.setState({
      session: {
        ...this.state.session,
        startTime: new Date().toISOString(),
      },
    });
  };

  /* Set up state based on recent session data */
  handleContinueSession = () => {
    this.props.sessionContinued(this.state.recentSession);
    this.setState({ open: false });
  };

  dialogClosed = () => {
    this.setState(
      {
        ...this.getInitialState(),
      },
      () => {
        this.props.history.push("/");
      }
    );
  };

  onDragEnd = (result) => {
    const { destination, draggableId, source } = result;

    /* Moved outside of droppable zone */
    if (!destination) return;

    /* Dropped at same position */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    /* Moved within TDL */
    if (
      source.droppableId === "toDoList" &&
      destination.droppableId === "toDoList"
    ) {
      /* Edit order of arrays */
      const tasks = [...this.state.toDoList.tasks];
      const taskIds = [...this.state.toDoList.taskIds];
      taskIds.splice(source.index, 1);
      taskIds.splice(destination.index, 0, draggableId);
      tasks.sort((a, b) => {
        const indexA = taskIds.findIndex((id) => id === a.id);
        const indexB = taskIds.findIndex((id) => id === b.id);
        return indexA - indexB;
      });
      /* Update firebase */
      updateTaskIds(taskIds);
      this.setState({
        toDoList: {
          ...this.state.toDoList,
          tasks,
          taskIds,
        },
      });
      return;
    }
  };

  render() {
    const { classes, isNewUser } = this.props;
    const {
      addNewTaskId,
      loading,
      projectNames,
      session: { endTime, startTime, sessionId, tasksCompleted },
      showTutorial,
      toDoList: { completedTasks, tasks, taskIds },
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    const showTaskCardTooltip = !showTutorial && isNewUser && !tasks.length;

    /* Data for current task */
    const currentTaskId = taskIds[0] || "N/A";
    const currentTask = tasks.find((task) => task.id === currentTaskId);

    /* Data for column */
    const tasksToShow = tasks.concat(completedTasks).filter((t) => t.showTask);

    return (
      <>
        <Container maxWidth="md" style={{ height: "100%" }}>
          <Grid container justify="center">
            <Grid className={classes.maxItemSize} item>
              <CurrentTask
                currentTask={currentTask}
                currentTaskId={currentTaskId}
                editCurrentTask={this.editCurrentTask}
                endBellEnabled={this.state.endBellEnabled}
                isNewUser={isNewUser}
                sessionId={sessionId}
                setSessionStartTime={this.setSessionStartTime}
                startTime={this.state.session.startTime}
                tasks={tasksToShow}
                taskIds={taskIds}
                tasksCompleted={tasksCompleted}
                updateActiveTask={this.props.updateActiveTask}
              />
            </Grid>
            <Grid
              className={`${
                startTime || !currentTask || !currentTask.showTask
                  ? ""
                  : classes.negativeMargin
              }`}
              item
              xs={12}
            >
              <Grid container justify="center">
                {/* Add New Task */}
                <Grid className={classes.maxItemSize} item>
                  <Grid
                    alignItems="center"
                    container
                    style={{ paddingBottom: 10 }}
                  >
                    <Typography
                      align="left"
                      display="inline"
                      style={{ fontWeight: 600 }}
                      variant="h6"
                    >
                      To Do List
                    </Typography>
                    <Tooltip
                      arrow
                      placement="right"
                      title="Keep a running list of the tasks you want to work on. If you don't complete certain tasks during a deep work session, they'll remain in this list for your next session."
                    >
                      <InfoIcon
                        classes={{ root: classes.infoIconRoot }}
                        style={{ marginLeft: 10 }}
                      />
                    </Tooltip>
                  </Grid>
                  <TaskCard
                    addNewProjectName={this.addNewProjectName}
                    deleteTask={this.deleteTask}
                    editTasks={this.editTasks}
                    handleDeleteProjectLabel={this.handleDeleteProjectLabel}
                    isRootTask={true}
                    projectNames={projectNames}
                    projectValue={this.state.currentTask.projectName}
                    setNewTaskId={this.setNewTaskId}
                    showTaskCardTooltip={showTaskCardTooltip}
                    taskId={addNewTaskId}
                    textFieldValue={""}
                    timeLabelText={getTimeLabelText(this.state.currentTask)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* TO DO LIST */}
            {tasksToShow.length && tasksToShow[0].showTask ? (
              <Grid className={classes.onyx} item xs={12}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Grid container justify="center">
                    <Grid
                      className={`${classes.onyx} ${classes.maxItemSize}`}
                      item
                    >
                      <Column
                        addNewProjectName={this.addNewProjectName}
                        deleteTask={this.deleteTask}
                        editTasks={this.editTasks}
                        handleDeleteProjectLabel={this.handleDeleteProjectLabel}
                        isNewUser={isNewUser}
                        projectNames={projectNames}
                        tasks={tasksToShow}
                      />
                    </Grid>
                  </Grid>
                </DragDropContext>
              </Grid>
            ) : (
              <div></div>
            )}
            <div>
              <Dialog
                open={this.state.openSessionEndedDialog}
                onClose={this.dialogClosed}
              >
                <DialogTitle>Great Job</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ color: "white" }}>
                    You did {getTimeElapsedStr({ endTime, startTime })} of deep
                    work! To make deep work a habit, remember to give yourself a
                    reward after each session.
                    <br />
                    <br />
                    <b>Any issues with the session data?</b> Click the edit
                    button below to adjust your data.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      /* Reset state and go to session for editing */
                      this.setState(
                        {
                          ...this.getInitialState(),
                        },
                        () => {
                          this.props.history.push(`/previous/${sessionId}`);
                        }
                      );
                    }}
                  >
                    Edit Session
                  </Button>
                  <Button onClick={this.dialogClosed} variant="contained">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            {/* <div>
                <Dialog
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Recent Session Detected"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You recently started a work session, would you like to
                      continue it or start a new one?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="white">
                      Start New
                    </Button>
                    <Button
                      onClick={this.handleContinueSession}
                      color="white"
                      autoFocus
                    >
                      Continue Previous
                    </Button>
                  </DialogActions>
                </Dialog>
              </div> */}
            <div>
              <Dialog
                open={this.state.finishCurrentTaskDialog}
                onClose={() =>
                  this.setState({ finishCurrentTaskDialog: false })
                }
              >
                <DialogTitle>Task in Progress</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      "We've detected an active task. Please mark it as finished and then end the session."
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({ finishCurrentTaskDialog: false })
                    }
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
          {startTime ? (
            <Fab
              className={classes.fab}
              onClick={() => {
                /* Tell user to finish current task first */
                if (this.props.activeTask) {
                  this.setState({ finishCurrentTaskDialog: true });
                  return;
                }
                this.saveSessionData();
                this.setState({
                  openSessionEndedDialog: true,
                });
              }}
              variant="extended"
            >
              End Session
            </Fab>
          ) : (
            <div></div>
          )}
          <TutorialDialog
            closeDialog={() => this.setState({ showTutorial: false })}
            open={this.state.showTutorial}
            url={this.state.tutorialUrl}
          />
        </Container>
        <SubRequiredDialog />
      </>
    );
  }
}

SessionParent.propTypes = {
  ...getMuiPropTypes(),
};

export default withStyles(styles)(SessionParent);
