import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { getMuiPropTypes } from "../utils/utils";
import { signOut as signOutUser } from "../firebase/auth";
import { drawerWidthMd, drawerWidthSm } from "../theme/theme";

import { Desktop, Mobile, Tablet } from "../responsive";
import ActiveTaskDialog from "../dialogs/ActiveTaskDialog";

const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.mainGreen.color}`,
    // flexGrow: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  // appBarRoot: theme.appBarWithSideBar,
  label: {
    fontWeight: 600,
  },
  uncontainedButtonLabel: {
    fontWeight: 600,
    fontSize: 17,
  },
  title: {
    cursor: "pointer",
    fontSize: 28,
    fontWeight: theme.palette.customFonts.fontWeight.thick,
  },
  typographyNavBar: {
    cursor: "pointer",
    fontSize: 17,
    fontWeight: theme.palette.customFonts.fontWeight.thick,
    marginLeft: 10,
  },
});

class CustomAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      loading: true,
      openMenu: false,
      openSessionEndedDialog: false,
      requestedRoute: "",
      routes: [],
    };
  }

  getSignedOutRoutes = (pathname) => {
    switch (pathname) {
      case "/onboarding":
        return [];
      case "/login":
        return [];
      case "/signup":
        return [];
      default:
        return [
          {
            buttonText: "Pricing",
            route: "/pricing",
          },
          {
            buttonText: "Contact",
            route: "/contact",
          },
          {
            buttonText: "Log In",
            route: "/login",
          },
          {
            buttonText: "Start Free Trial",
            route: "/signup",
            variant: "contained",
          },
        ];
    }
  };

  getSignedInRoutes = (pathname) => {
    switch (pathname) {
      case "/onboarding":
        return [];
      case "/login":
        return [];
      case "/signup":
        return [];
      default:
        return [
          {
            buttonText: "Contact",
            route: "/contact",
          },
          {
            buttonText: "Tutorials",
            route: "/learn",
          },
          {
            buttonText: "Sign Out",
            route: "/",
            signOut: true,
          },
        ];
    }
  };

  getMobileSignedInRoutes = (pathname) => {
    switch (pathname) {
      case "/onboarding":
        return [];
      case "/login":
        return [];
      case "/signup":
        return [];
      default:
        return [
          {
            buttonText: "Home",
            route: "/",
          },
          {
            buttonText: "Start Deep Work",
            route: "/start-session",
          },
          {
            buttonText: "Previous Sessions",
            route: "/previous",
          },
          {
            buttonText: "Tutorials",
            route: "/learn",
          },
          {
            buttonText: "Contact",
            route: "/contact",
          },
          {
            buttonText: "Settings",
            route: "/settings",
          },
          {
            buttonText: "Sign Out",
            route: "/",
            signOut: true,
          },
        ];
    }
  };

  getWinstonContainer = () => {
    return (
      <Grid container>
        <Typography
          align="left"
          display="inline"
          onClick={() => this.handleRoute({ route: "/" })}
          style={{ cursor: "pointer" }}
          variant="h4"
        >
          Winston
        </Typography>
      </Grid>
    );
  };

  handleRoute = async ({ route, signOut = false }) => {
    if (this.props.activeTask) {
      this.setState({
        openSessionEndedDialog: true,
        requestedRoute: route,
      });
      return;
    }
    if (signOut) {
      await signOutUser();
    }
    if (this.state.anchorEl) {
      this.setState({
        anchorEl: null,
      });
    }
    this.props.history.push(route);
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleCloseDialog = ({ activeTask, redirect }) => {
    this.setState(
      {
        anchorEl: null,
        openSessionEndedDialog: false,
      },
      () => {
        this.props.updateActiveTask({
          activeTask,
          route: redirect ? this.state.requestedRoute : null,
        });
      }
    );
  };

  render() {
    const { classes, loading, pathname, user } = this.props;
    const routes = loading
      ? []
      : user
      ? this.getSignedInRoutes(pathname)
      : this.getSignedOutRoutes(pathname);
    const mobileRoutes = loading
      ? []
      : user
      ? this.getMobileSignedInRoutes(pathname)
      : this.getSignedOutRoutes(pathname);

    return (
      <div>
        <Mobile>
          <AppBar
            className={classes.appBar}
            elevation={0}
            style={{ width: `100%` }}
          >
            <Container maxWidth={"md"}>
              <Toolbar>
                <Grid container alignItems="center">
                  {this.props.pathname === "/onboarding" || !this.props.user ? (
                    <Grid item xs={5}>
                      <Grid container>{this.getWinstonContainer()}</Grid>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                  <Grid item xs={this.props.user ? 12 : 7}>
                    <Grid alignItems="center" container justify="flex-end">
                      {mobileRoutes.length ? (
                        <>
                          <IconButton
                            onClick={(e) =>
                              this.setState({
                                anchorEl: e.currentTarget,
                              })
                            }
                          >
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            onClose={this.handleClose}
                            open={Boolean(this.state.anchorEl)}
                          >
                            {mobileRoutes.map(
                              (
                                { buttonText, route, signOut = false },
                                index
                              ) => (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    this.handleRoute({ route, signOut })
                                  }
                                >
                                  {buttonText}
                                </MenuItem>
                              )
                            )}
                          </Menu>{" "}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>
        </Mobile>
        <Tablet>
          <AppBar
            className={classes.appBar}
            elevation={0}
            style={{
              width: `calc(100% - ${
                this.props.fullWidth ? "0px" : drawerWidthSm
              }px)`,
            }}
          >
            <Container maxWidth={"md"}>
              <Toolbar>
                <Grid container alignItems="center">
                  {this.props.pathname === "/onboarding" || !this.props.user ? (
                    <Grid item xs={5}>
                      <Grid container>{this.getWinstonContainer()}</Grid>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                  <Grid item xs={this.props.user ? 12 : 7}>
                    <Grid alignItems="center" container justify="flex-end">
                      {routes.length ? (
                        <>
                          <IconButton
                            onClick={(e) =>
                              this.setState({
                                anchorEl: e.currentTarget,
                              })
                            }
                          >
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            onClose={this.handleClose}
                            open={Boolean(this.state.anchorEl)}
                          >
                            {routes.map(
                              (
                                { buttonText, route, signOut = false },
                                index
                              ) => (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    this.handleRoute({ route, signOut })
                                  }
                                >
                                  {buttonText}
                                </MenuItem>
                              )
                            )}
                          </Menu>{" "}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>
        </Tablet>
        <Desktop>
          <AppBar
            className={classes.appBar}
            elevation={0}
            style={{
              width: `calc(100% - ${
                this.props.fullWidth
                  ? "0px"
                  : this.props.sideBarExpanded
                  ? drawerWidthMd
                  : drawerWidthSm
              }px)`,
            }}
          >
            <Container maxWidth={this.props.user ? false : "md"}>
              <Toolbar>
                <Grid container alignItems="center">
                  {this.props.pathname === "/onboarding" || !this.props.user ? (
                    <Grid item xs={5}>
                      <Grid container>{this.getWinstonContainer()}</Grid>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                  <Grid item xs={this.props.user ? 12 : 7}>
                    <Grid alignItems="center" container justify="flex-end">
                      {routes.length ? (
                        <>
                          {routes.map(
                            (
                              { buttonText, route, signOut = false, variant },
                              index
                            ) => {
                              if (variant) {
                                return (
                                  <Button
                                    classes={{ label: classes.label }}
                                    key={index}
                                    onClick={() =>
                                      this.handleRoute({ route, signOut })
                                    }
                                    variant={variant}
                                  >
                                    {buttonText}
                                  </Button>
                                );
                              }
                              return (
                                <Typography
                                  className={classes.typographyNavBar}
                                  display="inline"
                                  key={index}
                                  onClick={() =>
                                    this.handleRoute({ route, signOut })
                                  }
                                  style={{ padding: 8 }}
                                  variant="h2"
                                >
                                  {buttonText}
                                </Typography>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>
        </Desktop>
        <div>
          <ActiveTaskDialog
            handleCloseDialog={this.handleCloseDialog}
            open={this.state.openSessionEndedDialog}
          />
        </div>
      </div>
    );
  }
}

CustomAppBar.propTypes = {
  ...getMuiPropTypes(),
  history: PropTypes.object,
  loading: PropTypes.bool,
};

export default withRouter(withStyles(styles)(CustomAppBar));
