import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CountUp from "react-countup";

import { getMuiPropTypes, getRandomInt, TIME_MAP } from "../utils/utils";

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
});

class DemoStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avg: getRandomInt({ max: 8, min: 1 }),
      streak: getRandomInt({ max: 9, min: 1 }),
      today: getRandomInt({ max: 8, min: 1 }),
    };
    this.timerId = setInterval(() => this.setStats(), TIME_MAP.ONE_SECOND * 8);
  }

  setStats = () => {
    this.setState({
      avg: getRandomInt({ max: 8, min: 1 }),
      streak: getRandomInt({ max: 9, min: 1 }),
      today: getRandomInt({ max: 8, min: 1 }),
    });
  };

  render() {
    const { classes, toolTipContent } = this.props;
    const { avg, streak, today } = this.state;

    /* Items to be displayed in grid */
    const items = [
      {
        subtitle: "7-Day Avg.",
        toolTip: toolTipContent[0],
        value: avg,
      },
      {
        subtitle: "Today",
        toolTip: toolTipContent[1],
        value: today,
      },
      {
        subtitle: "Streak",
        toolTip: toolTipContent[2],
        value: streak,
      },
    ];

    return (
      <>
        <div className={classes.root}>
          <Grid
            alignItems="center"
            container
            justify={this.props.justifyContainer}
          >
            {items.map(({ subtitle, value }, index) => {
              return (
                <Grid item key={index} xs={4}>
                  {/* <Tooltip arrow title={toolTip}> */}
                  {/* STATS ELEMENT */}
                  <Typography
                    align="center"
                    style={{ color: "#E5E5E5", fontWeight: 600 }}
                    variant="body1"
                  >
                    {subtitle}
                  </Typography>
                  <CountUp
                    // decimals={index === 2 ? 0 : 1}
                    delay={0}
                    duration={5}
                    end={parseFloat(value)}
                    start={0}
                    suffix={index === 2 ? " Days" : " Hrs"}
                  >
                    {({ countUpRef }) => {
                      return (
                        <div>
                          <span
                            ref={countUpRef}
                            style={{
                              color: "white",
                              fontSize: "2.125rem",
                              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                              fontWeight: 400,
                              lineHeight: 1.235,
                              letterSpacing: "0.00735em",
                            }}
                          />
                        </div>
                      );
                    }}
                  </CountUp>
                  {/* </Tooltip> */}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </>
    );
  }
}

DemoStats.propTypes = {
  ...getMuiPropTypes(),
  justifyContainer: PropTypes.string,
  toolTipContent: PropTypes.array,
};

export default withStyles(styles)(DemoStats);
