// import { flatten } from "./array";
// import { messagePathSplit } from "./message";

// function cloneArray(array) {
//   return array.slice(0);
// }

// function cloneDate(date) {
//   return new Date(date.getTime());
// }

/**
 * Deeply merges plain objects.
 *
 * Eg.
 * objectDeepAssign( { a: { b: 1, c: 2 } }, { a: { b: 3, d: 4 } }, ... )
 * -> { a: { b: 3, c: 2, d: 4 } }
 *
 * Code from https://github.com/rxaviers/cldrjs/blob/master/src/util/json/merge.js
 */
// export function objectDeepAssign(destination, ...sources) {
//   sources.forEach(function (source) {
//     var prop;
//     for (prop in source) {
//       if (Array.isArray(source[prop])) {
//         // Clone and set arrays.
//         destination[prop] = cloneArray(source[prop]);
//       } else if (source[prop] instanceof Date) {
//         // Clone and set dates.
//         destination[prop] = cloneDate(source[prop]);
//       } else if (typeof source[prop] === "object") {
//         // Merge objects.
//         destination[prop] = objectDeepAssign(
//           {},
//           destination[prop],
//           source[prop]
//         );
//       } else {
//         // Set new values.
//         destination[prop] = source[prop];
//       }
//     }
//   });
//   return destination;
// }

/**
 * Deeply clones an object.
 */
// export function objectDeepClone(...sources) {
//   return objectDeepAssign({}, ...sources);
// }

/* Gets value in object path
 * @param {object} obj
 * @param {string|array} path
 * @examples
 * get({foo: 1, bar: {baz: 2}}, 'bar.baz');
 * > 2
 * get({foo: 1, bar: {baz: 2}}, 'foo.bar.baz.quax');
 * > undefined
 * NOTE: Asterisk support for Node.js only (reduced bytes)
 */
// export let objectGet;
// if (process.env.BABEL_ENV === "module" || process.env.BABEL_ENV === "browser") {
//   // Client-side
//   objectGet = function (obj, path) {
//     if (typeof path === "string") {
//       path = path.split(".");
//     }
//     if (!path.length) {
//       return obj;
//     }
//     if (process.env.NODE_ENV !== "production") {
//       if (path.some((part) => part === "*")) {
//         throw new Error(
//           "Internal error: objectGet asterisk support NOT available"
//         );
//       }
//     }
//     var prop = path.slice(-1);
//     var node = path.slice(0, -1).reduce(function (node, item) {
//       return node[item] || {};
//     }, obj);
//     return node[prop];
//   };
// } else {
//   // Server-side
//   objectGet = function (obj, path) {
//     if (typeof path === "string") {
//       path = path.split(".");
//     }
//     if (!path.length) {
//       return obj;
//     }
//     if (path.some((part) => part === "*")) {
//       return objectGetAsterisk(obj, path);
//     }
//     var prop = path.slice(-1);
//     var node = path.slice(0, -1).reduce(function (node, item) {
//       return node[item] || {};
//     }, obj);
//     return node[prop];
//   };
// }

// function objectGetAsterisk(obj, path, result = []) {
//   let asteriskPos;

//   const asteriskFound = path.some((part, i) => {
//     asteriskPos = i;
//     return part === "*";
//   });

//   // Last part of the path where no asterisk is found: get value given this last path and concat it to the result array.
//   if (!asteriskFound) {
//     const value = objectGet(obj, path);
//     if (!value) {
//       return [];
//     }
//     result.push(value);
//     return [result];
//   }

//   // Walking the path containing asterisk, i.e., push key into result array and keep walking.
//   if (asteriskPos) {
//     obj = objectGet(obj, path.slice(0, asteriskPos));
//   }
//   if (!obj && typeof obj !== "object") {
//     return [];
//   }
//   return flatten(
//     Object.keys(obj).map(function (key) {
//       return objectGetAsterisk(
//         obj[key],
//         path.slice(asteriskPos + 1),
//         result.concat(key)
//       );
//     })
//   );
// }

/**
 * Return an array of object leafs. It's runs a DFS (depth-first-search) recursion.
 * @param {object} obj
 * leafs({foo: 1, bar: {baz: 2}})
 * // > [
 * //   {path: ["foo"], value: 1},
 * //   {path: ["bar", "baz"], value: 2}
 * // ]
 */
export function _objectLeafs(obj, isLeaf, cb, path = []) {
  if (isLeaf(obj)) {
    return cb({ value: obj, path });
  }
  Object.keys(obj).forEach((key) =>
    _objectLeafs(obj[key], isLeaf, cb, path.concat(key))
  );
}
export function objectLeafs(
  obj,
  isLeaf = function (ele) {
    return typeof ele !== "object";
  }
) {
  const ret = [];
  _objectLeafs(obj, isLeaf, function (entry) {
    ret.push(entry);
  });
  return ret;
}

/**
 * An example is worth 1000 words... =)
 *
 * objectKeysSplit({ "a.b.c[0]": "value" }, ["."])
 * // > { a: { b: { "c[0]": "value" } } }
 *
 * objectKeysSplit({ "a.b.c[0]": "value" }, [".", "[]"])
 * // > a: { b: { c: { "0": "value" } } }
 */
// export function objectKeysSplit(data, separators) {
//   if (!separators) {
//     return data;
//   }

//   const modifiedData = {};
//   objectLeafs(data).forEach(({ path, value }) => {
//     objectSet(modifiedData, messagePathSplit(path, separators), value);
//   });
//   return modifiedData;
// }

/**
 * Sets value in object path
 * @param {object} obj
 * @param {string|array} path
 * @param {*} value
 * @examples
 * set({foo: 1}, 'bar/baz', 2);
 * // > {foo: 1, bar: {baz: 2}}
 */
export function objectSet(obj, path, value) {
  if (typeof path === "string") {
    path = path.split(".");
  }
  var prop = path.slice(-1);
  var node = path.slice(0, -1).reduce(function (node, item) {
    return (node[item] = node[item] || {});
  }, obj);
  node[prop] = value;
  return obj;
}

/**
 * Slices an object given a path.
 */
// export function objectSlice(obj, path = []) {
//   return objectSet({}, path, objectGet(obj, path));
// }

/**
 * Shallow merges an arary of objects.
 * shallowMerge([{ a: { i: 1 }}, { b: 2}, { a: { x: 99 }}])
 * > { a: { x: 99 }, b: 2 }
 */
export function shallowMerge(arrayOfObjects) {
  return Object.assign({}, ...arrayOfObjects);
}
