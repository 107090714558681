import React from "react";
import { withRouter } from "react-router-dom";
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  /* Switch --> */
  checked: {},
  switchRoot: {
    /* https://stackoverflow.com/questions/56812652/positioning-material-ui-label-to-the-left-with-left-alignment */
    display: "flex",
    justifyContent: "space-between",
  },
  switchBase: {
    color: "#bdbdbd",
    "&$checked": {
      color: theme.palette.customButtons.backgroundColor.default,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.customButtons.backgroundColor.default,
    },
  },
  track: {},
  /* <-- Switch */
});

class Settings extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container style={{ marginTop: 40 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Create a Habit</Typography>
            <br />
            <Typography align="center" variant="body1">
              You have a 40% higher chance of making deep work a habit with two
              daily reminders.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 40 }} xs={12}>
            {/* <FormControlLabel
              classes={{ root: classes.switchRoot }}
              control={
                <Switch
                  checked={this.props.sendCalInvite}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={this.props.updateCalInvite}
                />
              }
              label="Calendar Invites"
              labelPlacement="start"
            />
            <FormHelperText
              style={{ marginBottom: 15, marginLeft: 16, paddingRight: 50 }}
            >
              Get editable invites for deep work sessions.
            </FormHelperText> */}
            <FormControlLabel
              classes={{ root: classes.switchRoot }}
              control={
                <Switch
                  checked={this.props.enableNotifs}
                  classes={{
                    checked: classes.checked,
                    switchBase: classes.switchBase,
                    track: classes.track,
                  }}
                  onChange={this.props.updateNotifSettings}
                />
              }
              label="Notifications"
              labelPlacement="start"
              style={{ textAlign: "left" }}
            />
            <FormHelperText style={{ marginLeft: 16, paddingRight: 50 }}>
              Get reminders to do deep work.
            </FormHelperText>
          </Grid>
        </Grid>
      </>
    );
  }
}

Settings.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(Settings));
