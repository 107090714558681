import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class FindMatchesDialog extends React.Component {
  render() {
    const { loading, matches } = this.props;
    if (loading) {
      return (
        <Dialog open={this.props.open}>
          <DialogTitle>Deep Work Buddies</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Finding you deep work partners...
            </DialogContentText>
            <div style={{ paddingBottom: 20, paddingTop: 20 }}>
              <div style={{ paddingLeft: "40%" }}>
                <CircularProgress />
              </div>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={() => this.props.closeThanksDialog()}
              variant="contained"
            >
              Close
            </Button>
          </DialogActions> */}
        </Dialog>
      );
    }
    if (matches) {
      return (
        <Dialog open={this.props.open}>
          <DialogTitle>Deep Work Buddies</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {matches.length
                ? `We found you ${matches.length} matches based on the times you entered! Please check your email for more details.`
                : "We couldn't find any matches for those times. As soon as we do, we'll send you an email!"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeThanksDialog} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <div></div>;
  }
}

FindMatchesDialog.propTypes = {
  closeThanksDialog: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  matches: PropTypes.array,
  open: PropTypes.bool,
};

export default withRouter(FindMatchesDialog);
