import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

class Loading extends React.Component {
  render() {
    const { loadingText } = this.props;
    return (
      <div>
        <Grid
          alignItems="center"
          container
          justify="center"
          style={{
            backgroundColor: this.props.bgColor || "",
            height: loadingText ? "" : "100vh",
            paddingTop: loadingText ? "64" : "",
          }}
        >
          <Grid item xs={12}>
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          </Grid>
          {loadingText ? (
            <Grid item style={{ paddingTop: 20 }} xs={12}>
              <Typography>{loadingText}</Typography>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
      </div>
    );
  }
}

Loading.propTypes = {
  bgColor: PropTypes.string,
  loadingText: PropTypes.string,
};

export default Loading;
