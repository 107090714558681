import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class StripeRedirectDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Redirecting you to Stripe checkout...
          </DialogContentText>
          <div style={{ paddingBottom: 20, paddingTop: 20 }}>
            <div style={{ paddingLeft: "40%" }}>
              <CircularProgress />
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button
            onClick={() => this.props.closeThanksDialog()}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    );
  }
}

StripeRedirectDialog.propTypes = {
  closeThanksDialog: PropTypes.func,
  history: PropTypes.object,
  open: PropTypes.bool,
};

export default withRouter(StripeRedirectDialog);
