import ReactGA from "react-ga";
import { isTestEnv } from "../utils/utils";

export const LABEL_NAV_BAR = "NavBar";

export const logAuthEvent = ({ action = "", label = "" }) => {
  if (isTestEnv()) {
    console.log("In test mode, otherwise would have logged auth event.");
  } else {
    ReactGA.event({ category: "Auth", action, label });
  }
};

export const logAcquisitionEvent = ({
  action = "",
  label = "",
  value = "",
}) => {
  const event = {
    action,
    category: "Acquisition",
    label,
  };
  if (value) {
    event.value = value;
  }
  if (isTestEnv()) {
    console.log(
      `In test mode, otherwise would have logged acquisition event: ${JSON.stringify(
        event,
        null,
        2
      )}`
    );
  } else {
    ReactGA.event(event);
  }
};

export const logNavigationEvent = ({ action = "", label = "" }) => {
  if (isTestEnv()) {
    console.log("In test mode, otherwise would have logged navigation event.");
  } else {
    ReactGA.event({ category: "Navigation", action, label });
  }
};

export const logPageView = (page) => {
  if (isTestEnv()) {
    console.log(`In test mode, otherwise would have logged pageview: ${page}.`);
  } else {
    ReactGA.pageview(page);
  }
};

export const logPreviousSessionEvent = ({ action = "", label = "" }) => {
  if (isTestEnv()) {
    console.log(
      "In test mode, otherwise would have logged previous session event."
    );
  } else {
    ReactGA.event({ category: "Previous Session", action, label });
  }
};

export const logSessionEvent = ({ action = "", label = "" }) => {
  if (isTestEnv()) {
    console.log("In test mode, otherwise would have logged session event.");
  } else {
    ReactGA.event({ category: "Session", action, label });
  }
};

export const logStripeEvent = ({ action = "", label = "" }) => {
  if (isTestEnv()) {
    console.log("In test mode, otherwise would have logged auth event.");
  } else {
    ReactGA.event({ category: "Stripe", action, label });
  }
};
