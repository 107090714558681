import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Container,
  Divider,
  // FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

/* https://stripe.com/docs/payments/accept-a-payment?integration=elements */
import { loadStripe } from "@stripe/stripe-js";

import { Desktop, MobileOrTablet } from "../responsive";
import { getStripeCheckout } from "../utils/server";
import { getMuiPropTypes, isTestEnv } from "../utils/utils";
import { getUserSignUpDate } from "../firebase";

import StripeRedirectDialog from "../dialogs/StripeRedirectDialog";
import Loading from "../components/Loading";

const stripeKey = isTestEnv()
  ? "pk_test_Gu7OrTnobqle8bdvH3BB7b83"
  : "pk_live_di77JMFzf1AZi2s1zKqhYEZN";
const stripePromise = loadStripe(stripeKey);

const discountedPrices = [
  {
    discountedPrice: 5,
    plan: "1 Month",
    price: 10,
    priceId: isTestEnv()
      ? "price_1HxkcWAOBzzTsZ6E5gaQXj7b"
      : "price_1I4B30AOBzzTsZ6EdTV5c4Cw",
  },
  {
    discountedPrice: 2,
    plan: "12 Months",
    price: 4,
    priceId: isTestEnv()
      ? "price_1HxkcWAOBzzTsZ6EQCjdN3HU"
      : "price_1I4B30AOBzzTsZ6EBByvYQbQ",
  },
];

const regularPrices = [
  {
    plan: "1 Month",
    price: 7.99,
    priceId: isTestEnv()
      ? "price_1HxkcWAOBzzTsZ6E5gaQXj7b"
      : "price_1I8alrAOBzzTsZ6EwkoJywHc",
  },
  {
    plan: "12 Months",
    price: 3.99,
    priceId: isTestEnv()
      ? "price_1HxkcWAOBzzTsZ6EQCjdN3HU"
      : "price_1I8alEAOBzzTsZ6ETUcQQ1AD",
  },
];

class ChoosePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      newYearsDiscount: false,
      pricingRoute: this.props.history.location.pathname.includes("/pricing"),
      showDialog: false,
    };
  }

  async componentDidMount() {
    if (this.props.user) {
      const signUpDate = await getUserSignUpDate();
      if (signUpDate && moment(signUpDate).isBefore("2021-01-13")) {
        this.setState({
          newYearsDiscount: true,
        });
      }
    }
    this.setState({
      loading: false,
    });
  }

  /**
   * Redirect to Stripe checkout when a user
   * selects a plan.
   */
  handleGetStarted = async (priceId) => {
    const { pricingRoute } = this.state;
    if (pricingRoute) {
      this.props.history.push("/signup");
    } else {
      this.setState(
        {
          showDialog: true,
        },
        async () => {
          const stripe = await stripePromise;
          const sessionId = await getStripeCheckout(priceId);
          await stripe.redirectToCheckout({
            sessionId,
          });
          /* TO DO -- get result from redirectToCheckout and handle errors */
          // if (result.error) {}
        }
      );
    }
  };

  render() {
    const { loading, newYearsDiscount, pricingRoute } = this.state;

    if (loading) {
      return <Loading />;
    }

    const prices = newYearsDiscount ? discountedPrices : regularPrices;

    return (
      <Container maxWidth="sm">
        <Paper style={{ marginTop: 40 }} variant="outlined">
          <Grid container style={{ padding: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {pricingRoute ? "Pricing Options" : "Choose Your Plan"}
              </Typography>
              <br />
              <Typography component={"span"} variant="body1">
                {
                  "Improve your deep work for the price of a coffee or two each month. "
                }
                {pricingRoute ? <b>Free 14-day trial. No card needed.</b> : ""}
                {pricingRoute ? <br /> : <div></div>}
                {newYearsDiscount ? (
                  <b>
                    You get 50% off FOREVER if you sign up before Jan 31, 2021.
                  </b>
                ) : (
                  <div></div>
                )}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              // spacing={4}
              style={{ marginTop: 20 }}
            >
              {prices.map((data, index) => (
                <Grid container key={index}>
                  <Grid item xs={12}>
                    <Grid
                      alignItems="center"
                      container
                      justify="space-between"
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <Grid item sm={8} style={{ paddingLeft: 10 }} xs={12}>
                        <Typography
                          align="left"
                          display="inline"
                          // style={{ paddingLeft: 8 }}
                          variant="h6"
                        >
                          {`${data.plan}: `}
                        </Typography>
                        <Typography
                          align="left"
                          display="inline"
                          style={{
                            textDecoration: data.discountedPrice
                              ? "line-through"
                              : "none",
                          }}
                          variant="h6"
                        >
                          {`$${data.price}/mo`}
                        </Typography>
                        {data.discountedPrice ? (
                          <Typography
                            align="left"
                            display="inline"
                            variant="h6"
                          >
                            {` $${data.discountedPrice}/mo`}
                          </Typography>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                      <Grid item sm={4} style={{ paddingRight: 10 }} xs={12}>
                        <MobileOrTablet>
                          <Grid container justify="center">
                            <Button
                              onClick={() =>
                                this.handleGetStarted(data.priceId)
                              }
                              variant="contained"
                            >
                              {pricingRoute ? "Try for Free" : "Get Started"}
                            </Button>
                          </Grid>
                        </MobileOrTablet>
                        <Desktop>
                          <Grid container justify="flex-end">
                            <Button
                              onClick={() =>
                                this.handleGetStarted(data.priceId)
                              }
                              variant="contained"
                            >
                              {pricingRoute ? "Try for Free" : "Get Started"}
                            </Button>
                          </Grid>
                        </Desktop>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index === 0 ? (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
        <div>
          <StripeRedirectDialog open={this.state.showDialog} />
        </div>
      </Container>
    );
  }
}

ChoosePlan.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(ChoosePlan);
