export const content = {
  hero: {
    subtitle:
      "Use analytics from your deep work sessions to monitor how much time you're putting in, what you're accomplishing, and more.",
    title: "Get Better at Deep Work",
  },
  howItWorks: {
    addTask: {
      body:
        "Add tasks to your to do list. Go for tasks that require focus (studying, coding, etc.) over shallow tasks (emailing, basic meetings, etc.).",
      cta: "Start Your List",
      title: "Enter Tasks",
    },
    improveOverTime: {
      body:
        "Your session data will be added to your dashboard, enabling you to analyze trends. Our users report a 20% increase in daily deep work after just one month.",
      cta: "Discover Your Habits",
      title: "Use Your Analytics",
    },
    startDeepWork: {
      body:
        "Turn off your phone and shield yourself from any distractions. Begin working through your to do list while we record how long each task takes, how long you work for, and more.",
      cta: "Find Your Flow",
      title: "Start Deep Work",
    },
    stayMotivated: {
      body:
        "Your dashboard will also highlight information that will drive you to do more. We even email you a daily report so you can hold yourself accountable!",
      cta: "Gamify Your Productivity",
      title: "Stay Motivated",
    },
  },
};
