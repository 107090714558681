import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import isElectron from "is-electron";

import {
  electronGoogleAuth,
  signInWithGoogle,
  signInWithEmailAndPassword,
} from "../firebase/auth";
import { logAuthEvent } from "../analytics/index";

import { Desktop } from "../responsive";
import { getMuiPropTypes } from "../utils/utils";

import Loading from "../components/Loading";

const styles = () => ({
  buttonGoogle: {
    background: "#2D46B9",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
});

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      errorMessage: "",
      googleError: false,
      loading: false,
      password: "",
      passwordError: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.signInWithEmailAndPassword();
    }
  };

  signInWithEmailAndPassword = () => {
    logAuthEvent({ action: "Log In", label: "Email/Password" });
    this.setState(
      {
        loading: true,
      },
      async () => {
        const { email, password } = this.state;
        const {
          emailError,
          errorMessage,
          passwordError,
          userSignedIn,
        } = await signInWithEmailAndPassword(email, password);
        if (!userSignedIn) {
          this.setState({
            emailError,
            errorMessage,
            loading: false,
            passwordError,
            userSignedIn,
          });
        }
      }
    );
  };

  handleSignInWithGoogle = async () => {
    logAuthEvent({ action: "Log In", label: "Google" });
    if (isElectron()) {
      electronGoogleAuth();
      return;
    }
    const { errorMessage, googleError, userSignedIn } = await signInWithGoogle(
      this.state.redirectId
    );
    if (!userSignedIn) {
      this.setState({
        errorMessage,
        googleError,
        userSignedIn,
      });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Container maxWidth="md">
        <Grid
          alignItems="center"
          container
          justify="center"
          style={{ paddingTop: "10%" }}
        >
          <Grid item md={5} xs={12}>
            <Typography variant="h3">Winston</Typography>
            <br />
            <Typography variant="h6">Get better at deep work.</Typography>
          </Grid>
          <Desktop>
            <Grid item md={2}>
              <Grid container justify="center">
                <Divider style={{ height: 300 }} orientation="vertical" />
              </Grid>
            </Grid>
          </Desktop>
          <Grid item md={5} xs={12}>
            <TextField
              id="email"
              fullWidth
              label="Email"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.emailError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <TextField
              id="password"
              fullWidth
              label="Password"
              type="password"
              onChange={this.handleChange}
              onKeyPress={(e) => this.keyPress(e)}
            />
            {this.state.passwordError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              fullWidth
              onClick={this.signInWithEmailAndPassword}
            >
              Log In
            </Button>
            <Button onClick={() => this.props.history.push("/reset")}>
              {" "}
              Forgot Password?{" "}
            </Button>
            <Divider />
            <Button
              classes={{ root: classes.buttonGoogle }}
              style={{ marginTop: 20, marginBottom: 10 }}
              variant="contained"
              fullWidth
              onClick={this.handleSignInWithGoogle}
            >
              Log In with Google
            </Button>
            {this.state.googleError ? (
              <FormHelperText error={true}>
                {this.state.errorMessage}
              </FormHelperText>
            ) : (
              <div></div>
            )}
            <Button onClick={() => this.props.history.push("/signup")}>
              New User? Create an Account.
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

LogIn.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(LogIn));
