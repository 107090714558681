import React from "react";
import { withRouter } from "react-router-dom";
import isElectron from "is-electron";
import moment from "moment";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  // InputLabel,
  LinearProgress,
  Paper,
  Slider,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

import { logSessionEvent } from "../analytics/index";
import {
  getMarksForSlider,
  getMarksForDifficultySlider,
  getMuiPropTypes,
  getTimeElapsedStr,
  TIME_MAP,
  truncate,
} from "../utils/utils";
import MyWindowPortal from "../components/MyWindowPortal";

import boxingBell from "../sound-effects/boxing-bell.wav";
const audio = new Audio(boxingBell);

const marks = getMarksForSlider();
const marksForDifficultySlider = getMarksForDifficultySlider();

const styles = (theme) => ({
  addPadding: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  addMarginBottom: {
    marginBottom: 20,
  },
  addMarginTop: {
    marginTop: 20,
  },
  button: {
    color: "white",
    border: "1px solid white",
    margin: 8,
    textTransform: "none",
  },
  checkColor: {
    // color: theme.palette.customButtons.backgroundColor.default
    color: "white",
  },
  dialogPaper: theme.bigDialogPaper,
  infoIcon: theme.infoIcon.main,
  infoIconRoot: theme.infoIcon.root,
  mainIcons: {
    "& svg": {
      fontSize: 45,
    },
  },
  maxItemSize: {
    flexGrow: 1,
    maxWidth: 576.11,
  },
  popUpIcons: {
    "& svg": {
      fontSize: 20,
    },
  },
  popUpIconsBig: {
    "& svg": {
      fontSize: 25,
    },
  },
  progressBar: {
    backgroundColor: "white",
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
  },
});

class CurrentTask extends React.Component {
  constructor(props) {
    super(props);
    this.focusRef = React.createRef();
    this.state = {
      difficulty: 2,
      focus: null,
      errors: {
        difficulty: false,
        focus: false,
      },
      inactiveTime: 0,
      inactiveTimerRunning: false,
      notes: "",
      openInactiveSessionDialog: false,
      openTimerFinishedDialog: false,
      openTooltip: false,
      paused: false,
      playSound: false,
      popUp: null,
      session: {},
      sessionStarted: false,
      showPopUp: false,
      timeLeft: 10,
      timePassed: 0,
      timerRunning: false,
      taskStartTime: null,
    };
  }

  // componentDidMount() {
  //   window.addEventListener("message", function(e) {
  //     console.log("e.origin: ", e.origin);
  //     if (e.origin != 'http://test-domain-name:3000') {
  //       // something from an unknown domain, let's ignore it
  //       return;
  //     }
  //     // alert('hi there');
  //     window.focus();
  //   });
  // }

  assignWindow = (w) => {
    this.setState({
      popUp: w,
    });
  };

  /**
   * Format time in timer to display
   * minutes and seconds.
   */
  formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  };

  /**
   * Display fraction of time passed over
   * time remaining
   */
  formatTimeFraction = () => {
    const {
      currentTask: { time },
    } = this.props;
    /* If current task time exists show fraction otherwise show time passed */
    if (time) {
      return `${this.formatTimeLeft(
        this.state.timePassed
      )}/${this.formatTimeLeft(time)}`;
    }
    return `${this.formatTimeLeft(this.state.timePassed)}`;
  };

  /**
   * Called once a second to increase the time passed by
   * one second and reduce the time left by one second.
   *
   * Set inactive interval to count how long they're
   * inactive for.
   */
  countDown = () => {
    const { currentTask } = this.props;
    let { taskStartTime, timeLeft, timePassed, timerRunning } = this.state;
    if (!timerRunning) {
      /**
       * Time passed is the diff b/w now and when task
       * was started. If you start task at 10 am and
       * it's 10:01am, then the diff will be 60.
       * We store the diff in timePassed.
       *
       * When user pauses, we know how much time has passed.
       * When they restart, we want the difference between
       * now and the time they started to equal timePassed.
       * Therefore, we subtract timePassed from now.
       *
       * We subtract an additional second bc it took 1 second
       * for this function to be called.
       */
      taskStartTime = new Date(Date.now() - timePassed * 1000 - 1000);
      timerRunning = true;
    }

    timePassed = Math.round((new Date() - taskStartTime) / 1000);

    if (currentTask) {
      timeLeft = currentTask.time - timePassed;
    }

    this.setState({
      session: {
        ...this.state.session,
        endTime: new Date().toISOString(),
      },
      taskStartTime,
      timeLeft,
      timePassed,
      timerRunning,
    });

    /* Start counting inactive time */
    if (timeLeft === 0) {
      console.log("Starting to count inactive time...");
      const { id } = currentTask;
      // const timeInterval = isTestEnv() ? TIME_MAP.ONE_SECOND : TIME_MAP.ONE_MIN;
      this.inactiveTimerId = setInterval(
        () => this.updateInactiveTime(id),
        TIME_MAP.ONE_MIN
      );
      this.setState({
        inactiveTimerRunning: true,
        openTimerFinishedDialog: true,
        playSound: true,
      });
    }
  };

  /**
   * Play audio when timer hits 0.
   */
  playSound = () => {
    if (this.props.endBellEnabled) {
      audio.play();
    }
    this.setState({ playSound: false });
  };

  /**
   * Count inactive time so that we can end session
   * if needed.
   */
  updateInactiveTime = (currentTaskId) => {
    console.log("Updating inactive time...");
    /* End session if inactive for more than 90 mins */
    // const comparisonTime = isTestEnv() ? TIME_MAP.ONE_SECOND : TIME_MAP.ONE_HOUR_AND_A_HALF;
    if (this.state.inactiveTime >= TIME_MAP.ONE_HOUR_AND_A_HALF) {
      console.log(
        "Ending session, inactiveTime is: ",
        this.state.inactiveTime / (TIME_MAP.ONE_MIN * 60)
      );
      /* Save data for current task */
      // const { hrs, mins } = getHoursAndMinsFromTime(
      //   this.state.timePassed * 1000
      // );
      /* Update task and session in firebase */
      this.props.editCurrentTask({
        actualTime: this.state.timePassed,
        completed: false,
        date: new Date().toISOString(),
        // hrs,
        id: currentTaskId,
        // mins,
        notes: "",
        sessionId: this.props.sessionId,
      });
      /* Reset inactive time */
      this.resetInactiveTimer();
      /* Update state and adjust dialogs */
      this.setState({
        openInactiveSessionDialog: true,
        openTimerFinishedDialog: false,
      });
    } else {
      this.setState({
        inactiveTime: this.state.inactiveTime + TIME_MAP.ONE_MIN,
      });
    }
  };

  /**
   * If user takes action that shows
   * activity, reset the inactive timer
   */
  resetInactiveTimer = () => {
    console.log("Inactive interval has been reset...");
    clearInterval(this.inactiveTimerId);
    this.setState({
      inactiveTime: 0,
      inactiveTimerRunning: false,
    });
  };

  handleStartTimer = () => {
    this.resetInactiveTimer();
    const { sessionStarted, timerRunning } = this.state;
    /* Set session start time if session hasn't started yet */
    !sessionStarted && this.props.setSessionStartTime();
    if (!timerRunning) {
      this.setState({ sessionStarted: true });
      /* Start timer by setting interval */
      this.timerId = setInterval(() => this.countDown(), TIME_MAP.ONE_SECOND);
      /* Tell parent that task is in progress */
      this.props.updateActiveTask({
        activeTask: true,
      });
      /* Show pop up player */
      this.setState({ showPopUp: true });
    }
  };

  handlePauseTimer = () => {
    this.resetInactiveTimer();
    const { timerRunning } = this.state;
    if (timerRunning) {
      /* Pause timer by clearing interval */
      clearInterval(this.timerId);
      this.setState({
        timerRunning: false,
      });
    }
  };

  handleSave = (currentTaskId) => {
    const { focus, difficulty } = this.state;
    if (!focus || !difficulty) {
      this.setState({
        errors: {
          focus: !focus,
          difficulty: !difficulty,
        },
      });
      return;
    }
    const { sessionStarted } = this.state;
    /* Update task with this data, save it, and move to next task */
    // const { hrs, mins } = getHoursAndMinsFromTime(this.state.timePassed * 1000);
    /* Update task and session in firebase */
    this.props.editCurrentTask({
      actualTime: this.state.timePassed,
      completed: true,
      date: new Date().toISOString(),
      difficulty,
      focus,
      // hrs,
      id: currentTaskId,
      // mins,
      notes: this.state.notes,
      sessionId: this.props.sessionId,
    });
    /* Tell parent that task is no longer in progress */
    this.props.updateActiveTask({
      activeTask: false,
    });
    /* Close dialog + reset timer */
    clearInterval(this.timerId);
    this.setState({
      /* Reset everything as needed */
      difficulty: 2,
      focus: null,
      errors: {
        difficulty: false,
        focus: false,
      },
      notes: "",
      openTimerFinishedDialog: false,
      sessionStarted: true,
      timePassed: 0,
      timerRunning: false,
    });
    /* Set session start time if session hasn't started yet */
    !sessionStarted && this.props.setSessionStartTime();
    /* Reset inactive timer */
    this.resetInactiveTimer();
  };

  handleKeepGoing = () => {
    this.setState({ openTimerFinishedDialog: false });
    this.resetInactiveTimer();
  };

  handleTextFieldChange = (e) => {
    this.setState({ notes: e.currentTarget.value });
  };

  getTimerFinishedDialog = () => {
    const { classes, currentTaskId } = this.props;
    return (
      <Dialog
        /* https://stackoverflow.com/a/47763027 */
        classes={{ paper: classes.dialogPaper }}
        open={this.state.openTimerFinishedDialog}
        onClose={this.handleKeepGoing}
      >
        <DialogTitle>Data for Analytics</DialogTitle>
        <DialogContent>
          <div>
            {/* Focus Level Form */}
            <FormControl fullWidth>
              <Typography style={{ fontWeight: 600 }}>Focus Level</Typography>
              <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                <Slider
                  defaultValue={1}
                  marks={marks}
                  min={1}
                  max={10}
                  onChange={(e, value) => this.setState({ focus: value })}
                  step={1}
                />
              </div>
              <FormHelperText>
                How focused were you while you worked on this task?
              </FormHelperText>
              {this.state.errors.focus ? (
                <FormHelperText error>
                  Please choose a value so we can update your analytics.
                </FormHelperText>
              ) : (
                <div></div>
              )}
            </FormControl>
          </div>
          <div style={{ marginTop: 40 }}>
            {/* Difficulty Form */}
            <FormControl fullWidth>
              <Typography style={{ fontWeight: 600 }}>
                Task Difficulty
              </Typography>
              <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                <Slider
                  defaultValue={this.state.difficulty}
                  marks={marksForDifficultySlider}
                  min={2}
                  max={8}
                  onChange={(e, value) => this.setState({ difficulty: value })}
                  step={3}
                />
              </div>
              <FormHelperText>
                How much did this task push you intellectually?
              </FormHelperText>
              {this.state.errors.difficulty ? (
                <FormHelperText error>
                  Please choose a value so we can update your analytics.
                </FormHelperText>
              ) : (
                <div></div>
              )}
            </FormControl>
          </div>
          <div style={{ marginTop: 40 }}>
            <Typography style={{ fontWeight: 600 }}>Notes</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              // label="Notes"
              // margin="dense"
              multiline
              onChange={this.handleTextFieldChange}
              placeholder={
                "- Ran into issue x\n- Learned y\n- Need to ask about z"
              }
              rows={3}
              value={this.state.notes}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="timer"
            onClick={() => this.handleSave(currentTaskId)}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getCurrentTaskBox = () => {
    const { classes, currentTask } = this.props;
    return (
      <Paper elevation={0}>
        <Grid container justify="center">
          <Grid className={classes.addPadding} item xs={12}>
            {!currentTask.showTask ? (
              <div></div>
            ) : (
              <Typography align="center" variant="h5">
                {currentTask.value}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* <Divider /> */}
          </Grid>
          <Grid className={classes.addMarginTop} item xs={12}>
            {this.getTimerAndProgressBar()}
          </Grid>
          <Grid
            className={`${classes.addMarginBottom} ${classes.addMarginTop}`}
            item
            xs={12}
          >
            {/* <Divider /> */}
          </Grid>
          <Grid className={classes.addMarginBottom} item xs={12}>
            <Toolbar disableGutters variant="dense">
              <Grid container justify="center">
                {this.state.timerRunning ? (
                  <IconButton
                    className={classes.mainIcons}
                    disabled
                    onClick={this.handleStartTimer}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                ) : (
                  <Tooltip title={"Start Task"}>
                    <IconButton
                      className={classes.mainIcons}
                      onClick={this.handleStartTimer}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {this.state.timePassed === 0 ? (
                  <>
                    <IconButton
                      className={classes.mainIcons}
                      disabled
                      onClick={this.handlePauseTimer}
                    >
                      <PauseIcon />
                    </IconButton>
                    <IconButton
                      classes={{ root: classes.checkColor }}
                      className={classes.mainIcons}
                      disabled
                      onClick={() =>
                        this.setState({ openTimerFinishedDialog: true })
                      }
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Tooltip title={"Pause Task"}>
                      <IconButton
                        className={classes.mainIcons}
                        onClick={this.handlePauseTimer}
                      >
                        <PauseIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Finish Task"}>
                      <IconButton
                        classes={{ root: classes.checkColor }}
                        className={classes.mainIcons}
                        onClick={() => {
                          this.setState({
                            openTimerFinishedDialog: true,
                            showPopUp: false,
                          });
                        }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  getPopUp = () => {
    const { classes, currentTask } = this.props;
    /* Each line has a length of ~37 chars */
    // const multLines = currentTask.value.length > 30;
    return (
      /* Styling required: https://github.com/electron/electron/blob/master/docs/api/frameless-window.md */
      <div style={{ "-webkit-app-region": "drag" }}>
        <Grid
          alignItems="center"
          container
          style={{ padding: "10px 15px 0px 15px" }}
        >
          <Grid
            item
            style={{ paddingTop: 4, "-webkit-user-select": "none" }}
            xs={7}
          >
            <Typography variant="subtitle2">
              {truncate(currentTask.value, 54)}
            </Typography>
          </Grid>
          <Grid item style={{ "-webkit-app-region": "no-drag" }} xs={5}>
            <Grid alignItems="center" container justify="flex-end">
              <IconButton
                className={classes.popUpIconsBig}
                disabled={this.state.timerRunning}
                onClick={this.handleStartTimer}
              >
                <PlayArrowIcon />
              </IconButton>
              <IconButton
                className={classes.popUpIconsBig}
                disabled={!this.state.timerRunning}
                onClick={this.handlePauseTimer}
              >
                <PauseIcon />
              </IconButton>
              <IconButton
                className={classes.popUpIconsBig}
                disabled={this.state.timePassed === 0}
                onClick={() => {
                  this.setState({
                    openTimerFinishedDialog: true,
                    showPopUp: false,
                  });
                }}
              >
                <CheckCircleIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            {this.getProgressBar()}
          </Grid>
        </Grid>
      </div>
    );
  };

  getInactiveSessionEndedDialog = () => {
    const { sessionId } = this.props;
    return (
      <Dialog open={this.state.openInactiveSessionDialog}>
        <DialogTitle>Still Working?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              "You've been inactive for more than 1.5 hours, so we ended the session and saved your data in case you forgot to."
            }
            <br />
            <br />
            <b>
              {
                "If you're still working and want to continue your session, press continue."
              }
            </b>{" "}
            {"Otherwise, press end session."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="close"
            onClick={() => {
              /* Session data was already saved before this point */
              this.setState({ openInactiveSessionDialog: false });
              this.props.history.push(`/previous/${sessionId}`);
            }}
            style={{ backgroundColor: "#FF0600" }}
            variant="contained"
          >
            End Session
          </Button>
          <Button
            id="close"
            onClick={() => {
              /* Session simply continues */
              this.setState({ openInactiveSessionDialog: false });
            }}
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getTimerAndProgressBar = () => {
    return (
      <Grid container justify="center">
        <div style={{ marginBottom: 10 }}>
          <Typography variant="h5">{this.formatTimeFraction()}</Typography>
        </div>
        <Grid item xs={12}>
          {this.getProgressBar()}
        </Grid>
      </Grid>
    );
  };

  getProgressBar = () => {
    const {
      classes,
      currentTask: { time },
    } = this.props;
    const { timePassed } = this.state;
    /* Set progress bar value to fraction or max of 100 */
    const value = Math.min((timePassed / time) * 100, 100);
    return (
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={value}
      />
    );
  };

  handleOpen = () => {
    logSessionEvent({
      action: "Tooltip Displayed",
      label: "Current Task",
    });
    this.setState({ openTooltip: true });
  };

  handleClose = () => {
    this.setState({ openTooltip: false });
  };

  render() {
    const {
      session: { endTime },
    } = this.state;
    const { classes, currentTask, startTime, tasksCompleted } = this.props;

    /* Play sound once timer is up */
    this.state.playSound && this.playSound();

    /* Stats */
    const stats = [
      {
        header: "Start Time",
        val: startTime ? moment(startTime).format("h:mm a") : "",
      },
      {
        header: "Time Elapsed",
        val: getTimeElapsedStr({ endTime, startTime }),
      },
      {
        header: "Tasks Completed",
        val: tasksCompleted,
      },
    ];

    if (!currentTask || !currentTask.showTask) {
      return (
        <div>
          <Paper elevation={0}>
            <Grid container justify="center">
              <Grid className={classes.addPadding} item xs={12}>
                <Typography display="inline" variant="h5">
                  <i>Add tasks below...</i>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    }

    return (
      <div ref={this.focusRef} style={{ marginTop: 40, marginBottom: 20 }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid alignItems="center" container>
              <Typography align="left" style={{ fontWeight: 600 }} variant="h6">
                Current Task
              </Typography>
              <Tooltip
                arrow
                placement="right"
                title="When you're ready to start a deep work session, press the play button. Aim for 1+ hours of distraction-free time to help you get in the zone!"
              >
                <InfoIcon
                  classes={{ root: classes.infoIconRoot }}
                  style={{ marginLeft: 10 }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {this.getCurrentTaskBox()}
          </Grid>
          <Grid item xs={12}>
            {startTime ? <Divider /> : <div></div>}
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" style={{ padding: 20 }}>
              {stats.map((obj, index) => (
                <Grid item key={index} xs={4}>
                  <Collapse in={startTime}>
                    <Typography
                      align="center"
                      style={{ color: "#E5E5E5", fontWeight: 600 }}
                      variant="body1"
                    >
                      {obj.header}
                    </Typography>
                    <Typography variant="h6" align="center">
                      {obj.val}
                    </Typography>
                  </Collapse>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: 40 }} xs={12}>
            {startTime ? <Divider /> : <div></div>}
          </Grid>
          <div>
            {isElectron() && this.state.showPopUp && (
              <MyWindowPortal>{this.getPopUp()}</MyWindowPortal>
            )}
          </div>
          <div>{this.getTimerFinishedDialog()}</div>
          <div>{this.getInactiveSessionEndedDialog()}</div>
        </Grid>
      </div>
    );
  }
}

CurrentTask.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(CurrentTask));
