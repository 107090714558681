import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import HomeIcon from "@material-ui/icons/Home";
import HistoryIcon from "@material-ui/icons/History";
import QueueIcon from "@material-ui/icons/Queue";
import WorkIcon from "@material-ui/icons/Work";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupIcon from "@material-ui/icons/Group";

import { Desktop, Tablet } from "../responsive";

import ActiveTaskDialog from "../dialogs/ActiveTaskDialog";
import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  drawer: theme.sideBar.drawer,
  drawerSm: theme.sideBar.drawerSm,
  drawerContainer: theme.sideBar.drawerContainer,
  drawerPaper: theme.sideBar.drawerPaper,
  drawerPaperSm: theme.sideBar.drawerPaperSm,
  drawerToolBar: {
    height: 0,
  },
  expandButtonContainer: theme.sideBar.expandButtonContainer,
  expandButtonContainerSm: theme.sideBar.expandButtonContainerSm,
  startDeepWorkListItem: {
    backgroundColor: theme.palette.mainGreen.color,
    borderRadius: 25,
    fontWeight: 600,
  },
});

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSessionEndedDialog: false,
      requestedRoute: "",
    };
  }

  getBigListItem = (text) => {
    const { classes } = this.props;

    if (text === "Start Deep Work") {
      return (
        <Grid
          className={classes.startDeepWorkListItem}
          container
          justify="center"
        >
          <Typography align="center" style={{ fontWeight: 600, padding: 10 }}>
            {text}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <>
          <ListItemIcon>{this.getSideBarIcon(text)}</ListItemIcon>
          <Typography>{text}</Typography>
        </>
      );
    }
  };

  getSideBarArr = () => {
    return [
      {
        route: "/",
        text: "Home",
      },
      {
        route: "/partners",
        text: "Find Partners",
      },
      {
        route: "/previous",
        text: "Previous Sessions",
      },
      {
        route: "/settings",
        text: "Settings",
      },
      {
        route: "/start-session",
        text: "Start Deep Work",
      },
    ];
  };

  getSideBarIcon = (text) => {
    switch (text) {
      case "Home":
        return <HomeIcon />;
      case "Find Partners":
        return <GroupIcon />;
      case "Add Data Manually":
        return <AddToPhotosIcon />;
      case "Previous Sessions":
        return <HistoryIcon />;
      case "To Do List":
        return <QueueIcon />;
      case "Settings":
        return <SettingsIcon />;
      default:
        return <div></div>;
    }
  };

  getSmallListItem = (text) => {
    const { classes } = this.props;
    if (text === "Start Deep Work") {
      return (
        // <Tooltip title={text}>
        <Grid
          className={classes.startDeepWorkListItem}
          container
          justify="center"
        >
          <Grid
            container
            className={classes.startDeepWorkListItem}
            justify="center"
            style={{ padding: 10 }}
          >
            <WorkIcon />
          </Grid>
        </Grid>
        // </Tooltip>
      );
    } else {
      return (
        // <Tooltip title={text}>
        <div style={{ paddingLeft: "30%" }}>{this.getSideBarIcon(text)}</div>
        // </Tooltip>
      );
    }
  };

  getSmallWinston = () => {
    return (
      <Grid container justify="center">
        <Typography
          align="center"
          onClick={() => this.handleRoute("/")}
          style={{ cursor: "pointer", fontWeight: 600 }}
          variant="h6"
        >
          W
        </Typography>
      </Grid>
    );
  };

  handleRoute = (route) => {
    if (this.props.history.location.pathname === route) {
      return;
    }
    if (this.props.activeTask) {
      this.setState({
        openSessionEndedDialog: true,
        requestedRoute: route,
      });
      return;
    }
    this.props.history.push(route);
  };

  handleCloseDialog = ({ activeTask, redirect }) => {
    this.setState(
      {
        openSessionEndedDialog: false,
      },
      () => {
        this.props.updateActiveTask({
          activeTask,
          route: redirect ? this.state.requestedRoute : null,
        });
      }
    );
  };

  render() {
    const { classes, expanded } = this.props;

    return (
      <>
        <Tablet>
          <Drawer
            classes={{
              paper: classes.drawerPaperSm,
            }}
            className={classes.drawerSm}
            variant="permanent"
          >
            <Toolbar>{this.getSmallWinston()}</Toolbar>
            <div className={classes.drawerContainer}>
              <List>
                {this.getSideBarArr().map(({ route, text }) => (
                  <ListItem
                    button
                    key={text}
                    onClick={() => this.handleRoute(route)}
                  >
                    {this.getSmallListItem(text)}
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
        </Tablet>
        <Desktop>
          <Drawer
            classes={{
              paper: expanded ? classes.drawerPaper : classes.drawerPaperSm,
            }}
            className={expanded ? classes.drawer : classes.drawerSm}
            variant="permanent"
          >
            <Toolbar>
              {expanded ? (
                <Typography
                  align="left"
                  onClick={() => this.handleRoute("/")}
                  style={{ cursor: "pointer" }}
                  variant="h6"
                >
                  Winston
                </Typography>
              ) : (
                this.getSmallWinston()
              )}
            </Toolbar>
            <div className={classes.drawerContainer}>
              <List>
                {this.getSideBarArr().map(({ route, text }) => (
                  <Tooltip
                    key={text}
                    placement="right"
                    title={expanded ? "" : text}
                  >
                    <ListItem button onClick={() => this.handleRoute(route)}>
                      {expanded
                        ? this.getBigListItem(text)
                        : this.getSmallListItem(text)}
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </div>
            <Grid
              className={
                expanded
                  ? classes.expandButtonContainer
                  : classes.expandButtonContainerSm
              }
              container
              justify={expanded ? "flex-end" : "center"}
            >
              <IconButton onClick={this.props.toggleSideBar}>
                {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Grid>
          </Drawer>
        </Desktop>
        <div>
          <ActiveTaskDialog
            handleCloseDialog={this.handleCloseDialog}
            open={this.state.openSessionEndedDialog}
          />
        </div>
      </>
    );
  }
}

SideBar.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(SideBar));
