import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { fetchMediaURL } from "../firebase";
import { getMuiPropTypes } from "../utils/utils";

import Loading from "./Loading";
import TutorialVideo from "./TutorialVideo";

const styles = () => ({
  bodyText: {
    fontSize: "1.1rem",
  },
});

class Tutorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      startDeepWorkTutorial: "",
      urlFullTutorial: "",
    };
  }

  async componentDidMount() {
    this.fetchStartDeepWorkTutorial();
    const urlFullTutorial = await fetchMediaURL(
      "media/full-tutorial-final.mp4"
    );
    this.setState({
      loading: false,
      urlFullTutorial,
    });
  }

  fetchStartDeepWorkTutorial = async () => {
    const startDeepWorkTutorial = await fetchMediaURL(
      "media/dw-page-tutorial.mov"
    );
    this.setState({
      startDeepWorkTutorial,
    });
  };

  handleStartFreeTrial = () => {
    this.props.history.push("/signup");
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    const tutorials = [
      {
        header: "Overview",
        subtitle: "Get a high level overview of how to use Winston.",
        url: this.state.urlFullTutorial,
      },
      {
        header: "Deep Work Page",
        subtitle: "Learn the details of the deep work page.",
        url: this.state.startDeepWorkTutorial,
      },
    ];

    return (
      <>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            {tutorials.map((obj, index) => (
              <Grid item key={index} sm={6} xs={12}>
                <Typography
                  style={{ fontWeight: 600, paddingBottom: 10 }}
                  variant="h4"
                >
                  {obj.header}
                </Typography>
                <Typography
                  align="center"
                  className={classes.bodyText}
                  style={{ paddingBottom: 10 }}
                >
                  {obj.subtitle}
                </Typography>
                <TutorialVideo
                  analyticsLabel={"Tutorial Page"}
                  height={210}
                  url={obj.url}
                />
              </Grid>
            ))}
            {this.props.user ? (
              <></>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: 150, paddingBottom: 50 }}
                >
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: 30, paddingLeft: 80, paddingRight: 80 }}
                >
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    Haven't Signed Up Yet?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingBottom: 50, paddingTop: 15 }}
                >
                  <Button
                    variant="contained"
                    onClick={this.handleStartFreeTrial}
                  >
                    Start Free Trial
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </>
    );
  }
}

Tutorial.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(Tutorial));
