import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getMuiPropTypes } from "../utils/utils";

const styles = () => ({
  buttonGoogle: {
    background: "#2D46B9",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
});

class Contact extends React.Component {
  render() {
    return (
      <Container maxWidth="sm">
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography variant="h6">
              Please email us at{" "}
              <a href="mailto:arjun@trywinston.com" style={{ color: "white" }}>
                arjun@trywinston.com
              </a>{" "}
              for any comments, questions, feature requests, or feedback that
              you have.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

Contact.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(Contact));
