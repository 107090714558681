import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LabelIcon from "@material-ui/icons/LabelOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";

import {
  getMuiPropTypes,
  getTagsFromValue,
  getTaskTimeOptions,
} from "../utils/utils";
import { logSessionEvent } from "../analytics/index";

import ProjectNamesMenu from "../components/ProjectNamesMenu";

const timeOptions = getTaskTimeOptions();

const styles = (theme) => ({
  cardError: {
    backgroundColor: "#f44336",
  },
  categoryIcon: theme.categoryIcon.main,
  categoryIconRoot: theme.categoryIcon.root,
  clearButtonIcon: {
    fontSize: 16,
    height: 17,
    marginLeft: 3,
    paddingBottom: 1,
    width: 17,
  },
  dividerError: {
    backgroundColor: "#f44336",
  },
  errorText: {
    fontSize: 15,
  },
  grid: {
    backgroundColor: "#424242",
  },
  iconToolTipPlacement: {
    margin: 0,
  },
  mainIcons: {
    "& svg": {
      fontSize: 20,
    },
  },
  onyx: {
    backgroundColor: theme.palette.background.default,
  },
  padding: {
    paddingTop: 6,
    paddingBottom: 10,
  },
  paddedContainer: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    // paddingBottom: 10,
  },
  paper: {
    backgroundColor: "#2A2A2A",
    paddingTop: 10,
    paddingBottom: 10,
  },
  removeBlueOutline: {
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // border: "1px solid #484850",
      // borderRadius: "5px 5px 0 0"
      outline: "none",
    },
  },
  saveButton: {
    fontWeight: 600,
    margin: 0,
  },
  table: {
    backgroundColor: "#424242",
  },
  textField: {
    marginTop: 16,
    maxWidth: 26,
  },
});

class TaskCard extends React.Component {
  constructor(props) {
    super(props);
    /* https://reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-dom-element */
    this.difficultyInput = React.createRef();
    this.focusInput = React.createRef();
    this.notesInput = React.createRef();
    this.taskInput = React.createRef();
    /* Keep reference to input from parent */
    if (this.props.updateInputRefMap) {
      this.props.updateInputRefMap({
        id: this.props.taskId,
        difficultyInput: this.difficultyInput,
        focusInput: this.focusInput,
        notesInput: this.notesInput,
        taskInput: this.taskInput,
      });
    }
    this.addButtonRef = React.createRef();
    this.iconButtonRef = React.createRef();
    this.firstProjectName = React.createRef();
    this.state = {
      anchorElTimer: null,
      anchorElProjectName: null,
      btnClicked: false,
      collapse: false,
      enterPressed: false,
      errors: {
        projectName: false,
        value: false,
      },
      newProjectName: "",
      openTooltip: false,
      showEditableDialog: false,
    };
  }

  /**
   * Log tooltip open
   */
  handleOpen = () => {
    logSessionEvent({
      action: "Tooltip Displayed",
      label: "To Do List",
    });
    this.setState({ openTooltip: true });
  };

  /**
   * Close tooltip
   */
  handleClose = () => {
    this.setState({ openTooltip: false });
  };

  handleMenuClose = (id) => {
    this.setState({ [id]: null });
  };

  handleMenuOpen = ({ e, id }) => {
    this.setState({
      [id]: e.currentTarget,
      btnClicked: true,
    });
  };

  /**
   * Close the menu by setting it to null in state.
   */
  closeProjectNameMenu = () => {
    this.setState({
      anchorElProjectName: null,
    });
  };

  /**
   * Open the menu by setting it to the current target.
   */
  openProjectNameMenu = (e) => {
    this.setState({
      anchorElProjectName: e.currentTarget,
      btnClicked: true,
    });
  };

  /**
   * This function is called by ProjectNamesMenu when
   * a name is selected. It uses this name to edit
   * the task.
   */
  projectNameSelected = (selectedProjectName) => {
    const { taskId } = this.props;
    this.props.editTasks({
      id: taskId,
      projectName: selectedProjectName,
    });
  };

  /**
   * Called when new project is created from ProjectNamesMenu
   */
  handleCreateNewProject = (newProjectName) => {
    const { projectNames, taskId } = this.props;
    if (newProjectName && !projectNames.includes(newProjectName)) {
      this.props.addNewProjectName(newProjectName);
    }
    this.props.editTasks({
      id: taskId,
      projectName: newProjectName,
    });
  };

  handleTimerClose = ({ id, time }) => {
    if (!time) return;
    this.setState(
      {
        anchorElTimer: null,
      },
      () =>
        this.props.editTasks({
          id,
          /* Update actualTime if it's a previous session */
          [this.props.isPreviousSession ? "actualTime" : "time"]: time,
        })
    );
  };

  errorExists = () => {
    const errors = {};
    let errorExists = false;
    /* Make sure there is task value */
    if (this.taskInput.current.value === "") {
      errors.value = true;
      errorExists = true;
    }
    /* Make sure there is a project value */
    if (!this.props.projectValue) {
      errors.projectName = true;
      errorExists = true;
    }
    this.setState({
      errors: { ...errors },
    });
    return errorExists;
  };

  addTask = (id) => {
    if (this.errorExists()) {
      return;
    }
    this.props.editTasks({
      id,
      showTask: true,
      tags: [...getTagsFromValue(this.taskInput.current.value)],
      value: this.taskInput.current.value,
    });
    /* Generate new ID for next task */
    this.props.setNewTaskId();
    /* Clear input */
    this.taskInput.current.value = "";
    /* Focus text input */
    this.focusTextInput();
    this.setState({
      errors: {
        projectName: false,
        value: false,
      },
    });
  };

  /**
   * Focus on next textfield once user presses enter
   */
  focusTextInput = () => {
    this.taskInput.current.focus();
  };

  dialogClosed = () => {
    this.setState({ showEditableDialog: false });
  };

  renderEditableDialog = () => {
    return (
      <>
        <Dialog
          open={this.state.showEditableDialog}
          onClose={this.dialogClosed}
        >
          <DialogTitle>Attempted Edit</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to edit this task?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus id="close" onClick={this.dialogClosed}>
              Cancel
            </Button>
            <Button
              autoFocus
              id="close"
              onClick={() => {
                this.props.setEditPreviousTasks(true);
                this.dialogClosed();
              }}
              variant="contained"
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  renderMainComponent = () => {
    const {
      classes,
      completed = false,
      difficulty,
      editable = true,
      focus,
      isPreviousSession = false,
      isRootTask = false,
      notes = "",
      projectNames = [],
      projectValue,
      taskId,
      textFieldValue,
      timeLabelText,
    } = this.props;

    const showErrors = Object.keys(this.state.errors).some(
      (key) => this.state.errors[key]
    );
    let errorText = "";
    if (this.state.errors.projectName && this.state.errors.value) {
      errorText = "Please add a task description and a project name.";
    } else if (this.state.errors.projectName) {
      errorText =
        "Please use the category icon to add a project name (for analytics).";
    } else if (this.state.errors.value) {
      errorText = "Please add a task description.";
    }

    const showDifficultyError =
      this.props.errorsDifficulty && this.props.errorsDifficulty.id === taskId;
    const showFocusError =
      this.props.errorsFocus && this.props.errorsFocus.id === taskId;

    return (
      <Grid className={classes.grid} container>
        <Grid item xs={12}>
          <Card
            classes={{ root: `${showErrors ? classes.cardError : ""}` }}
            style={{ minHeight: 50 }}
            variant="outlined"
          >
            <Grid
              alignItems="flex-start"
              className={`${classes.onyx} ${classes.paddedContainer}`}
              container
            >
              <Grid
                className={classes.onyx}
                item
                style={{ paddingBottom: completed ? 16 : 0 }}
                xs={12}
              >
                <div style={{ width: "100%" }}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <TextField
                        autoFocus={isRootTask}
                        className={classes.onyx}
                        defaultValue={textFieldValue}
                        disabled={completed}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          readOnly: !editable,
                        }}
                        inputRef={this.taskInput}
                        multiline
                        onBlur={() => {
                          if (!editable) return;
                          /* Update existing tasks on blur */
                          if (!isRootTask && !this.errorExists()) {
                            this.props.editTasks({
                              id: taskId,
                              tags: [
                                ...getTagsFromValue(
                                  this.taskInput.current.value
                                ),
                              ],
                              value: this.taskInput.current.value,
                            });
                          }
                        }}
                        onClick={() => {
                          if (!editable) {
                            this.setState({ showEditableDialog: true });
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!editable) return;
                          if (e.key === "Enter") {
                            e.preventDefault();
                            this.addTask(taskId);
                          }
                        }}
                        placeholder="Add task description..."
                      />
                    </Box>
                    <Box style={{ paddingLeft: 9, paddingTop: 3 }}>
                      <div
                        style={{
                          backgroundColor: "grey",
                          borderRadius: 4,
                          // boxSizing: "border-box",
                        }}
                      >
                        <Typography
                          display="inline"
                          // hidden={textFieldValue}
                          style={{ padding: 3 }}
                        >
                          {timeLabelText}
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                </div>
              </Grid>
              {isPreviousSession ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.onyx}
                      defaultValue={notes}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        readOnly: !editable,
                      }}
                      inputRef={this.notesInput}
                      multiline
                      onBlur={() => {
                        if (!editable) return;
                        /* Update on blur */
                        this.props.editTasks({
                          id: taskId,
                          notes: this.notesInput.current.value,
                        });
                      }}
                      onClick={() => {
                        if (!editable) {
                          this.setState({ showEditableDialog: true });
                        }
                      }}
                      placeholder="No notes added..."
                      style={{ fontStyle: "italic" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <TextField
                        className={`${classes.onyx} ${classes.textField}`}
                        defaultValue={focus}
                        error={showFocusError}
                        inputProps={{ style: { textAlign: "center" } }}
                        InputProps={{
                          readOnly: !editable,
                        }}
                        inputRef={this.focusInput}
                        label="Focus"
                        onBlur={() => {
                          if (!editable) return;
                          /* Update on blur */
                          this.props.editTasks({
                            id: taskId,
                            focus: this.focusInput.current.value,
                          });
                        }}
                        onClick={() => {
                          if (!editable) {
                            this.setState({ showEditableDialog: true });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText error={showFocusError}>
                      {showFocusError ? this.props.errorsFocus.message : ""}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <TextField
                        className={`${classes.onyx} ${classes.textField}`}
                        defaultValue={difficulty}
                        error={showDifficultyError}
                        inputProps={{ style: { textAlign: "center" } }}
                        InputProps={{
                          readOnly: !editable,
                        }}
                        inputRef={this.difficultyInput}
                        label="Difficulty"
                        onBlur={() => {
                          if (!editable) return;
                          /* Update on blur */
                          this.props.editTasks({
                            id: taskId,
                            difficulty: this.difficultyInput.current.value,
                          });
                        }}
                        onClick={() => {
                          if (!editable) {
                            this.setState({ showEditableDialog: true });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText error={showDifficultyError}>
                      {showDifficultyError
                        ? this.props.errorsDifficulty.message
                        : ""}
                    </FormHelperText>
                  </Grid>
                </>
              ) : (
                <div></div>
              )}
              {projectValue && !completed ? (
                <Grid
                  className={classes.onyx}
                  container
                  justify="flex-start"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <Button
                    size="small"
                    style={{
                      borderRadius: 25,
                      marginLeft: -6,
                      paddingRight: 3,
                    }}
                    variant="outlined"
                  >
                    {projectValue}
                    <ClearIcon
                      classes={{ root: classes.clearButtonIcon }}
                      onClick={() => {
                        if (!editable) {
                          this.setState({ showEditableDialog: true });
                          return;
                        }
                        this.props.handleDeleteProjectLabel(taskId);
                      }}
                    />
                  </Button>
                </Grid>
              ) : (
                <div></div>
              )}
              <Grid
                className={classes.onyx}
                item
                style={{ paddingTop: projectValue ? 0 : 16 }}
                xs={12}
              >
                {/* <Collapse in={this.state.collapse && !completed}> */}
                <Divider
                  classes={{
                    root: `${showErrors ? classes.dividerError : ""}`,
                  }}
                />
                {/* </Collapse> */}
              </Grid>
              {completed ? (
                <div></div>
              ) : (
                <Grid className={classes.onyx} item xs={12}>
                  {/* <Collapse in={textFieldValue !== "" && !completed}> */}
                  <Grid
                    alignItems="center"
                    className={classes.onyx}
                    container
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Grid
                      className={classes.onyx}
                      item
                      xs={isRootTask ? 6 : 12}
                    >
                      <Grid className={classes.onyx} container>
                        <Tooltip
                          classes={{
                            tooltipPlacementBottom:
                              classes.iconToolTipPlacement,
                          }}
                          placement="bottom"
                          title={
                            isPreviousSession
                              ? "Edit task time"
                              : "Set task timer"
                          }
                        >
                          <IconButton
                            className={classes.mainIcons}
                            onClick={(e) => {
                              if (!editable) {
                                this.setState({ showEditableDialog: true });
                                return;
                              }
                              this.handleMenuOpen({
                                e,
                                id: "anchorElTimer",
                              });
                            }}
                            style={{
                              marginLeft: -12,
                              marginRight: 20,
                            }}
                          >
                            <TimerIcon />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={this.state.anchorElTimer}
                          keepMounted
                          open={Boolean(this.state.anchorElTimer)}
                          onClose={() => this.handleMenuClose("anchorElTimer")}
                        >
                          {timeOptions.map(({ display, time }, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  this.handleTimerClose({
                                    id: taskId,
                                    time,
                                  })
                                }
                              >
                                {display}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                        {/* PROJECT NAMES BUTTON + MENU */}
                        <Tooltip
                          classes={{
                            tooltipPlacementBottom:
                              classes.iconToolTipPlacement,
                          }}
                          placement="bottom"
                          title={
                            isPreviousSession
                              ? "Edit project name"
                              : "Set project name"
                          }
                        >
                          <IconButton
                            className={classes.mainIcons}
                            onClick={(e) => {
                              if (!editable) {
                                this.setState({ showEditableDialog: true });
                                return;
                              }
                              this.openProjectNameMenu(e);
                            }}
                            ref={this.iconButtonRef}
                            style={{
                              marginLeft: -12,
                              marginRight: 20,
                            }}
                          >
                            <LabelIcon />
                          </IconButton>
                        </Tooltip>
                        <ProjectNamesMenu
                          anchorElProjectName={this.state.anchorElProjectName}
                          handleCloseMenu={this.closeProjectNameMenu}
                          handleCreateNewProject={this.handleCreateNewProject}
                          projectNames={projectNames}
                          projectNameSelected={this.projectNameSelected}
                        />
                        {isRootTask ? (
                          <div></div>
                        ) : (
                          <Tooltip
                            classes={{
                              tooltipPlacementBottom:
                                classes.iconToolTipPlacement,
                            }}
                            placement="bottom"
                            title="Delete task"
                          >
                            <IconButton
                              className={classes.mainIcons}
                              onClick={() => {
                                if (!editable) {
                                  this.setState({ showEditableDialog: true });
                                  return;
                                }
                                this.props.deleteTask(taskId);
                              }}
                              style={{
                                marginLeft: -12,
                                marginRight: 20,
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                    {isRootTask ? (
                      <Grid item xs={6}>
                        <Grid
                          className={classes.onyx}
                          container
                          justify="flex-end"
                        >
                          <Button
                            classes={{ root: classes.saveButton }}
                            ref={this.addButtonRef}
                            onClick={() => this.addTask(taskId)}
                            size="small"
                            variant="contained"
                          >
                            Add Task
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  {/* </Collapse> */}
                </Grid>
              )}
            </Grid>
          </Card>
          {showErrors ? (
            <FormHelperText classes={{ root: classes.errorText }} error={true}>
              {errorText}
            </FormHelperText>
          ) : (
            <div></div>
          )}
          {this.renderEditableDialog()}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { btnClicked } = this.state;
    const {
      enableDragging = false,
      draggableIndex,
      showTaskCardTooltip = false,
      taskId,
    } = this.props;

    if (!enableDragging) {
      return (
        <div style={{ marginBottom: 20 }}>
          <Tooltip
            arrow
            open={showTaskCardTooltip && !btnClicked}
            title="Add tasks to get started. Once they're entered, you can re-order your list by dragging and dropping them."
          >
            {this.renderMainComponent()}
          </Tooltip>
        </div>
      );
    }

    return (
      <>
        <Draggable draggableId={taskId} index={draggableIndex}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div style={{ marginBottom: 20 }}>
                {this.renderMainComponent()}
              </div>
            </div>
          )}
        </Draggable>
      </>
    );
  }
}

TaskCard.propTypes = {
  ...getMuiPropTypes(),
};

export default withStyles(styles)(TaskCard);
