import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#E9EFF6",
    },
    error: {
      main: "#DB000C",
    },
    warning: {
      main: "#087FFF",
      dark: "#464299",
    },
    info: {
      main: "#087FFF",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#262626",
        textAlign: "center",
        "@media (min-width:960px)": {
          textAlign: "left",
        },
      },
      h2: {
        marginBottom: "10px",
        fontWeight: "400",
        fontSize: "1.9rem",
        "@media (min-width:960px)": {
          textAlign: "left",
        },
      },
      h4: {
        marginBottom: "10px",
        fontWeight: "400",
        fontSize: "1.9rem",
        "@media (min-width:960px)": {
          fontSize: "2rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "2rem",
        },
      },
      h6: {},
    },
  },
});

const DEFAULT_BG = "#2A2A2A";
const MAIN_GREEN = "#1EB980";
export const drawerWidthMd = 240;
export const drawerWidthSm = 100;
const CONTENT_PADDING = 40;

// https://material.io/design/color/dark-theme.html#usage
// https://material-ui.com/customization/palette/#:~:text=Dark%20mode,it%20modifies%20several%20palette%20values.
export const darkTheme = createMuiTheme({
  DEFAULTS: {
    CONTENT_PADDING,
  },
  appBarWithSideBar: {
    marginLeft: drawerWidthSm,
    width: `calc(100% - ${drawerWidthSm}px)`,
    "@media (min-width: 960px)": {
      marginLeft: drawerWidthMd,
      width: `calc(100% - ${drawerWidthMd}px)`,
    },
  },
  /* https://stackoverflow.com/a/47763027 */
  bigDialogPaper: {
    minHeight: "25%",
    width: "80%",
    "@media (min-width: 960px)": {
      width: "60%",
    },
  },
  content: {
    flexGrow: 1,
    paddingLeft: drawerWidthSm + CONTENT_PADDING,
    "@media (min-width: 960px)": {
      paddingLeft: drawerWidthMd + CONTENT_PADDING,
    },
    paddingRight: CONTENT_PADDING,
    paddingTop: 30 + 64, // app bar has height of 64
  },
  dashboardLabels: {
    h4: {
      fontSize: "1.75rem",
      fontWeight: 600,
      marginBottom: 20,
    },
    h6: {
      marginBottom: 10,
    },
  },
  sideBar: {
    drawer: {
      flexShrink: 0,
      width: drawerWidthSm,
      "@media (min-width: 960px)": {
        width: drawerWidthMd,
      },
    },
    drawerSm: {
      flexShrink: 0,
      width: drawerWidthSm,
    },
    drawerContainer: {
      overflow: "auto",
    },
    drawerPaper: {
      backgroundColor: DEFAULT_BG,
      width: drawerWidthSm,
      "@media (min-width: 960px)": {
        width: drawerWidthMd,
      },
    },
    drawerPaperSm: {
      backgroundColor: DEFAULT_BG,
      width: drawerWidthSm,
    },
    expandButtonContainer: {
      bottom: 0,
      paddingBottom: 10,
      // paddingRight: 10,
      position: "fixed",
      width: drawerWidthMd - 10,
    },
    expandButtonContainerSm: {
      bottom: 0,
      paddingBottom: 10,
      // paddingRight: 10,
      position: "fixed",
      width: drawerWidthSm - 10,
    },
  },
  palette: {
    background: {
      default: DEFAULT_BG,
    },
    mainGreen: {
      color: MAIN_GREEN,
    },
    type: "dark",
    customButtons: {
      backgroundColor: {
        default: MAIN_GREEN,
      },
      color: {
        default: "#FFF",
      },
      fontSize: {
        default: "15px",
      },
      fontWeight: {
        default: "600",
      },
    },
    customFonts: {
      fontWeight: {
        medium: "500",
        thick: "600",
      },
    },
  },
  infoIcon: {
    main: {
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 3,
      marginTop: 7,
    },
    root: {
      fontSize: "1rem",
    },
  },
  timerIcon: {
    main: {
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 7,
      marginTop: 6,
    },
    root: {
      fontSize: "1rem",
    },
  },
  categoryIcon: {
    main: {
      cursor: "pointer",
      // marginLeft: 6,
      marginRight: 7,
      marginTop: 5,
    },
    root: {
      fontSize: "1rem",
    },
  },
  // https://stackoverflow.com/questions/50436542/how-to-override-styles-for-material-ui-textfield-component-without-using-the-mui
  // https://stackoverflow.com/questions/48489252/material-ui-next-dialog-textfield-underline-color
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "500",
      },
      contained: {
        backgroundColor: MAIN_GREEN,
        color: "#FFF",
        margin: 8,
      },
    },
    MuiCircularProgress: {
      circle: {
        color: "#FFF",
      },
    },
    MuiFab: {
      root: {
        backgroundColor: "#FF0600",
        color: "#FFF",
        fontWeight: "600",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#FF0600",
        },
      },
    },
    MuiGrid: {
      root: {
        backgroundColor: "#2A2A2A",
      },
    },
    MuiInput: {
      root: {
        color: "white",
      },
      underline: {
        // when textfield is inactive
        "&:before": {
          // borderBottom: '0px solid white'
        },
        "&:after": {
          borderBottom: "2px solid white",
        },
        // when hovered
        "&:hover:not($disabled):before": {
          // borderBottom: '0px solid white'
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    // https://github.com/mui-org/material-ui/issues/11244
    MuiInputLabel: {
      root: {
        // Name of the rule
        color: "white",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "white",
        },
      },
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: MAIN_GREEN,
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 5,
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "white !important",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#424242",
      },
    },
    MuiSlider: {
      root: {
        color: MAIN_GREEN,
        // paddingLeft: 10,
        // paddingTop: 13
      },
      thumb: {
        /* .MuiSlider-thumb.MuiSlider-active */
        "&$active": {
          boxShadow: "0px 0px 0px 3px rgba(30, 185, 128, 1)",
        },
        "&$focusVisible": {
          boxShadow: "0px 0px 0px 3px rgba(30, 185, 128, 1)",
        },
        "&:hover": {
          boxShadow: "0px 0px 0px 3px rgba(30, 185, 128, 1)",
        },
      },
    },
    MuiTextField: {
      root: {
        // same as theme.palette.background.default
        backgroundColor: "#2A2A2A",
      },
    },
    MuiTooltip: {
      arrow: {
        color: "#424242",
      },
      tooltip: {
        backgroundColor: "#424242",
        fontSize: 14,
        fontWeight: 600,
      },
    },
    MuiTypography: {
      root: {
        color: "white",
      },
    },
  },
});
