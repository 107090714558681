import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

import { fetchPreviousSessions } from "../../firebase";
import { getTimeElapsedStr } from "../../utils/utils";

import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import SubRequiredDialog from "../../components/SubRequiredDialog";

const styles = (theme) => ({
  dashboardLabels: theme.dashboardLabels.h4,
  launchIcon: {
    padding: 0,
    marginLeft: "4px",
    position: "relative",
    bottom: "-2px",
    fontSize: "14px",
    color: "#fff",
  },
});

const headCells = [
  { id: "date", numeric: false, label: "Date" },
  { id: "projects", numeric: false, label: "Projects" },
  { id: "lengthOfTime", numeric: true, label: "Length of Time" },
  { id: "tasks", numeric: true, label: "Tasks" },
];

class PreviousSessionParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      previousSessions: [],
      // reset: this.props.location.search.includes("reset")
    };
  }

  async componentDidMount() {
    const previousSessions = await fetchPreviousSessions();
    this.setState({
      loading: false,
      previousSessions,
    });
  }

  getTableRows = () => {
    const { classes } = this.props;
    return this.state.previousSessions.map((session, index) => {
      const {
        endTime = null,
        /* Query both for backwards compatibility */
        projects = [],
        projectName = "",
        sessionId,
        startTime,
        taskIds,
      } = session;
      const timeElapsedStr = getTimeElapsedStr({
        startTime,
        endTime,
      });
      const projectValue = projects.length
        ? projects.join(", ")
        : projectName || "N/A";
      return (
        <TableRow
          hover
          onClick={() => this.handleClick(sessionId)}
          tabIndex={-1}
          key={index}
        >
          <TableCell component="th" scope="row" padding="default">
            {moment(startTime).format("ddd, MMM Do YYYY, h:mm a")}
            <LaunchIcon className={classes.launchIcon} />
          </TableCell>
          <TableCell align="left">{projectValue}</TableCell>
          <TableCell align="right">{timeElapsedStr}</TableCell>
          <TableCell align="right">{taskIds.length || 0}</TableCell>
        </TableRow>
      );
    });
  };

  handleClick = (sessionId) => {
    this.props.history.push(`/previous/${sessionId}`);
  };

  render() {
    const { classes, user } = this.props;
    const { loading, previousSessions } = this.state;

    if (loading) {
      return <Loading user={user} />;
    }

    if (!previousSessions || !previousSessions.length) {
      return (
        <>
          <NoData label={"No Previous Sessions Found"} user={user} />
          <div>
            <SubRequiredDialog />
          </div>
        </>
      );
    }

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              style={{ marginBottom: 10 }}
              variant="h4"
            >
              All Sessions
            </Typography>
            <DataTable
              addPagination={true}
              headCells={headCells}
              tableRows={this.getTableRows()}
            />
          </Grid>
        </Grid>
        <div>
          <SubRequiredDialog />
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(PreviousSessionParent));
