import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, TextField } from "@material-ui/core";

class ProjectNamesMenu extends React.Component {
  constructor(props) {
    super(props);
    this.addProjectNameRef = React.createRef();
    this.state = {
      newProjectName: "",
    };
  }

  /**
   * This function is called whenever parent sends new props.
   * If there are no project names and the menu is open, we focus
   * on the textfield so user can enter new project name.
   *
   * Notes: https://www.pluralsight.com/guides/prop-changes-in-react-component
   */
  componentDidUpdate() {
    const { anchorElProjectName, projectNames } = this.props;
    if (anchorElProjectName && !projectNames.length) {
      this.addProjectNameRef.current.focus();
    }
  }

  /**
   * Sends new project name to parent.
   * Reset newProjectName in state.
   */
  createNewProject = () => {
    /* Tell parent that new project name was added */
    this.props.handleCreateNewProject(this.state.newProjectName);
    /* Tell parent to close the menu */
    this.props.handleCloseMenu();
    /* Reset newProjectName */
    this.setState({
      newProjectName: "",
    });
  };

  /**
   * Handle when user types in a new project name
   */
  handleAddProjectNameTextChange = (e) => {
    const { value } = e.currentTarget;
    if (value === "\n") return;
    this.setState({
      newProjectName: value,
    });
  };

  /**
   * Called when project name is selected from menu.
   * Closes menu, resets newProjectName, and calls
   * parent function that handles selectedProjectName.
   */
  handleProjectNameSelected = (selectedProjectName) => {
    this.props.handleCloseMenu();
    this.setState(
      {
        newProjectName: "",
      },
      () => this.props.projectNameSelected(selectedProjectName)
    );
  };

  /**
   * These two keys will move focus to textfield in menu
   */
  moveFocusToInput = (e) => {
    if (e.key === "Tab" || e.key === "ArrowRight") {
      e.stopPropagation();
      e.preventDefault();
      this.addProjectNameRef.current.focus();
    }
  };

  /**
   * Enables textfield to work correctly in Menu
   */
  stopPropagation = (e) => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      case "Enter":
        e.stopPropagation();
        e.preventDefault();
        /* Save new project name */
        this.createNewProject();
        break;
      default:
        e.stopPropagation();
    }
  };

  render() {
    const { anchorElProjectName, projectNames } = this.props;

    return (
      <Menu
        anchorEl={anchorElProjectName}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElProjectName)}
        onClose={this.props.handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          button={false}
          key={0}
          onKeyDown={this.moveFocusToInput}
          style={{ outline: "none" }}
        >
          {/* https://stackoverflow.com/questions/56995208/textfield-in-menuitem-onkeydown-event-interfering-with-onchange-in-textfield/57402115#57402115 */}
          <TextField
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            inputRef={this.addProjectNameRef}
            onChange={this.handleAddProjectNameTextChange}
            onKeyDown={this.stopPropagation}
            placeholder="Enter project name"
            style={{ backgroundColor: "#424242" }}
            value={this.state.newProjectName}
          />
          <Button
            onClick={this.createNewProject}
            size="small"
            variant="outlined"
          >
            Create
          </Button>
        </MenuItem>
        {projectNames
          .sort()
          .filter((name) => name)
          .map((projectName, index) => {
            return (
              <MenuItem
                key={index + 1}
                onClick={() => this.handleProjectNameSelected(projectName)}
                ref={index === 0 ? this.firstProjectName : null}
              >
                {projectName}
              </MenuItem>
            );
          })}
      </Menu>
    );
  }
}

ProjectNamesMenu.propTypes = {
  anchorElProjectName: PropTypes.element,
  handleCloseMenu: PropTypes.func,
  handleCreateNewProject: PropTypes.func,
  projectNameSelected: PropTypes.func,
  projectNames: PropTypes.array,
};

export default ProjectNamesMenu;
