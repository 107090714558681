import React from "react";
import { withStyles } from "@material-ui/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
});

class NoData extends React.Component {
  render() {
    const { classes, label } = this.props;
    return (
      <>
        <div className={classes.root}>
          <Container maxWidth="md" style={{ paddingTop: 20 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  {label}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

NoData.propTypes = {
  ...getMuiPropTypes(),
};

export default withStyles(styles)(NoData);
