import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import NoData from "../../components/NoData";
import Loading from "../../components/Loading";
import TaskCard from "../../task-card/TaskCard";
import AddTaskDialog from "../../dialogs/AddTaskDialog";
import SubRequiredDialog from "../../components/SubRequiredDialog";
import PreviousSessionDataTable from "./PreviousSessionDataTable";

import {
  deleteSession,
  deleteTask as deleteTaskFirebase,
  fetchPreviousSessionById,
  fetchProjectNames,
  updateFirebaseSession,
  updateProjectNames,
  updateTasks,
} from "../../firebase";
import { logPreviousSessionEvent } from "../../analytics/index";
import {
  addNewProjectName,
  deleteProjectLabel,
  editTasks,
  getTimeLabelText,
} from "../../task-card/index";

const styles = (theme) => ({
  dashboardLabels: theme.dashboardLabels.h6,
  infoIcon: theme.infoIcon.main,
  infoIconRoot: theme.infoIcon.root,
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: "20px 20px 0px 20px",
  },
});

class PreviousSessionPage extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefMap = {};
    this.state = {
      editPreviousTasks: false,
      errorsDifficulty: null,
      errorsEndDate: "",
      errorsFocus: null,
      errorsStartDate: "",
      loading: true,
      openWarningDialog: false,
      openTooltip: false,
      projectNames: [],
      sessionBeforeEdits: null,
      sessionId: this.props.location.pathname.substring(10),
      sessionWithEdits: null,
      showAddTaskDialog: false,
      tasksToDelete: [],
      tasksDeleted: [],
    };
  }

  /**
   * This function sets the state with the data that
   * Firebase retrieved.
   *
   * @param {object} sessionBeforeEdits - sessionData that
   * we can use to restore if user cancels edits
   * @param {object} sessionWithEdits - sessionData that
   * changes as user edits
   */
  async componentDidMount() {
    const projectNames = await fetchProjectNames();
    const session = await fetchPreviousSessionById(this.state.sessionId);
    this.setState({
      projectNames,
      sessionBeforeEdits: { ...session },
      sessionWithEdits: { ...session },
      loading: false,
    });
  }

  /**
   * Allows parent to keep track of input refs
   * of children.
   */
  updateInputRefMap = ({
    id,
    difficultyInput,
    focusInput,
    notesInput,
    taskInput,
  }) => {
    this.inputRefMap[id] = {
      difficultyInput,
      focusInput,
      notesInput,
      taskInput,
    };
  };

  /**
   * Saves new project name to firebase and
   */
  addNewProjectName = async (projectName) => {
    const projectNames = addNewProjectName({
      projectName,
      projectNames: this.state.projectNames,
    });
    /* Save to firebase */
    await updateProjectNames(projectNames);
    /* Save to state */
    this.setState({
      sessionWithEdits: { ...this.state.sessionWithEdits, projectNames },
    });
  };

  /**
   * Delete task from state and firebase
   */
  deleteTask = (taskId) => {
    /* Add to state's delete queue */
    const tasksToDelete = [...this.state.tasksToDelete];
    tasksToDelete.push(taskId);
    /* Set state */
    this.setState({ tasksToDelete });
  };

  /**
   * Update tasks stored in state
   */
  editTasks = (props) => {
    const { tasks, taskIds } = editTasks({
      props,
      tasks: this.state.sessionWithEdits.tasks,
      taskIds: this.state.sessionWithEdits.taskIds,
    });
    /* Update state */
    this.setState({
      sessionWithEdits: {
        ...this.state.sessionWithEdits,
        tasks,
        taskIds,
      },
    });
  };

  handleDeleteProjectLabel = (id) => {
    const { tasks } = deleteProjectLabel({
      id,
      tasks: this.state.sessionWithEdits.tasks,
    });
    this.setState({
      sessionWithEdits: {
        ...this.state.sessionWithEdits,
        tasks,
      },
    });
  };

  /**
   * Handle when user clicks save/cancel button.
   */
  handleEdit = () => {
    /* Cancelling changes */
    if (this.state.editPreviousTasks) {
      /* Reset all the input ref values */
      this.state.sessionBeforeEdits.tasks.forEach(
        ({ focus, difficulty, id, notes = "", value }) => {
          const {
            difficultyInput,
            focusInput,
            notesInput,
            taskInput,
          } = this.inputRefMap[id];
          /* Some refs are undefined when they are temp deleted */
          if (difficultyInput && difficultyInput.current) {
            difficultyInput.current.value = difficulty;
          }
          if (focusInput && focusInput.current) {
            focusInput.current.value = focus;
          }
          if (notesInput && notesInput.current) {
            notesInput.current.value = notes;
          }
          if (taskInput && taskInput.current) {
            taskInput.current.value = value;
          }
        }
      );
      /* Set state back to original state */
      this.setState({
        editPreviousTasks: false,
        errorsDifficulty: null,
        errorsEndDate: "",
        errorsFocus: null,
        errorsStartDate: "",
        sessionWithEdits: {
          ...this.state.sessionBeforeEdits,
          tasks: this.state.sessionBeforeEdits.tasks.map((t) => ({ ...t })),
        },
        /* Clear tasks to delete */
        tasksToDelete: [],
      });
    } else {
      this.setState({
        editPreviousTasks: true,
      });
    }
  };

  /**
   * Determine if any errors exist based on state.
   */
  errorsExist = () => {
    let {
      sessionWithEdits: { endTime, startTime, tasks },
    } = this.state;

    /* No end/start time entered */
    if (!endTime) {
      this.setState({
        errorsEndDate: "Please enter an end time.",
      });
      return true;
    }
    if (!endTime) {
      this.setState({
        errorsStartDate: "Please enter a start time.",
      });
      return true;
    }

    /* Invalid dates */
    if (!moment(endTime).isValid()) {
      this.setState({
        errorsEndDate: "Please enter a valid date.",
      });
      return true;
    }
    if (!moment(startTime).isValid()) {
      this.setState({
        errorsStartDate: "Please enter a valid date.",
      });
      return true;
    }

    /* Check that end is after start */
    if (moment(endTime) < moment(startTime)) {
      this.setState({
        errorsStartDate: "Start time must come before end time.",
      });
      return true;
    }

    /* Tasks must take less time than session length */
    const totalTime = tasks.reduce((acc, t) => acc + t.actualTime, 0) * 1000;
    const dateDiff = moment(endTime).diff(moment(startTime));
    if (totalTime > dateDiff) {
      const dateDiffMins = moment(endTime).diff(moment(startTime), "minutes");
      this.setState({
        errorsEndDate: `Your tasks took more time to complete than ${dateDiffMins} minutes.`,
      });
      return true;
    }

    for (const task of tasks) {
      if (isNaN(task.focus)) {
        this.setState({
          errorsFocus: {
            id: task.id,
            message: "Tasks' focus values must be a number.",
          },
        });
        return true;
      }
      if (parseInt(task.focus) <= 0 || parseInt(task.focus) > 10) {
        this.setState({
          errorsFocus: {
            id: task.id,
            message: "Tasks' focus values must be a number between 1 and 10.",
          },
        });
        return true;
      }
      if (isNaN(task.difficulty)) {
        this.setState({
          errorsDifficulty: {
            id: task.id,
            message: "Tasks' difficulty values must be a number.",
          },
        });
        return true;
      }
      if (parseInt(task.difficulty) <= 0 || parseInt(task.difficulty) > 10) {
        this.setState({
          errorsFocus: {
            id: task.id,
            message:
              "Tasks' difficulty values must be a number between 1 and 10.",
          },
        });
        return true;
      }
    }

    this.setState({
      errorsDifficulty: null,
      errorsFocus: null,
    });

    return false;
  };

  /**
   * Add newly added task to state
   */
  handleAddTask = ({ ...task }) => {
    const { sessionWithEdits } = this.state;
    /* Get copies from state */
    const tasks = sessionWithEdits.tasks.map((t) => ({ ...t }));
    const taskIds = [...sessionWithEdits.taskIds];
    /* Add to tasks array */
    tasks.push(task);
    /* Add to taskIds array */
    taskIds.push(task.id);
    /* Set state */
    this.setState({
      sessionWithEdits: {
        ...sessionWithEdits,
        tasks: tasks.map((t) => ({ ...t })),
        taskIds: [...taskIds],
      },
      showAddTaskDialog: false,
    });
  };

  /**
   * Closes add task dialog
   */
  handleCancelAddTask = () => {
    this.setState({
      showAddTaskDialog: false,
    });
  };

  /**
   * Save updated data to firebase
   */
  handleSave = async () => {
    const { sessionWithEdits, tasksDeleted, tasksToDelete } = this.state;
    if (!this.errorsExist()) {
      const { taskIds } = sessionWithEdits;
      const tasks = sessionWithEdits.tasks.map((t) => ({ ...t }));
      /* Update session in firebase */
      updateFirebaseSession({
        ...sessionWithEdits,
        projects: [
          ...new Set(
            tasks
              .filter(
                ({ id, projectName }) =>
                  projectName && !tasksToDelete.includes(id)
              )
              .map(({ projectName }) => projectName)
          ),
        ],
        taskIds: taskIds.filter((id) => !tasksToDelete.includes(id)),
      });
      /* Update tasks in firebase */
      updateTasks(tasks);
      /* Delete tasks if needed */
      try {
        await Promise.all(
          tasksToDelete.map(async (id) => await deleteTaskFirebase(id))
        );
      } catch (error) {
        console.log("Error deleting tasks");
      }
      /* Track these so we never show them again */
      tasksDeleted.push(...this.state.tasksToDelete);
      this.setState({
        editPreviousTasks: false,
        errorsEndDate: "",
        errorsStartDate: "",
        sessionBeforeEdits: {
          ...sessionWithEdits,
          tasks: sessionWithEdits.tasks.map((t) => ({ ...t })),
        },
        tasksDeleted,
        tasksToDelete: [],
      });
    }
  };

  /**
   * Delete entire session
   */
  handleDelete = async () => {
    await deleteSession(this.state.sessionId);
    this.props.history.push(`/previous`);
  };

  /**
   * Handle tooltip opening + log event
   */
  handleOpen = () => {
    logPreviousSessionEvent({
      action: "Tooltip Displayed",
      label: "Previous Session Tasks",
    });
    this.setState({ openTooltip: true });
  };

  /**
   * Handle tooltip closing + log event
   */
  handleClose = () => {
    this.setState({ openTooltip: false });
  };

  /**
   * Update state with date change
   */
  handleDateChange = ({ date, type }) => {
    const { sessionWithEdits } = this.state;
    const tasks = sessionWithEdits.tasks.map((t) => ({ ...t }));
    const taskIds = [...sessionWithEdits.taskIds];
    let dateVal;
    /* HACK -- this is so bad because inconsistent way of saving date in state */
    try {
      dateVal = date.toISOString();
      /* Update each task's date based on new date, order, and task time */
      if (type === "startTime") {
        tasks.sort((a, b) => {
          const indexA = taskIds.findIndex((id) => id === a.id);
          const indexB = taskIds.findIndex((id) => id === b.id);
          return indexA - indexB;
        });
        let timeToAdd = 0;
        for (const task of tasks) {
          task.date = moment(date)
            .add(timeToAdd, "seconds")
            .toDate()
            .toISOString();
          timeToAdd += task.actualTime;
        }
      }
    } catch (error) {
      dateVal = date;
    }
    this.setState({
      sessionWithEdits: {
        ...this.state.sessionWithEdits,
        tasks,
        [type]: dateVal,
      },
    });
  };

  setEditPreviousTasks = (editable) => {
    this.setState({
      editPreviousTasks: editable,
    });
  };

  render() {
    const { classes, user } = this.props;
    const {
      editPreviousTasks,
      errorsDifficulty,
      errorsFocus,
      loading,
      sessionWithEdits,
    } = this.state;

    if (loading) {
      return <Loading user={user} />;
    }

    if (!sessionWithEdits || !sessionWithEdits.tasks) {
      return <NoData label="No Session Data" user={user} />;
    }

    const { taskIds, tasks } = sessionWithEdits;

    const toolTipText =
      "Type to edit notes and the task description. To edit a task's time, click the timer icon. To edit the project name, click the category icon. To delete a task, click the delete icon.";

    return (
      <>
        <div>
          {/* <Container maxWidth="md"> */}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid
                alignItems="center"
                className={classes.dashboardLabels}
                container
                justify="space-between"
              >
                <Grid item sm={6} xs={12}>
                  <Grid container justify="flex-start">
                    <Typography align="left" display="inline" variant="h6">
                      Previous Session Tasks
                    </Typography>
                    <Tooltip
                      arrow
                      open={this.state.openTooltip}
                      onOpen={this.handleOpen}
                      onClose={this.handleClose}
                      placement="right"
                      title={toolTipText}
                    >
                      <Typography className={classes.infoIcon} display="inline">
                        <InfoIcon classes={{ root: classes.infoIconRoot }} />
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Grid alignItems="center" container justify="flex-end">
                    {editPreviousTasks ? (
                      <Button
                        onClick={this.handleEdit}
                        style={{ height: 36 }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <Button
                      onClick={
                        editPreviousTasks ? this.handleSave : this.handleEdit
                      }
                      variant={editPreviousTasks ? "contained" : "outlined"}
                    >
                      {editPreviousTasks ? "Save Changes" : "Edit"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.grid} container>
                <Grid className={classes.grid} item xs={12}>
                  <div>
                    {tasks
                      .sort((a, b) => {
                        const indexA = taskIds.findIndex((id) => id === a.id);
                        const indexB = taskIds.findIndex((id) => id === b.id);
                        return indexA - indexB;
                      })
                      .map((task, index) => {
                        /* Temporarily hide tasks that are to be deleted */
                        if (this.state.tasksToDelete.includes(task.id))
                          return <div></div>;
                        if (this.state.tasksDeleted.includes(task.id))
                          return <div></div>;
                        return (
                          <TaskCard
                            addNewProjectName={this.addNewProjectName}
                            deleteTask={this.deleteTask}
                            difficulty={task.difficulty}
                            draggableIndex={index}
                            editable={editPreviousTasks}
                            editTasks={this.editTasks}
                            enableDragging={false}
                            errorsDifficulty={errorsDifficulty}
                            errorsFocus={errorsFocus}
                            focus={task.focus}
                            handleDeleteProjectLabel={
                              this.handleDeleteProjectLabel
                            }
                            hideAddTaskButton={true}
                            isRootTask={false}
                            isPreviousSession={true}
                            key={task.id}
                            notes={task.notes}
                            projectNames={this.state.projectNames}
                            projectValue={task.projectName}
                            setEditPreviousTasks={this.setEditPreviousTasks}
                            taskId={task.id}
                            textFieldValue={task.value}
                            timeLabelText={getTimeLabelText(task)}
                            updateInputRefMap={this.updateInputRefMap}
                          />
                        );
                      })}
                    <Button
                      onClick={() =>
                        this.setState({
                          editPreviousTasks: true,
                          showAddTaskDialog: true,
                        })
                      }
                      variant="contained"
                    >
                      Add Another Task
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                className={classes.dashboardLabels}
                container
                justify="flex-start"
              >
                <Typography align="left" display="inline" variant="h6">
                  Session Data
                </Typography>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    "To edit this data, click the edit button at the top right of this page."
                  }
                >
                  <Typography className={classes.infoIcon} display="inline">
                    <InfoIcon classes={{ root: classes.infoIconRoot }} />
                  </Typography>
                </Tooltip>
              </Grid>
              <Paper className={classes.paper} elevation={0} variant="outlined">
                <PreviousSessionDataTable
                  dataPoints={[
                    "startTime",
                    "endTime",
                    "timeElapsed",
                    "totalTasks",
                  ]}
                  editPreviousTasks={editPreviousTasks}
                  errorsEndDate={this.state.errorsEndDate}
                  errorsStartDate={this.state.errorsStartDate}
                  handleDateChange={this.handleDateChange}
                  handleStartDateError={this.handleStartDateError}
                  hideEndSession={true}
                  previousSession={sessionWithEdits}
                  tasks={tasks}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => this.setState({ openWarningDialog: true })}
                style={{ backgroundColor: "#FF0600" }}
                variant="contained"
              >
                Delete Session
              </Button>
            </Grid>
          </Grid>
          <div>
            <Dialog
              open={this.state.openWarningDialog}
              onClose={this.dialogClosed}
            >
              <DialogTitle>Delete Session</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <b>
                    Are you sure you want to permanently remove this session
                    from your data?
                  </b>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ openWarningDialog: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ openWarningDialog: false }, () => {
                      /* Delete session */
                      this.handleDelete();
                    });
                  }}
                  style={{ backgroundColor: "#FF0600" }}
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {/* </Container> */}
        </div>
        <div>
          <SubRequiredDialog />
        </div>
        <div>
          <AddTaskDialog
            addNewProjectName={this.addNewProjectName}
            handleAddTask={this.handleAddTask}
            handleCancelAddTask={this.handleCancelAddTask}
            open={this.state.showAddTaskDialog}
            projectNames={this.state.projectNames}
            sessionId={sessionWithEdits.sessionId}
            taskDate={sessionWithEdits.startTime}
          />
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(PreviousSessionPage));
