import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
// import TwitterIcon from "@material-ui/icons/Twitter";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

import { logStripeEvent } from "../analytics/index";
import { getCustomerIdFromSession, saveStripeData } from "../utils/server";
import {
  // convertSentenceForUrl,
  getHoursAndMinsFromTime,
  getMuiPropTypes,
  getPercentageChange,
  getRandomInt,
} from "../utils/utils";
import {
  fetchCompletedTasks,
  fetchCurrentStreak,
  fetchSessions,
  getDailyGoalData,
} from "../firebase";
import {
  filterAvgSessionPerWeekLineGraph,
  filterLineGraphData,
  FILTER_OPTIONS,
  filterStackGraphData,
  generateFakeData,
  getAvgSessionPerWeekData,
  getHoursPerDayData,
  getHoursPerMonthData,
  getHoursPerWeekData,
  MOMENT_FORMAT,
} from "../graph/index";

import Loading from "./Loading";
import LineGraph from "../graph/LineGraph";
import StackedGraph from "../graph/StackedGraph";
import FilterBtn from "../graph/FilterBtn";
import SubRequiredDialog from "./SubRequiredDialog";
import ThanksDialog from "./ThanksDialog";
import DataTable from "./DataTable";

const graphPadding = { bottom: 50, left: 50, right: 50, top: 10 };
// const BASE_TWEET_URL = `https://twitter.com/intent/tweet?text=`;
const THIRTY_DAYS_AGO = moment().subtract(30, "days");
const headCells = [
  { id: "month", numeric: false, label: "Month" },
  { id: "hours", numeric: true, label: "Total Hours" },
  { id: "percentageChange", numeric: true, label: "Change from Prev. Month" },
];

const styles = (theme) => ({
  bodyFont: {
    fontSize: "1.1rem",
  },
  buttonContainer: {
    marginTop: 20,
  },
  dashboardLabels: theme.dashboardLabels.h4,
  graphDivider: {
    marginTop: 50,
  },
  gridItemGraph: {
    maxHeight: "75vh",
  },
  infoIcon: theme.infoIcon.main,
  infoIconRoot: theme.infoIcon.root,
  paper: {
    backgroundColor: theme.palette.background.default,
  },
  startDeepWorkListItem: {
    backgroundColor: theme.palette.mainGreen.color,
    borderRadius: 25,
    fontWeight: 600,
  },
  titleMargin: {
    marginTop: 50,
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyGoal: 4,
      deepWorkToday: 0,
      hoursPerMonthData: [],
      loading: true,
      recentStats: [],
      showGraphs: false,
      showFakeData: false,
      showThanksDialog: false,
      /* All Graphs */
      avgSessionPerWeekLineGraph: {
        overallData: [],
        selectedFilter: FILTER_OPTIONS.MONTH,
        selectedData: [],
      },
      hoursLineGraph: {
        dayData: {},
        selectedData: [],
        selectedFilter: FILTER_OPTIONS.WEEK,
        weekData: {},
      },
      difficultyPerDayLineGraph: {
        overallData: [],
        selectedFilter: FILTER_OPTIONS.WEEK,
        selectedData: [],
      },
      focusPerDayLineGraph: {
        overallData: [],
        selectedFilter: FILTER_OPTIONS.WEEK,
        selectedData: [],
      },
      hoursPerDayStackedGraph: {
        colorMap: {},
        legend: [],
        overallData: { Overall: [] },
        selectedFilter: FILTER_OPTIONS.MONTH,
        selectedData: [],
      },
    };
  }

  async componentDidMount() {
    /* Write Stripe data to DB */
    const { search = "" } = this.props.history.location;
    if (search) {
      const sessionId = new URLSearchParams(search).get("session_id");
      if (sessionId) {
        logStripeEvent({
          action: "Successful Payment",
          label: "Home.js with Session ID",
        });
        /* Save customerId to DB */
        const customerId = await getCustomerIdFromSession(sessionId);
        saveStripeData(customerId);
        this.setState({
          showThanksDialog: true,
        });
      }
    }
    /* Data for graphs */
    const sessions = await fetchSessions();
    const tasks = await fetchCompletedTasks();
    if (sessions.length && tasks.length) {
      this.updateAllData({ sessions, tasks });
    } else {
      /* Show fake data is user doesn't have any */
      this.setState(
        {
          showFakeData: true,
        },
        () => {
          const { sessions, tasks } = generateFakeData();
          this.updateAllData({ sessions, tasks });
        }
      );
    }
  }

  updateAllData = ({ sessions, tasks }) => {
    this.updateAvgSessionPerWeekData(sessions);
    this.updateRecentStats(tasks);
    this.updateDeepWorkToday(tasks);
    this.updateTaskHoursData(tasks);
  };

  /**
   * Process data related to hours spent on tasks.
   */
  updateTaskHoursData = ([...tasks]) => {
    const {
      colorMap,
      difficultyData,
      focusData,
      hoursPerDayData,
    } = getHoursPerDayData(tasks);
    const hoursPerWeekData = getHoursPerWeekData(tasks);
    const hoursPerMonthData = getHoursPerMonthData(hoursPerDayData, 12);
    /* Set overall data */
    this.setState(
      {
        difficultyPerDayLineGraph: {
          ...this.state.difficultyPerDayLineGraph,
          overallData: [...difficultyData],
        },
        focusPerDayLineGraph: {
          ...this.state.focusPerDayLineGraph,
          overallData: [...focusData],
        },
        hoursLineGraph: {
          ...this.state.hoursLineGraph,
          dayData: { ...hoursPerDayData },
          weekData: { ...hoursPerWeekData },
        },
        hoursPerDayStackedGraph: {
          ...this.state.hoursPerDayStackedGraph,
          colorMap: { ...colorMap },
          overallData: { ...hoursPerDayData },
        },
        hoursPerMonthData,
        /* Only show graph when there is some relevant data */
        showGraphs: hoursPerDayData.Overall && hoursPerDayData.Overall.length,
      },
      () => {
        /* Call functions that generate graph specific data */
        this.updateHoursLineGraph(FILTER_OPTIONS.WEEK);
        this.updateDifficultyPerDayLineGraph(FILTER_OPTIONS.WEEK);
        this.updateFocusPerDayLineGraph(FILTER_OPTIONS.WEEK);
        this.updateHoursPerDayStackedGraph(FILTER_OPTIONS.WEEK);
      }
    );
  };

  updateAvgSessionPerWeekData = ([...sessions]) => {
    const overallData = getAvgSessionPerWeekData(sessions);
    this.setState(
      {
        avgSessionPerWeekLineGraph: {
          ...this.state.avgSessionPerWeekLineGraph,
          overallData: [...overallData],
        },
      },
      () => {
        /* Call functions that generate graph specific data */
        this.updateAvgSessionPerWeekLineGraph(FILTER_OPTIONS.MONTH);
      }
    );
  };

  updateDifficultyPerDayLineGraph = (numDays) => {
    this.setState({
      difficultyPerDayLineGraph: {
        ...this.state.difficultyPerDayLineGraph,
        selectedData: filterLineGraphData({
          numDays,
          /* Edit data so that it conforms to function's expectations */
          overallData: {
            ["Overall"]: [...this.state.difficultyPerDayLineGraph.overallData],
          },
        }),
        selectedFilter: numDays,
      },
    });
  };

  updateFocusPerDayLineGraph = (numDays) => {
    this.setState({
      focusPerDayLineGraph: {
        ...this.state.focusPerDayLineGraph,
        selectedData: filterLineGraphData({
          numDays,
          /* Edit data so that it conforms to function's expectations */
          overallData: {
            ["Overall"]: [...this.state.focusPerDayLineGraph.overallData],
          },
        }),
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Sets data for hours per day line graph
   * by using filter etc.
   */
  updateHoursLineGraph = (numDays) => {
    let overallData = null;
    if (
      numDays === FILTER_OPTIONS.WEEK ||
      numDays === FILTER_OPTIONS.TWO_WEEKS
    ) {
      overallData = { ...this.state.hoursLineGraph.dayData };
    } else {
      overallData = { ...this.state.hoursLineGraph.weekData };
    }
    this.setState({
      hoursLineGraph: {
        ...this.state.hoursLineGraph,
        selectedData: filterLineGraphData({
          numDays,
          overallData,
        }),
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Sets data for hours per day stacked graph
   * by using filter etc.
   */
  updateHoursPerDayStackedGraph = (numDays) => {
    const { legend, selectedData } = filterStackGraphData({
      colorMap: { ...this.state.hoursPerDayStackedGraph.colorMap },
      numDays,
      overallData: { ...this.state.hoursPerDayStackedGraph.overallData },
    });
    this.setState({
      hoursPerDayStackedGraph: {
        ...this.state.hoursPerDayStackedGraph,
        legend,
        selectedData,
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Processes sessions data and converts it to
   * a form that the avg. session length graph
   * can use.
   */
  updateAvgSessionPerWeekLineGraph = (numDays) => {
    this.setState({
      avgSessionPerWeekLineGraph: {
        ...this.state.avgSessionPerWeekLineGraph,
        selectedData: filterAvgSessionPerWeekLineGraph({
          numDays,
          overallData: this.state.avgSessionPerWeekLineGraph.overallData,
        }),
        selectedFilter: numDays,
      },
    });
  };

  updateRecentStats = async ([...tasks]) => {
    const currentStreak = await fetchCurrentStreak();

    /* Get earliest date */
    tasks.sort((a, b) => a.date - b.date);

    /* Get relevant timestamps to filter tasks */
    const lastSundayMoment =
      moment().day() === 0 ? moment().startOf("day") : moment().startOf("week");
    const twoSundaysAgoMoment = lastSundayMoment.clone().subtract(7, "days");
    const lastSunday = firebase.firestore.Timestamp.fromDate(
      lastSundayMoment.toDate()
    );
    const twoWeeksSunday = firebase.firestore.Timestamp.fromDate(
      twoSundaysAgoMoment.toDate()
    );
    const lastWeekTasks = tasks.filter(({ date }) => date > lastSunday);
    const twoWeekAgoTasks = tasks.filter(
      ({ date }) => date > twoWeeksSunday && date < lastSunday
    );

    /* Calculate deep work avg (divide by 3600 to conver to hrs) */
    const totalSecondsThisWk = lastWeekTasks.reduce((acc, task) => {
      const { actualTime = 0 } = task;
      return acc + actualTime;
    }, 0);
    const totalSecondsTwoWeeksAgo = twoWeekAgoTasks.reduce((acc, task) => {
      const { actualTime = 0 } = task;
      return acc + actualTime;
    }, 0);
    const pastWeekDeepWorkAvg =
      totalSecondsThisWk / 3600 / (moment().diff(lastSundayMoment, "days") + 1);
    const twoWeekAgoDeepWorkAvg = totalSecondsTwoWeeksAgo / 3600 / 7;

    /* Calculate deep work scores */
    let runningAvg = 0;
    let numTasks = 0;
    lastWeekTasks.forEach((task) => {
      if (task.difficulty && task.focus) {
        runningAvg += (task.focus + task.difficulty) / 2;
        numTasks++;
      }
    });
    let oldRunningAvg = 0;
    let oldNumTasks = 0;
    twoWeekAgoTasks.forEach((task) => {
      if (task.difficulty && task.focus) {
        oldRunningAvg += (task.focus + task.difficulty) / 2;
        oldNumTasks++;
      }
    });
    const pastWeekDeepWorkScore = lastWeekTasks.length
      ? runningAvg / numTasks
      : 0;
    const twoWeekAgoDeepWorkScore = twoWeekAgoTasks.length
      ? oldRunningAvg / oldNumTasks
      : 0;

    /* Set data to be used in UI */
    /* https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview */
    // const dwAvgTweet = `I've been averaging ${pastWeekDeepWorkAvg.toFixed(
    //   1
    // )} hours of deep work per day recently (tracked on trywinston.com)!`;
    // const deepWorkScoreTweet = `My Winston deep work score is ${
    //   pastWeekDeepWorkScore.toFixed(1) * 10
    // }!`;
    // const dwStreakTweet = `I have a ${currentStreak} day deep work streak going (tracked on trywinston.com)!`;
    const recentStats = [
      {
        color:
          pastWeekDeepWorkAvg > twoWeekAgoDeepWorkAvg ? "#1EB980" : "#FF0600",
        diff: getPercentageChange({
          newNum: pastWeekDeepWorkAvg,
          originalNum: twoWeekAgoDeepWorkAvg,
        }).toFixed(1),
        // hrefTweet: `${BASE_TWEET_URL}${convertSentenceForUrl(dwAvgTweet)}`,
        subtitle: "Daily Avg.",
        toolTip:
          "The amount of deep work per day you've averaged since the beginning of the week.",
        value: `${pastWeekDeepWorkAvg.toFixed(1)} Hours`,
      },
      {
        color:
          totalSecondsThisWk > totalSecondsTwoWeeksAgo ? "#1EB980" : "#FF0600",
        diff: getPercentageChange({
          newNum: totalSecondsThisWk,
          originalNum: totalSecondsTwoWeeksAgo,
        }).toFixed(1),
        // hrefTweet: `${BASE_TWEET_URL}${convertSentenceForUrl(dwAvgTweet)}`,
        subtitle: "Total",
        toolTip:
          "The amount of deep work you've done since the beginning of the week.",
        value: `${(totalSecondsThisWk / 3600).toFixed(0)} Hours`,
      },
      {
        color: pastWeekDeepWorkScore > 5 ? "#1EB980" : "#FF0600",
        diff: getPercentageChange({
          newNum: pastWeekDeepWorkScore,
          originalNum: twoWeekAgoDeepWorkScore,
        }).toFixed(1),
        // hrefTweet: `${BASE_TWEET_URL}${convertSentenceForUrl(
        //   deepWorkScoreTweet
        // )}`,
        subtitle: "Deep Work Score",
        toolTip:
          "Your deep work score is calculated by averaging your focus and the difficulty of your tasks.",
        value: `${pastWeekDeepWorkScore.toFixed(1) * 10}`,
      },
      {
        color: currentStreak > 3 ? "#1EB980" : "#FF0600",
        // hrefTweet: `${BASE_TWEET_URL}${convertSentenceForUrl(dwStreakTweet)}`,
        subtitle: "Streak",
        toolTip:
          "The number of consecutive days that you've had at least one deep work session.",
        value: `${
          this.state.showFakeData
            ? getRandomInt({ max: 40, min: 4 })
            : currentStreak
        } Days`,
      },
    ];
    this.setState({
      recentStats,
    });
  };

  updateDeepWorkToday = async ([...tasks]) => {
    /* Get daily goal */
    const data = await getDailyGoalData();
    const dailyGoal = data.dailyGoal
      ? getHoursAndMinsFromTime(data.dailyGoal * 1000).hrs
      : 4;
    /* Calculate hours today */
    const deepWorkToday = tasks
      .filter(({ date }) => {
        if (!date) return false;
        return (
          moment(date.toDate()).format(MOMENT_FORMAT) ===
          moment().format(MOMENT_FORMAT)
        );
      })
      .reduce((acc, task) => {
        const { actualTime = 0 } = task;
        return acc + actualTime;
      }, 0);
    /* Convert to hours */
    this.setState({
      dailyGoal,
      deepWorkToday: deepWorkToday / 3600,
      loading: false,
    });
  };

  getTableRows = () => {
    return this.state.hoursPerMonthData.map((monthData, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          <TableCell align="left">
            {moment(monthData.dateStr, "MM-YYYY").format("MMM YYYY")}
          </TableCell>
          <TableCell align="right">{monthData.hours}</TableCell>
          <TableCell align="right">{monthData.percentageChange}%</TableCell>
        </TableRow>
      );
    });
  };

  // handleSwitch = () => {
  //   const includeWeekends = !this.state.includeWeekends;
  //   const dataToShow = this.filterData({allData: this.state.allData, includeWeekends, numDays: this.state.selectedFilter});
  //   this.setState({
  //     dataToShow,
  //     includeWeekends
  //   });
  // }

  isWeekend = (date) => {
    return date.getDay() === 6 || date.getDay() === 0;
  };

  render() {
    const { classes, user } = this.props;
    const {
      dailyGoal,
      deepWorkToday,
      loading,
      recentStats,
      showFakeData,
    } = this.state;

    if (loading) {
      return <Loading user={user} />;
    }

    const beatDailyGoal = deepWorkToday >= dailyGoal;

    const diffFromGoal = Math.abs(dailyGoal - deepWorkToday).toFixed(1);

    const dailyGoalBeg = `${
      beatDailyGoal ? "Congratulations! " : "Keep going! "
    } You've done `;
    const dailyGoalMiddle = ` hours of deep work today, `;
    const dailyGoalEnd = ` hours ${
      beatDailyGoal
        ? "more than your daily goal."
        : "less than your daily goal."
    }`;

    const tableRows = this.getTableRows();

    return (
      <Container maxWidth="md">
        <Grid alignItems="flex-start" container justify="center">
          <Grid item xs={12}>
            {/* FAKE DATA --> */}
            {showFakeData ? (
              <>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    className={classes.dashboardLabels}
                    variant="h4"
                  >
                    Warning
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper} variant="outlined">
                    <Grid container style={{ padding: 30 }}>
                      <Typography
                        align="left"
                        className={classes.bodyFont}
                        variant="body1"
                      >
                        You haven't done a deep work session yet, so we added
                        fake data so that you can see what your dashboard will
                        look like once you do.
                        <br />
                        <br />
                        <b>
                          After your first deep work session, this fake data
                          will be replaced by your real data.
                        </b>
                      </Typography>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: 25 }}
                      >
                        <Button
                          onClick={() =>
                            this.props.history.push("/start-session")
                          }
                          size="large"
                          variant="contained"
                        >
                          Start Deep Work
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            ) : (
              <div></div>
            )}
            {/* <-- FAKE DATA */}
            {/* DAILY GOAL --> */}
            <Grid className={showFakeData ? classes.titleMargin : ""} container>
              <Typography
                align="left"
                className={classes.dashboardLabels}
                display="inline"
                variant="h4"
              >
                Daily Goal
              </Typography>
              <Tooltip
                arrow
                placement="right"
                title={
                  "Studies show that to excel in a given field, you should do at least 4 hours of deep work each day. It's the productivity equivalent of 10,000 steps per day."
                }
              >
                <Typography className={classes.infoIcon} display="inline">
                  <InfoIcon classes={{ root: classes.infoIconRoot }} />
                </Typography>
              </Tooltip>
            </Grid>
            <Paper className={classes.paper} variant="outlined">
              <Grid container style={{ padding: 30 }}>
                <Typography
                  align="left"
                  className={classes.bodyFont}
                  variant="body1"
                >
                  {dailyGoalBeg}
                  <b
                    style={{
                      color: `${beatDailyGoal ? "#1EB980" : "#FF0600"}`,
                    }}
                  >{`${deepWorkToday.toFixed(1)}`}</b>
                  {dailyGoalMiddle}
                  <b
                    style={{
                      color: `${beatDailyGoal ? "#1EB980" : "#FF0600"}`,
                    }}
                  >{`${diffFromGoal}`}</b>
                  {dailyGoalEnd}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          {/* <-- DAILY GOAL */}
          <Grid className={classes.titleMargin} item xs={12}>
            <Typography
              align="left"
              className={classes.dashboardLabels}
              variant="h4"
            >
              Recent Stats
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container style={{ padding: 30 }}>
                {recentStats.map(
                  ({ diff, subtitle, toolTip, value }, index) => {
                    return (
                      <Grid item key={index} style={{ marginTop: 20 }} xs={3}>
                        <Tooltip arrow title={toolTip}>
                          <Grid alignItems="center" container justify="center">
                            <Grid item xs={12}>
                              {/* STATS ELEMENT */}
                              <Typography
                                align="center"
                                className={classes.bodyFont}
                                style={{
                                  color: "#E5E5E5",
                                }}
                                variant="body1"
                              >
                                {subtitle}
                              </Typography>
                              <Typography
                                style={{ fontSize: "1.75rem" }}
                                variant="h6"
                              >
                                {value}
                              </Typography>
                              <Grid
                                alignItems="center"
                                container
                                justify="center"
                              >
                                {diff === undefined || !isFinite(diff) ? (
                                  <div></div>
                                ) : (
                                  <Typography
                                    align="center"
                                    className={classes.bodyFont}
                                    style={{
                                      color: diff > 0 ? "#1EB980" : "#FF0600",
                                    }}
                                    variant="body1"
                                  >
                                    {`${diff > 0 ? "+" : ""} ${diff}%`}
                                  </Typography>
                                )}
                                {diff === undefined || !isFinite(diff) ? (
                                  <div></div>
                                ) : diff > 0 ? (
                                  <TrendingUpIcon
                                    style={{
                                      color: diff > 0 ? "#1EB980" : "#FF0600",
                                      marginLeft: 3,
                                    }}
                                  />
                                ) : (
                                  <TrendingDownIcon
                                    style={{
                                      color: diff > 0 ? "#1EB980" : "#FF0600",
                                      marginLeft: 3,
                                    }}
                                  />
                                )}
                              </Grid>
                              {/* <Link
                                    class="twitter-share-button"
                                    href={hrefTweet}
                                  >
                                    <TwitterIcon
                                      style={{
                                        color: "#E5E5E5",
                                        fontSize: "1.4rem",
                                      }}
                                    />
                                  </Link> */}
                            </Grid>
                          </Grid>
                        </Tooltip>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Paper>
          </Grid>
          {this.state.showGraphs ? (
            <>
              <Grid item style={{ marginTop: 50 }} xs={12}>
                <Typography
                  align="left"
                  className={classes.dashboardLabels}
                  variant="h4"
                >
                  All Data
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined">
                  <Grid container>
                    <Grid item style={{ paddingTop: 50 }} xs={12}>
                      <Typography variant="h6">Deep Work Hours</Typography>
                    </Grid>
                    <Grid className={classes.gridItemGraph} item xs={12}>
                      <LineGraph
                        data={this.state.hoursLineGraph.selectedData}
                        filter={this.state.hoursLineGraph.selectedFilter}
                        padding={graphPadding}
                        tickFormatUnit={"hrs"}
                        tickValues={
                          this.state.hoursLineGraph.selectedFilter ===
                            FILTER_OPTIONS.WEEK ||
                          this.state.hoursLineGraph.selectedFilter ===
                            FILTER_OPTIONS.TWO_WEEKS
                            ? [2, 4, 6, 8]
                            : [10, 20, 30, 40, 50]
                        }
                        yAxis={"hours"}
                      />
                    </Grid>
                    <Grid
                      className={classes.buttonContainer}
                      container
                      justify="center"
                    >
                      <Grid container justify="center">
                        <FilterBtn
                          buttonText={"Last 7 Days"}
                          onClick={() =>
                            this.updateHoursLineGraph(FILTER_OPTIONS.WEEK)
                          }
                          selected={
                            this.state.hoursLineGraph.selectedFilter ===
                            FILTER_OPTIONS.WEEK
                          }
                        />
                        {this.state.hoursLineGraph.dayData.Overall.some(
                          ({ date }) =>
                            moment(date).isBefore(moment().subtract(7, "days"))
                        ) ? (
                          <FilterBtn
                            buttonText={"Last 14 Days"}
                            onClick={() =>
                              this.updateHoursLineGraph(
                                FILTER_OPTIONS.TWO_WEEKS
                              )
                            }
                            selected={
                              this.state.hoursLineGraph.selectedFilter ===
                              FILTER_OPTIONS.TWO_WEEKS
                            }
                          />
                        ) : (
                          <div></div>
                        )}
                        <FilterBtn
                          buttonText={"Last 30 Days"}
                          onClick={() =>
                            this.updateHoursLineGraph(FILTER_OPTIONS.MONTH)
                          }
                          selected={
                            this.state.hoursLineGraph.selectedFilter ===
                            FILTER_OPTIONS.MONTH
                          }
                        />
                        {this.state.hoursLineGraph.weekData.Overall.some(
                          ({ date }) => moment(date).isBefore(THIRTY_DAYS_AGO)
                        ) ? (
                          <FilterBtn
                            buttonText={"All Time"}
                            onClick={() =>
                              this.updateHoursLineGraph(FILTER_OPTIONS.ALL_TIME)
                            }
                            selected={
                              this.state.hoursLineGraph.selectedFilter ===
                              FILTER_OPTIONS.ALL_TIME
                            }
                          />
                        ) : (
                          <div></div>
                        )}
                        {/* <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.includeWeekends}
                                    classes={{
                                      // root: classes.root,
                                      checked: classes.checked,
                                      switchBase: classes.switchBase,
                                      // thumb: classes.thumb,
                                      track: classes.track,
                                    }}
                                    onChange={this.handleSwitch}
                                  />
                                }
                                label="Include Weekends"
                              /> 
                          */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.graphDivider} />
                  <Grid container>
                    <Grid item style={{ paddingTop: 50 }} xs={12}>
                      <Typography variant="h6">
                        Deep Work Hours per Day (by project)
                      </Typography>
                    </Grid>
                    <Grid className={classes.gridItemGraph} item xs={12}>
                      <StackedGraph
                        filter={
                          this.state.hoursPerDayStackedGraph.selectedFilter ===
                          FILTER_OPTIONS.WEEK
                            ? "week"
                            : "two-weeks"
                        }
                        legend={this.state.hoursPerDayStackedGraph.legend}
                        padding={graphPadding}
                        stackedData={
                          this.state.hoursPerDayStackedGraph.selectedData
                        }
                        tickValues={[2, 4, 6, 8]}
                      />
                    </Grid>
                    <Grid
                      alignItems="center"
                      container
                      justify="center"
                      style={{ paddingBottom: 20 }}
                    >
                      {this.state.hoursPerDayStackedGraph.legend.map(
                        (data, index) => (
                          <Grid item key={index}>
                            <Grid alignItems="center" container>
                              <Divider
                                style={{
                                  backgroundColor: data.fill,
                                  height: 20,
                                  marginRight: 5,
                                  width: 20,
                                }}
                              />
                              <Typography
                                display="inline"
                                style={{ marginRight: 20 }}
                                variant="body2"
                              >
                                {data.projectName}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                    <Grid
                      className={classes.buttonContainer}
                      container
                      justify="center"
                    >
                      <FilterBtn
                        buttonText={"Last 7 Days"}
                        onClick={() =>
                          this.updateHoursPerDayStackedGraph(
                            FILTER_OPTIONS.WEEK
                          )
                        }
                        selected={
                          this.state.hoursPerDayStackedGraph.selectedFilter ===
                          FILTER_OPTIONS.WEEK
                        }
                      />
                      {this.state.hoursPerDayStackedGraph.overallData.Overall.some(
                        ({ date }) =>
                          moment(date).isBefore(moment().subtract(7, "days"))
                      ) ? (
                        <FilterBtn
                          buttonText={"Last 14 Days"}
                          onClick={() =>
                            this.updateHoursPerDayStackedGraph(
                              FILTER_OPTIONS.TWO_WEEKS
                            )
                          }
                          selected={
                            this.state.hoursPerDayStackedGraph
                              .selectedFilter === FILTER_OPTIONS.TWO_WEEKS
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                  {this.state.hoursPerMonthData.length && (
                    <div>
                      <Divider className={classes.graphDivider} />
                      <Grid item xs={12}>
                        <Grid item style={{ paddingTop: 50 }} xs={12}>
                          <Typography variant="h6">
                            Deep Work Hours per Month
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          style={{ padding: 50 }}
                        >
                          <Grid item xs={12}>
                            <DataTable
                              background={{ background: "#2A2A2A" }}
                              headCells={headCells}
                              rowsPerPage={tableRows.length}
                              size={"small"}
                              tableRows={tableRows}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <Divider className={classes.graphDivider} />
                  <Grid container style={{ marginBottom: 50 }}>
                    <Grid item style={{ paddingTop: 50 }} xs={12}>
                      <Typography variant="h6">
                        Avg. Session Length per Week
                      </Typography>
                    </Grid>
                    <Grid className={classes.gridItemGraph} item xs={12}>
                      <LineGraph
                        data={
                          this.state.avgSessionPerWeekLineGraph.selectedData
                        }
                        filter={
                          this.state.avgSessionPerWeekLineGraph.selectedFilter
                        }
                        padding={graphPadding}
                        tickFormatUnit={"hrs"}
                        tickValues={[1, 2, 3, 4]}
                        yAxis={"hours"}
                      />
                    </Grid>
                    <Grid
                      className={classes.buttonContainer}
                      container
                      justify="center"
                    >
                      <FilterBtn
                        buttonText={"Last 30 Days"}
                        onClick={() =>
                          this.updateAvgSessionPerWeekLineGraph(
                            FILTER_OPTIONS.MONTH
                          )
                        }
                        selected={
                          this.state.avgSessionPerWeekLineGraph
                            .selectedFilter === FILTER_OPTIONS.MONTH
                        }
                      />
                      {this.state.avgSessionPerWeekLineGraph.overallData.some(
                        ({ date }) => moment(date).isBefore(THIRTY_DAYS_AGO)
                      ) ? (
                        <FilterBtn
                          buttonText={"All Time"}
                          onClick={() =>
                            this.updateAvgSessionPerWeekLineGraph(
                              FILTER_OPTIONS.ALL_TIME
                            )
                          }
                          selected={
                            this.state.avgSessionPerWeekLineGraph
                              .selectedFilter === FILTER_OPTIONS.ALL_TIME
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Divider className={classes.graphDivider} />
                  <Grid container>
                    <Grid className={classes.gridItemGraph} item xs={12}>
                      <LineGraph
                        data={this.state.difficultyPerDayLineGraph.selectedData}
                        filter={
                          this.state.difficultyPerDayLineGraph.selectedFilter
                        }
                        labelText={"Avg. Difficulty per Day"}
                        tickFormatUnit={""}
                        tickValues={[2, 4, 6, 8, 10]}
                        yAxis={"difficulty"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        className={classes.buttonContainer}
                        container
                        justify="center"
                      >
                        <FilterBtn
                          buttonText={"Last 7 Days"}
                          onClick={() =>
                            this.updateDifficultyPerDayLineGraph(
                              FILTER_OPTIONS.WEEK
                            )
                          }
                          selected={
                            this.state.difficultyPerDayLineGraph
                              .selectedFilter === FILTER_OPTIONS.WEEK
                          }
                        />
                        {this.state.difficultyPerDayLineGraph.overallData.some(
                          ({ date }) =>
                            moment(date).isBefore(moment().subtract(7, "days"))
                        ) ? (
                          <FilterBtn
                            buttonText={"Last 14 Days"}
                            onClick={() =>
                              this.updateDifficultyPerDayLineGraph(
                                FILTER_OPTIONS.TWO_WEEKS
                              )
                            }
                            selected={
                              this.state.difficultyPerDayLineGraph
                                .selectedFilter === FILTER_OPTIONS.TWO_WEEKS
                            }
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.graphDivider} />
                  <Grid container style={{ marginBottom: 50 }}>
                    <Grid className={classes.gridItemGraph} item xs={12}>
                      <LineGraph
                        data={this.state.focusPerDayLineGraph.selectedData}
                        filter={this.state.focusPerDayLineGraph.selectedFilter}
                        labelText={"Avg. Focus per Day"}
                        tickFormatUnit={""}
                        tickValues={[2, 4, 6, 8, 10]}
                        yAxis={"focus"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        className={classes.buttonContainer}
                        container
                        justify="center"
                      >
                        <FilterBtn
                          buttonText={"Last 7 Days"}
                          onClick={() =>
                            this.updateFocusPerDayLineGraph(FILTER_OPTIONS.WEEK)
                          }
                          selected={
                            this.state.focusPerDayLineGraph.selectedFilter ===
                            FILTER_OPTIONS.WEEK
                          }
                        />
                        {this.state.focusPerDayLineGraph.overallData.some(
                          ({ date }) =>
                            moment(date).isBefore(moment().subtract(7, "days"))
                        ) ? (
                          <FilterBtn
                            buttonText={"Last 14 Days"}
                            onClick={() =>
                              this.updateFocusPerDayLineGraph(
                                FILTER_OPTIONS.TWO_WEEKS
                              )
                            }
                            selected={
                              this.state.focusPerDayLineGraph.selectedFilter ===
                              FILTER_OPTIONS.TWO_WEEKS
                            }
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Paper>
              </Grid>
            </>
          ) : (
            <div></div>
          )}
        </Grid>
        <div>
          <SubRequiredDialog />
        </div>
        <div>
          <ThanksDialog
            closeThanksDialog={() => this.setState({ showThanksDialog: false })}
            open={this.state.showThanksDialog}
          />
        </div>
      </Container>
    );
  }
}

Home.propTypes = {
  ...getMuiPropTypes(),
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withStyles(styles)(Home);
