import React from "react";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { content } from "./content";
import { fetchMediaURL } from "../firebase";
import { logAuthEvent } from "../analytics/index";
import {
  filterAvgSessionPerWeekLineGraph,
  filterLineGraphData,
  FILTER_OPTIONS,
  filterStackGraphData,
  generateFakeData,
  getAvgSessionPerWeekData,
  getHoursPerDayData,
  getHoursPerWeekData,
} from "../graph/index";

import Loading from "../components/Loading";
import LineGraph from "../graph/LineGraph";
import StackedGraph from "../graph/StackedGraph";
import DemoStats from "../components/DemoStats";

import { Desktop, MobileOrTablet } from "../responsive";
import Footer from "../components/Footer";
import { getMuiPropTypes } from "../utils/utils";

const styles = () => ({
  desktopPaddingTop: {
    paddingTop: 110,
  },
  mainContainer: {
    height: "80%",
  },
  mainGridContainer: {
    width: "100%",
    height: "100%",
  },
  mobilePaddingTop: {
    paddingTop: 80,
  },
});

class Landing extends React.Component {
  constructor(props) {
    super(props);
    /* Add fake data to state for demo */
    const { sessions, tasks } = generateFakeData();
    this.state = {
      /* All Graphs */
      avgSessionPerWeekLineGraph: {
        overallData: [],
        selectedFilter: FILTER_OPTIONS.MONTH,
        selectedData: [],
      },
      hoursPerDayLineGraph: {
        overallData: { Overall: [] },
        selectedFilter: FILTER_OPTIONS.WEEK,
        selectedData: [],
      },
      hoursPerDayStackedGraph: {
        colorMap: {},
        legend: [],
        overallData: { Overall: [] },
        selectedFilter: FILTER_OPTIONS.MONTH,
        selectedData: [],
      },
      hoursPerWeekLineGraph: {
        overallData: { Overall: [] },
        selectedFilter: FILTER_OPTIONS.MONTH,
        selectedData: [],
      },
      sessions,
      tasks,
      url: "",
    };
  }

  componentDidMount() {
    this.fetchTaskCardGif();
    this.fetchSessionGif();
    /* Update all graphs with sessions and tasks */
    this.updateAvgSessionPerWeekData(this.state.sessions);
    this.updateHoursPerDayData(this.state.tasks);
    this.updateHoursPerWeekData(this.state.tasks);
  }

  fetchTaskCardGif = async () => {
    const urlNewTask = await fetchMediaURL("media/new-task-demo.gif");
    const urlNewTaskSm = await fetchMediaURL("media/new-task-demo-sm.gif");
    this.setState({
      loading: false,
      urlNewTask,
      urlNewTaskSm,
    });
  };

  fetchSessionGif = async () => {
    const urlCurrentTaskSm = await fetchMediaURL(
      "media/current-task-demo-sm.gif"
    );
    const urlCurrentTaskMd = await fetchMediaURL(
      "media/current-task-rect-3.gif"
    );
    this.setState({
      urlCurrentTaskSm,
      urlCurrentTaskMd,
    });
  };

  /**
   * Process hours per day data and save it to state.
   */
  updateHoursPerDayData = ([...tasks]) => {
    const { colorMap, hoursPerDayData } = getHoursPerDayData(tasks);
    /* Set overall data */
    this.setState(
      {
        hoursPerDayLineGraph: {
          ...this.state.hoursPerDayLineGraph,
          overallData: { ...hoursPerDayData },
        },
        hoursPerDayStackedGraph: {
          ...this.state.hoursPerDayStackedGraph,
          colorMap: { ...colorMap },
          overallData: { ...hoursPerDayData },
        },
        /* Only show graph when there is some relevant data */
        showGraphs: hoursPerDayData.Overall && hoursPerDayData.Overall.length,
      },
      () => {
        /* Call functions that generate graph specific data */
        this.updateHoursPerDayLineGraph(FILTER_OPTIONS.WEEK);
        this.updateHoursPerDayStackedGraph(FILTER_OPTIONS.WEEK);
      }
    );
  };

  updateHoursPerWeekData = ([...tasks]) => {
    const hoursPerWeekData = getHoursPerWeekData(tasks);
    this.setState(
      {
        hoursPerWeekLineGraph: {
          ...this.state.hoursPerWeekLineGraph,
          overallData: { ...hoursPerWeekData },
        },
      },
      () => {
        /* Call functions that generate graph specific data */
        this.updateHoursPerWeekLineGraph(FILTER_OPTIONS.MONTH);
      }
    );
  };

  updateAvgSessionPerWeekData = ([...sessions]) => {
    const overallData = getAvgSessionPerWeekData(sessions);
    this.setState(
      {
        avgSessionPerWeekLineGraph: {
          ...this.state.avgSessionPerWeekLineGraph,
          overallData: [...overallData],
        },
      },
      () => {
        /* Call functions that generate graph specific data */
        this.updateAvgSessionPerWeekLineGraph(FILTER_OPTIONS.MONTH);
      }
    );
  };

  /**
   * Sets data for hours per day line graph
   * by using filter etc.
   */
  updateHoursPerDayLineGraph = (numDays) => {
    this.setState({
      hoursPerDayLineGraph: {
        ...this.state.hoursPerDayLineGraph,
        selectedData: filterLineGraphData({
          numDays,
          overallData: { ...this.state.hoursPerDayLineGraph.overallData },
        }),
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Sets data for hours per day stacked graph
   * by using filter etc.
   */
  updateHoursPerDayStackedGraph = (numDays) => {
    const { legend, selectedData } = filterStackGraphData({
      colorMap: { ...this.state.hoursPerDayStackedGraph.colorMap },
      numDays,
      overallData: { ...this.state.hoursPerDayStackedGraph.overallData },
    });
    this.setState({
      hoursPerDayStackedGraph: {
        ...this.state.hoursPerDayStackedGraph,
        legend,
        selectedData,
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Sets data for hours per week line graph
   * by using filter etc.
   */
  updateHoursPerWeekLineGraph = (numDays) => {
    this.setState({
      hoursPerWeekLineGraph: {
        ...this.state.hoursPerWeekLineGraph,
        selectedData: filterLineGraphData({
          numDays,
          overallData: { ...this.state.hoursPerWeekLineGraph.overallData },
        }),
        selectedFilter: numDays,
      },
    });
  };

  /**
   * Processes sessions data and converts it to
   * a form that the avg. session length graph
   * can use.
   */
  updateAvgSessionPerWeekLineGraph = (numDays) => {
    this.setState({
      avgSessionPerWeekLineGraph: {
        ...this.state.avgSessionPerWeekLineGraph,
        selectedData: filterAvgSessionPerWeekLineGraph({
          numDays,
          overallData: this.state.avgSessionPerWeekLineGraph.overallData,
        }),
        selectedFilter: numDays,
      },
    });
  };

  handleLogIn = () => {
    logAuthEvent({
      action: "Clicked Log In Button",
      label: "Home Page",
    });
    this.props.history.push("/login");
  };

  handleNewAccount = (label) => {
    logAuthEvent({
      action: "Clicked Start Free Trial",
      label: `${label} (Landing.js)`,
    });
    this.props.history.push("/signup");
  };

  getTextLayout = ({ alignText = "left", body, cta, title }) => {
    return (
      <>
        {/* How to hide: https://stackoverflow.com/questions/62775719/hide-material-ui-grid-column-on-certain-breakpoints */}
        <Box
          component={Grid}
          display={{ md: "none" }}
          item
          sm={2}
          xs={false}
        ></Box>
        <Grid item md={4} sm={8} xs={12}>
          <Typography
            align={alignText}
            style={{ fontWeight: 500 }}
            variant="h5"
          >
            {title}
          </Typography>
          <Typography
            align={alignText}
            style={{ fontSize: "1rem", marginTop: 10 }}
            variant="body1"
          >
            {body}
          </Typography>
          <Grid
            alignItems="center"
            container
            onClick={() => {
              logAuthEvent({
                action: "Clicked How it Works Button",
                label: `${cta}`,
              });
              this.props.history.push("/signup");
            }}
            style={{ cursor: "pointer", marginTop: 10 }}
          >
            <Typography
              align="left"
              style={{ color: "#1EB980", fontSize: "1rem" }}
              variant="body1"
            >
              <b>{cta}</b>
            </Typography>
            <ArrowForwardIosIcon
              style={{ color: "#1EB980", fontSize: "0.9rem", marginLeft: 2 }}
            />
          </Grid>
        </Grid>
        <Box
          component={Grid}
          display={{ md: "none" }}
          item
          sm={2}
          xs={false}
        ></Box>
      </>
    );
  };

  render() {
    const { classes, user } = this.props;
    const { tasks } = this.state;
    const { hero, howItWorks } = content;

    const toolTipContent = [
      "Tracking your 7-day average will inspire you to match or beat it everyday.",
      "Tracking your daily deep work hours will show you how well you're doing on a given day.",
      "Tracking your deep work streak will inspire you to keep knocking out deep work sessions!",
    ];

    const graphs = [
      <StackedGraph
        filter={"week"}
        key={"hoursPerDayStackedGraph"}
        labelText={"Deep Work Hours per Day (by project)"}
        legend={this.state.hoursPerDayStackedGraph.legend}
        stackedData={this.state.hoursPerDayStackedGraph.selectedData}
        padding={{ bottom: 40, left: 40, right: 10, top: 40 }}
        tickFormatUnit={"hrs"}
        tickValues={[2, 4, 6, 8]}
        yAxis={"hours"}
      />,
      <LineGraph
        data={this.state.hoursPerDayLineGraph.selectedData}
        key={"hoursPerDayLineGraph"}
        labelText={"Deep Work Hours per Day"}
        padding={{ bottom: 40, left: 40, right: 10, top: 40 }}
        tickFormatUnit={"hrs"}
        tickValues={[2, 4, 6, 8]}
        yAxis={"hours"}
      />,
      <LineGraph
        data={this.state.hoursPerWeekLineGraph.selectedData}
        key={"hoursPerWeekLineGraph"}
        labelText={"Deep Work Hours per Week"}
        padding={{ bottom: 40, left: 40, right: 10, top: 40 }}
        tickFormatUnit={"hrs"}
        tickValues={[10, 20, 30, 40, 50]}
        yAxis={"hours"}
      />,
      <LineGraph
        data={this.state.avgSessionPerWeekLineGraph.selectedData}
        key={"avgSessionPerWeekLineGraph"}
        labelText={"Avg. Session Length per Week"}
        padding={{ bottom: 40, left: 40, right: 10, top: 40 }}
        tickFormatUnit={"hrs"}
        tickValues={[0.5, 1, 1.5, 2, 2.5, 3]}
        yAxis={"hours"}
      />,
    ];

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Container className={classes.mainContainer} maxWidth="md">
        <Grid
          className={classes.mainGridContainer}
          container
          justify="center"
          alignItems="center"
        >
          {/* Hero --> */}
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              {hero.title}
            </Typography>
          </Grid>
          <Grid item sm={1} xs={false}></Grid>
          <Grid item sm={10} xs={12} style={{ marginTop: 30 }}>
            <Typography variant="h6">{hero.subtitle}</Typography>
          </Grid>
          <Grid item sm={1} xs={false}></Grid>
          {user ? (
            <div></div>
          ) : (
            <Grid item xs={12} style={{ marginTop: 30 }}>
              <Button
                variant="contained"
                onClick={() => this.handleNewAccount("Hero Button")}
              >
                Start Free Trial
              </Button>
            </Grid>
          )}
          {/* <-- Hero */}
          <Grid item xs={12} style={{ paddingBottom: 50, paddingTop: 50 }}>
            <Divider />
          </Grid>
          {/* Not for everyone --> */}
          <Grid item sm={1} xs={false}></Grid>
          <Grid item sm={10} xs={12}>
            <Typography style={{ fontWeight: 500 }} variant="h4">
              What is Deep Work?
            </Typography>
            <Typography
              align="left"
              style={{ fontSize: "1rem", paddingTop: 40 }}
              variant="body1"
            >
              It's a method of working where you block off periods of time with
              no distractions to hyper focus on challenging tasks. Deep work is
              the key to mastering any high value skill.
              <br />
              <br />
              You don't need 100 productivity hacks. If you regularly engage in
              deep work sessions, you'll be amazed with what you can accomplish.
            </Typography>
            <br />
            <Grid
              alignItems="center"
              container
              onClick={() => this.props.history.push("/deep-work")}
              style={{ cursor: "pointer" }}
            >
              <Typography
                align="left"
                style={{ color: "#1EB980", fontSize: "1rem" }}
                variant="body1"
              >
                <b>Learn about Deep Work</b>
              </Typography>
              <ArrowForwardIosIcon
                style={{
                  color: "#1EB980",
                  fontSize: "0.9rem",
                  marginLeft: 2,
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={1} xs={false}></Grid>
          {/* <-- Not for everyone */}
          <Grid item xs={12} style={{ paddingBottom: 50, paddingTop: 50 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 500 }} variant="h4">
              How it Works
            </Typography>
          </Grid>
          {/* Create a task --> */}
          <MobileOrTablet>
            <Grid className={classes.mobilePaddingTop} container>
              {this.getTextLayout({
                ...howItWorks.addTask,
              })}
              <Grid item sm={2} xs={false}></Grid>
              <Grid item sm={8} style={{ paddingTop: 20 }} xs={12}>
                <img
                  alt="Add a Task"
                  src={this.state.urlNewTaskSm}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
              <Grid item sm={2} xs={false}></Grid>
            </Grid>
          </MobileOrTablet>
          <Desktop>
            <Grid className={classes.desktopPaddingTop} container>
              {this.getTextLayout({
                ...howItWorks.addTask,
                alignText: "left",
              })}
              <Grid item style={{ paddingLeft: 70 }} md={8}>
                <img
                  alt="Add a Task"
                  src={this.state.urlNewTask}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
            </Grid>
          </Desktop>
          {/* <-- Create a task */}
          {/* Start a session --> */}
          <MobileOrTablet>
            <Grid
              className={classes.mobilePaddingTop}
              container
              style={{ paddingTop: 40 }}
            >
              {this.getTextLayout({
                ...howItWorks.startDeepWork,
              })}
              <Grid item sm={2} xs={false}></Grid>
              <Grid item sm={8} style={{ paddingTop: 25 }} xs={12}>
                <img
                  alt="Start Deep Work"
                  src={this.state.urlCurrentTaskSm}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
              <Grid item sm={2} xs={false}></Grid>
            </Grid>
          </MobileOrTablet>
          <Desktop>
            <Grid className={classes.desktopPaddingTop} container>
              <Grid item md={8}>
                <img
                  alt="Start Deep Work"
                  src={this.state.urlCurrentTaskMd}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
              {this.getTextLayout({
                ...howItWorks.startDeepWork,
                alignText: "left",
              })}
            </Grid>
          </Desktop>
          {/* <-- Start a session */}
          {/* Improve over time --> */}
          <MobileOrTablet>
            <Grid
              className={classes.mobilePaddingTop}
              container
              style={{ paddingTop: 60 }}
            >
              {this.getTextLayout({
                ...howItWorks.improveOverTime,
              })}
              <Grid item sm={1} xs={false}></Grid>
              <Grid item sm={10} xs={12}>
                <Carousel autoPlay={true} navButtonsAlwaysVisible={false}>
                  {graphs.map((graph, i) => (
                    <div key={i}>{graph}</div>
                  ))}
                </Carousel>
              </Grid>
              <Grid item sm={1} xs={false}></Grid>
            </Grid>
          </MobileOrTablet>
          <Desktop>
            <Grid container style={{ paddingTop: 200 }}>
              {this.getTextLayout({
                ...howItWorks.improveOverTime,
                alignText: "left",
              })}
              <Grid item md={8} style={{ paddingLeft: 80 }}>
                <Carousel autoPlay={true} navButtonsAlwaysVisible={false}>
                  {graphs.map((graph, i) => (
                    <div key={i}>{graph}</div>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Desktop>
          {/* <-- Improve over time */}
          {/* Stay motivated --> */}
          <MobileOrTablet>
            <Grid className={classes.mobilePaddingTop} container>
              {this.getTextLayout({
                ...howItWorks.stayMotivated,
              })}
              <Grid item sm={1} xs={false}></Grid>
              <Grid item sm={10} style={{ paddingTop: 30 }} xs={12}>
                <DemoStats
                  isDemo={true}
                  justifyContainer="center"
                  tasks={tasks}
                  toolTipContent={toolTipContent}
                />
              </Grid>
              <Grid item sm={1} xs={false}></Grid>
            </Grid>
          </MobileOrTablet>
          <Desktop>
            <Grid container style={{ paddingTop: 200 }}>
              <Grid item md={8}>
                <Grid container>
                  <Grid item md={8}>
                    <DemoStats
                      isDemo={true}
                      justifyContainer="center"
                      tasks={tasks}
                      toolTipContent={toolTipContent}
                    />
                  </Grid>
                  <Grid item md={4}></Grid>
                </Grid>
              </Grid>
              {this.getTextLayout({
                ...howItWorks.stayMotivated,
                alignText: "left",
              })}
            </Grid>
          </Desktop>
          {/* <-- Stay motivated */}
          <Grid item xs={12} style={{ paddingTop: 150, paddingBottom: 50 }}>
            <Divider />
          </Grid>
          {user ? (
            <div></div>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Are You Ready to Try Winston?
                </Typography>
                <Typography
                  align={"center"}
                  style={{ fontSize: "1rem", paddingTop: 20 }}
                  variant="body1"
                >
                  We'll help you do an extra hour of deep work each day.
                </Typography>
                <Button
                  onClick={() => this.handleNewAccount("Bottom Button")}
                  style={{ marginTop: 25 }}
                  variant="contained"
                >
                  Start Free Trial
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ paddingTop: 50 }}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Footer />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

Landing.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(Landing));
