import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { getUID } from "./auth";

/**
 * Auth
 */
export const getElectronAuthRef = (id) => {
  return firebase.firestore().collection("temp-auth-codes").doc(id);
};

/**
 * General
 */
export const getCurrentStreakDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("general")
    .doc("currentStreak");
};

export const getProjectNamesDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("general")
    .doc("projectNames");
};

/**
 * Sessions
 */
export const getSessionCollectionRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("sessions");
};

export const getSessionIdDocRef = (sessionId) => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("sessions")
    .doc(sessionId);
};

/**
 * Settings
 */
export const getCalendarDoc = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("settings")
    .doc("calendar");
};

export const getEmailDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("settings")
    .doc("email");
};

export const getGoalsDoc = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("settings")
    .doc("goals");
};

export const getNotificationsCollectionRef = () => {
  return firebase.firestore().collection("notifications");
};

export const getNotificationsDoc = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("settings")
    .doc("notifications");
};

export const getSoundDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("settings")
    .doc("sound");
};

/**
 * Stripe
 */
export const getStripeCustomerDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("stripe")
    .doc("customerData");
};

/**
 * Tasks
 */
export const getTaskCollectionRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("tasks");
};

export const getTaskIdsDocRef = () => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("general")
    .doc("taskIds");
};

export const getTaskIdRef = (taskId) => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("tasks")
    .doc(taskId);
};

/**
 * To Do List
 */
export const getTdlDocRef = (projectName) => {
  return firebase
    .firestore()
    .collection("users")
    .doc(getUID())
    .collection("toDoList")
    .doc(projectName);
};

/**
 * Testing
 */
// export const getOtherUserDocShouldFail = () => {
//   return firebase
//     .firestore()
//     .collection("users")
//     .doc("WdmxiyEUxEdopSlUJPo0UskAjlt2")
//     .collection("general")
//     .doc("currentStreak");
// };

/**
 * Timeslots
 */
export const getTimeSlotCollectionRef = () => {
  return firebase.firestore().collection("timeslots");
};

export const getUserTimeSlotDocRef = (docId) => {
  return getTimeSlotCollectionRef()
    .doc(docId)
    .collection("users")
    .doc(getUID());
};

/**
 * User
 */
export const getUserDocRef = () => {
  return firebase.firestore().collection("users").doc(getUID());
};
