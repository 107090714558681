import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class ThanksDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>Successful Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your payment was successful, thanks so much for supporting Winston!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.closeThanksDialog()}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ThanksDialog.propTypes = {
  closeThanksDialog: PropTypes.func,
  history: PropTypes.object,
  open: PropTypes.bool,
};

export default withRouter(ThanksDialog);
