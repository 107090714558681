import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import { getMuiPropTypes } from "../utils/utils";

class PricingInfo extends React.Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Thanks for Joining!</Typography>
            <br />
            <Typography variant="body1">
              Just FYI, Winston is $4-8/mo after your 14-day trial ends. You
              don't need a card now.
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

PricingInfo.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(PricingInfo);
