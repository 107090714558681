import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";

import {
  getCurrentStreakDocRef,
  getCalendarDoc,
  getEmailDocRef,
  getGoalsDoc,
  getNotificationsDoc,
  getProjectNamesDocRef,
  getSessionCollectionRef,
  getSessionIdDocRef,
  getSoundDocRef,
  getStripeCustomerDocRef,
  getTaskCollectionRef,
  getTaskIdRef,
  getTaskIdsDocRef,
  getUserDocRef,
  getNotificationsCollectionRef,
} from "./refs";
import { getUID } from "./auth";

export const getUserSignUpDate = async () => {
  const userDoc = await getUserDocRef().get();
  if (userDoc.exists) {
    const { signUpDate } = userDoc.data();
    return signUpDate ? signUpDate.toDate() : null;
  }
  /* Should never be the case */
  return null;
};

export const getUserFirstName = async () => {
  const userDoc = await getUserDocRef().get();
  if (userDoc.exists) {
    return userDoc.data().firstName;
  }
  /* Should never be the case */
  return "";
};

export const updateFirebaseSession = async (sessionData) => {
  const {
    endTime,
    projects = [],
    projectName = "",
    sessionId,
    startTime,
    taskIds,
  } = sessionData;
  try {
    /* Save session data */
    const sessionIdRef = getSessionIdDocRef(sessionId);
    await sessionIdRef.set({
      endTime: endTime
        ? firebase.firestore.Timestamp.fromDate(new Date(endTime))
        : null,
      /* HACK -- backwards compatibility */
      projects: projects.length
        ? [...projects]
        : projectName
        ? [projectName]
        : [],
      sessionId,
      startTime: startTime
        ? firebase.firestore.Timestamp.fromDate(new Date(startTime))
        : null,
      taskIds,
    });
  } catch (error) {
    console.log("Error Updating Firebase Session Data: ", error);
  }
  /* Update user's timezone for daily report purposes */
  const userDocRef = getUserDocRef();
  userDocRef.update({
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

/**
 * Updates task ids
 */
export const updateTaskIds = async (taskIds) => {
  try {
    await getTaskIdsDocRef().set({ taskIds });
  } catch (error) {
    console.log("[updateTaskIds] Error updating task IDs");
  }
};

/**
 * Updates array of tasks by calling updateTask()
 */
export const updateTasks = async (tasks) => {
  /* https://medium.com/@antonioval/making-array-iteration-easy-when-using-async-await-6315c3225838 */
  try {
    await Promise.all(tasks.map(async (task) => await updateTask(task)));
  } catch (error) {
    console.log("Error Updating Firebase Session Tasks: ", error);
  }
};

/**
 * Updates individual firebase task
 */
export const updateTask = async (task) => {
  const { date, difficulty, focus, projectName } = task;
  console.log("Updating task in firebase...");
  /* TO DO -- should I make sure that task object fields are consistent here? */
  try {
    await getTaskIdRef(task.id).set({
      ...task,
      /* Date will be in ISO string format */
      date: date ? firebase.firestore.Timestamp.fromDate(new Date(date)) : null,
      difficulty: parseInt(difficulty),
      focus: parseInt(focus),
      projectName: projectName || "",
    });
  } catch (error) {
    console.log("Error saving task: ", error);
  }
};

export const updateProjectNames = async (projectNames) => {
  try {
    await getProjectNamesDocRef().set({
      projectNames: [...projectNames],
    });
  } catch (error) {
    console.log("[updateProjectNames] Error: ", error);
  }
};

export const deleteProjectName = async (projectName) => {
  await getProjectNamesDocRef().update({
    projectNames: firebase.firestore.FieldValue.arrayRemove(projectName),
  });
};

export const fetchCompletedTasks = async () => {
  const taskCollectionRef = getTaskCollectionRef();
  const snapshot = await taskCollectionRef
    .where("sessionId", "!=", "toDoList")
    .get();
  if (!snapshot.empty) return snapshot.docs.map((item) => item.data());
  return [];
};

export const taskExistsWithProjectName = async (projectName) => {
  const taskCollectionRef = getTaskCollectionRef();
  const snapshot = await taskCollectionRef
    .where("projectName", "==", projectName)
    .get();
  return !snapshot.empty;
};

export const fetchToDoListTasks = async () => {
  const taskCollectionRef = getTaskCollectionRef();
  const snapshot = await taskCollectionRef
    .where("sessionId", "==", "toDoList")
    .get();
  if (!snapshot.empty) return snapshot.docs.map((item) => item.data());
  return [];
};

export const fetchTaskIds = async () => {
  /* Query task IDs */
  const taskIdsRef = getTaskIdsDocRef();
  const taskIdsDoc = await taskIdsRef.get();
  if (taskIdsDoc.exists) {
    const { taskIds } = taskIdsDoc.data();
    return taskIds;
  }
  return [];
};

export const fetchSessions = async () => {
  const sessionCollectionRef = getSessionCollectionRef();
  const snapshot = await sessionCollectionRef.get();
  if (!snapshot.empty) return snapshot.docs.map((item) => item.data());
  return [];
};

export const fetchCurrentStreak = async () => {
  try {
    const doc = await getCurrentStreakDocRef().get();
    /* Update UI with currentStreak */
    if (doc.exists) {
      const { currentStreak } = doc.data();
      return currentStreak;
    }
  } catch (error) {
    console.log("[fetchCurrentStreak] Error fetching current streak: ", error);
  }
  return 0;
};

// this calculates all time avg:
// export const fetchHoursWorked = async ({ uid, updateDeepWorkAvg }) => {
//   const taskCollectionRef = getTaskCollectionRef(uid);
//   const snapshot = await taskCollectionRef
//     .where("sessionId", "!=", "toDoList")
//     .get();
//   if (!snapshot.empty) {
//     const tasks = snapshot.docs
//       .map((item) => item.data());
//     /* Calculate the time from all of the tasks and track # of days */
//     let earliestDate = new Date(), timeSecs = 0;
//     tasks.forEach(task => {
//       const { actualTime = 0, date: timeStamp } = task;
//       if (!timeStamp) return;
//       timeSecs += actualTime;
//       const date = timeStamp.toDate();
//       if (date < earliestDate) {
//         earliestDate = date
//       }
//     });
//     const timeHrs = Math.round(timeSecs / 3600);
//     const numDays = moment().diff(moment(earliestDate), 'days');
//     updateDeepWorkAvg(timeHrs/numDays);
//   }
// };

// export const fetchTasksCompleted = async ({ numDays, updateUI }) => {
//   /* Refs */
//   const taskCollectionRef = getTaskCollectionRef();

//   /* Find all tasks in the last {numDays} days */
//   const pastDate = firebase.firestore.Timestamp.fromDate(
//     moment.utc().subtract(numDays, "days").toDate()
//   );
//   const snapshot = await taskCollectionRef
//     .where("sessionId", "!=", "toDoList")
//     .get();

//   if (!snapshot.empty) {
//     const tasks = snapshot.docs
//       .map((item) => item.data())
//       .filter(({ date }) => date > pastDate);
//     /* Calculate the time from all of the tasks */
//     const tasksCompleted = tasks.reduce((acc, task) => {
//       if (task.completed) return acc + 1;
//       return acc;
//     }, 0);
//     updateUI({ tasksCompleted });
//   } else {
//     updateUI({ tasksCompleted: 0 });
//   }
// };

export const fetchProjectNames = async () => {
  /* Get data */
  const doc = await getProjectNamesDocRef().get();
  if (doc.exists) {
    const { projectNames } = doc.data();
    /* HACK -- filter out empty project names */
    return [...new Set(projectNames.sort().filter((name) => name))];
  }
  return [];
};

// export const showContinueSession = (updateUI) => {
//       /* (1) Find all session in the last 2 hours */
//       let pastDate = new Date();
//       pastDate.setHours(pastDate.getHours() - 3);
//       const sessionCollectionRef = getSessionCollectionRef(user.uid);
//       const snapshot = await sessionCollectionRef
//         .where("startTime", ">=", pastDate)
//         .get();
//       if (snapshot) {
//         /* (2) Find the most recent session */
//         let sessions = snapshot.docs.map((item) => item.data());
//         sessions.sort((a, b) => b.startTime.toDate() - a.startTime.toDate());
//         updateUI({ recentSession: sessions[0] });
//       } else {
//         updateUI(null);
//       }
// };

export const fetchPreviousSessions = async () => {
  let previousSessions = [];
  /* Fetch all sessions */
  const sessionCollectionRef = getSessionCollectionRef();
  const snapshot = await sessionCollectionRef.get();
  // debugger;
  if (!snapshot.empty) {
    /* Sort sessions with most recent first */
    previousSessions = snapshot.docs
      .map((item) => item.data())
      .filter((session) => session.startTime)
      .map((session) => {
        return {
          ...session,
          startTime: session.startTime.toDate().toISOString(),
          endTime: session.endTime
            ? session.endTime.toDate().toISOString()
            : null,
        };
      });
    previousSessions.sort(
      (a, b) => new Date(b.startTime) - new Date(a.startTime)
    );
  }
  return previousSessions;
};

export const fetchPreviousSessionById = async (sessionId) => {
  /* Fetch session by id */
  const sessionIdDocRef = getSessionIdDocRef(sessionId);
  const session = await sessionIdDocRef.get();
  if (session.exists) {
    const tasks = await getTasksFromSessions([sessionId]);
    const sessionData = session.data();
    sessionData.startTime = sessionData.startTime
      ? sessionData.startTime.toDate().toISOString()
      : null;
    sessionData.endTime = sessionData.endTime
      ? sessionData.endTime.toDate().toISOString()
      : null;
    return {
      ...sessionData,
      tasks: tasks[0].map((t) => ({
        ...t,
        date: t.date ? t.date.toDate().toISOString() : null,
      })),
    };
  }
  return {
    session: {
      tasks: [],
    },
  };
};

const getTasksFromSessions = async (sessionIds) => {
  return await Promise.all(
    sessionIds.map(async (sessionId) => {
      const taskCollectionRef = getTaskCollectionRef();
      const snapshot = await taskCollectionRef
        .where("sessionId", "==", sessionId)
        .get();
      return snapshot.empty ? [] : snapshot.docs.map((item) => item.data());
    })
  );
};

const getTaskIdsFromSession = async (sessionId) => {
  try {
    const sessionDoc = await getSessionIdDocRef(sessionId).get();
    if (sessionDoc.exists) {
      const sessionData = sessionDoc.data();
      return [...sessionData.taskIds];
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error fetching task ids from session: ", error);
    return [];
  }
};

const deleteTasks = async (taskIds) => {
  try {
    await Promise.all(taskIds.map(async (id) => await deleteTask(id)));
  } catch (error) {
    console.log("Error Updating Firebase Session Tasks: ", error);
  }
};

export const deleteTask = async (taskId) => {
  try {
    /* Delete task */
    const taskIdRef = getTaskIdRef(taskId);
    await taskIdRef.delete();
  } catch (err) {
    console.log("Error deleting Task: ", err);
  }
};

export const deleteSession = async (sessionId) => {
  try {
    /* Delete tasks associated with session */
    const taskIds = await getTaskIdsFromSession(sessionId);
    await deleteTasks(taskIds);
    /* Delete session */
    await getSessionIdDocRef(sessionId).delete();
  } catch (err) {
    console.log("Error deleting session: ", err);
  }
};

/* Currently not used, but can be if need only a few sessions */
// const getRecentSessions = async ({ previousSessions, uid }) => {
//   /* (1) Get max of three most recent sessions */
//   let recentSessions = [];
//   if (previousSessions.length) {
//     recentSessions = previousSessions.slice(
//       0,
//       Math.min(previousSessions.length, 3)
//     );
//   }
//   /* (2) Fetch task data for recent sessions */
//   if (recentSessions.length) {
//     recentSessions = await getTasksFromSessions(recentSessions);
//   }
// };

/* https://firebase.google.com/docs/storage/web/download-files */
export const fetchMediaURL = async (path) => {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  try {
    return await storageRef.child(path).getDownloadURL();
  } catch (error) {
    console.log("Error fetching media file: ", error);
    return "";
  }
};

/* NOTIFICATIONS --> */

/**
 * Listens for notifs and handles them
 */
// export const onMessageListener = () => {
//   try {
//     return new Promise((resolve) => {
//       firebase.messaging().onMessage((payload) => {
//         console.log("Message payload: ", payload);
//         resolve(payload);
//       });
//     });
//   } catch (error) {
//     console.log("[onMessageListener] Error: ", error);
//     return null;
//   }
// };

/**
 * Asks browser if we can send notifications.
 * If so, get a token that server will use to
 * send notifications to this browser.
 */
export const requestFirebaseNotificationPermission = () => {
  let messaging;
  try {
    messaging = firebase.messaging();
  } catch (error) {
    console.log(
      "[requestFirebaseNotificationPermission] Firebase messaging error: ",
      error
    );
    return;
  }
  return new Promise((resolve, reject) => {
    messaging
      .getToken({
        vapidKey:
          "BPVSe-ZdYGVFqY5ffnR2g8kXoBlvr9iWfNnbY40eLvsWtHVqqumawmIBZ26RJry-zOSg2sdNeTQmCE_O-Mrz9qI",
      })
      .then(async (firebaseToken) => {
        const notifDocRef = getNotificationsDoc();
        /* Write to firebase */
        await notifDocRef.set(
          {
            firebaseBrowserToken: firebaseToken,
          },
          { merge: true }
        );
        resolve(firebaseToken);
      })
      .catch((error) => {
        console.log(
          "[requestFirebaseNotificationPermission] Rejecting with ",
          error
        );
        reject("");
      });
  });
};

/**
 * Get user's email preferences
 */
export const getEmailPreferences = async () => {
  const docRef = getEmailDocRef();
  try {
    const doc = await docRef.get();
    /* Write to firebase */
    if (doc.exists) {
      return {
        ...doc.data(),
      };
    }
  } catch (error) {
    console.log("[getNotifPreferences] ", error);
  }
  return {};
};

/**
 * Get user's notif preferences
 */
export const getNotifPreferences = async () => {
  const notifDocRef = getNotificationsDoc();
  try {
    const doc = await notifDocRef.get();
    /* Write to firebase */
    if (doc.exists) {
      const data = doc.data();
      return {
        ...data,
      };
    }
  } catch (error) {
    console.log("Error setting notification preferences");
  }
  return {};
};

/**
 * Update user's notif preferences
 */
export const setNotifPreferences = async (enabled) => {
  const notifDocRef = getNotificationsDoc();
  try {
    const doc = await notifDocRef.get();
    /* Write to firebase */
    if (doc.exists) {
      await notifDocRef.update({
        afternoonNotif: enabled,
        morningNotif: enabled,
      });
    } else {
      await notifDocRef.set({
        afternoonNotif: enabled,
        morningNotif: enabled,
      });
    }
  } catch (error) {
    console.log("Error setting notification preferences");
  }
};

export const updateAfternoonNotif = async (enabled) => {
  const notifDocRef = getNotificationsDoc();
  try {
    await notifDocRef.update({
      afternoonNotif: enabled,
    });
  } catch (error) {
    console.log("[updateAfternoonNotif] ", error);
  }
};

export const updateMorningNotif = async (enabled) => {
  const notifDocRef = getNotificationsDoc();
  try {
    await notifDocRef.update({
      morningNotif: enabled,
    });
  } catch (error) {
    console.log("[updateMorningNotif] ", error);
  }
};

export const saveNotifData = async () => {
  const collectionRef = getNotificationsCollectionRef();
  try {
    collectionRef.add({
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      to: getUID(),
    });
  } catch (error) {
    console.log("[saveNotifData] ", error);
  }
};

export const updateEmailSettings = async (settingObj) => {
  const docRef = getEmailDocRef();
  try {
    await docRef.set(
      {
        ...settingObj,
      },
      { merge: true }
    );
  } catch (error) {
    console.log("[updateEmailSettings] ", error);
  }
};

export const getSoundPreferences = async () => {
  const docRef = getSoundDocRef();
  try {
    const doc = await docRef.get();
    if (doc.exists) {
      return doc.data().endBell;
    }
  } catch (error) {
    console.log("[getSoundPreferences] ", error);
  }
  return true;
};

export const updateSoundSettings = async (enabled) => {
  try {
    await getSoundDocRef().set(
      {
        endBell: enabled,
      },
      { merge: true }
    );
  } catch (error) {
    console.log("[updateSoundSettings] ", error);
    alert(
      "Error updating sound preferences, please refresh the page and try again."
    );
  }
};

/**
 * Update user's settings re calendar invite
 */
export const updateUserCalendarSettings = async (dailyInvite) => {
  /* Write to firebase that this user has their recurring email turned on */
  const calDocRef = getCalendarDoc();
  try {
    await calDocRef.set({
      dailyInvite,
    });
  } catch (error) {
    console.log("Error updating calendar settings in DB: ", error);
  }
};

/**
 * Get daily goal data
 */
export const getDailyGoalData = async () => {
  const goalsDocRef = getGoalsDoc();
  try {
    const doc = await goalsDocRef.get();
    if (doc.exists) {
      return {
        ...doc.data(),
      };
    }
  } catch (error) {
    console.log("[getDailyGoalData] ", error);
  }
  return {};
};

/**
 * Update daily goal preferences
 */
export const updateDailyGoal = async (dailyGoal) => {
  const goalsDocRef = getGoalsDoc();
  try {
    await goalsDocRef.set(
      {
        dailyGoal,
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    console.log("[updateDailyGoal] ", error);
  }
};

/**
 * Stripe
 */
export const getStripeCustomerId = async () => {
  try {
    /* Read data */
    const customerRef = getStripeCustomerDocRef();
    const doc = await customerRef.get();
    if (doc.exists) {
      const { customerId } = doc.data();
      return customerId;
    }
  } catch (error) {
    console.log(
      "[getStripeCustomerId] Error getting stripe customer id: ",
      error
    );
    return "";
  }
};
