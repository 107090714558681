import React from "react";
import { Droppable } from "react-beautiful-dnd";

import { getMuiPropTypes } from "../utils/utils";

import TaskCard from "../task-card/TaskCard";
import { getTimeLabelText } from "../task-card";

class Column extends React.Component {
  render() {
    const {
      addNewProjectName,
      deleteTask,
      droppableId = "toDoList",
      editTasks,
      handleDeleteProjectLabel,
      isNewUser,
      projectNames,
      tasks,
    } = this.props;
    return (
      <div>
        <Droppable droppableId={droppableId}>
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map((task, index) => {
                  return (
                    <TaskCard
                      addNewProjectName={addNewProjectName}
                      completed={task.completed}
                      deleteTask={deleteTask}
                      draggableIndex={index}
                      editTasks={editTasks}
                      enableDragging={!task.completed}
                      handleDeleteProjectLabel={handleDeleteProjectLabel}
                      hideAddTaskButton={true}
                      isNewUser={isNewUser}
                      isRootTask={false}
                      key={task.id}
                      projectNames={projectNames}
                      projectValue={task.projectName}
                      taskId={task.id}
                      textFieldValue={task.value}
                      timeLabelText={getTimeLabelText(task)}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    );
  }
}

Column.propTypes = {
  ...getMuiPropTypes(),
};

export default Column;
