import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from "victory";

import { getAllDaysFromRange } from "../utils/utils";

class LineGraph extends React.Component {
  render() {
    const { data, filter, labelText, tickValues } = this.props;

    /* Styling */
    const chartLabel = {
      fill: "white",
      fontFamily: "Roboto",
      fontSize: 10,
      marginBlock: 20,
    };
    const defaultPadding = { bottom: 50, left: 50, right: 50, top: 50 };

    let tickValuesXAxis = [];
    if (filter === 7) {
      /* Show last 7 days */
      tickValuesXAxis = [
        ...getAllDaysFromRange({
          earliestDate: moment().subtract(6, "days").toDate(),
          latestDate: new Date(),
        }),
      ];
    } else if (filter === 14) {
      /* Show 7 of the last 14 days */
      tickValuesXAxis = [
        ...getAllDaysFromRange({
          earliestDate: moment().subtract(13, "days").toDate(),
          latestDate: new Date(),
        }).filter((item, index) => index % 2 === 0),
      ];
    } else if (filter === 30) {
      /* Show only Sundays in the last 30 days */
      tickValuesXAxis = [
        ...getAllDaysFromRange({
          earliestDate: moment().subtract(29, "days").toDate(),
          latestDate: new Date(),
        }).filter((date) => moment(date).weekday() === 0),
      ];
    } else if (filter === null) {
      /* Show max of 5 Sundays of all time */
      const earliestDate = data[0].date;
      const allSundays = getAllDaysFromRange({
        earliestDate,
        latestDate: new Date(),
      }).filter((date) => moment(date).weekday() === 0);
      const numToSubtract = parseInt(allSundays.length / 5);
      for (let i = allSundays.length - 1; i >= 0; i -= numToSubtract) {
        tickValuesXAxis.push(allSundays[i]);
      }
    }

    /* Pad missing data */
    const missingData = [];
    tickValuesXAxis.forEach((d) => {
      if (!data.some(({ date }) => moment(date).isSame(moment(d), "day"))) {
        missingData.push({
          date: new Date(d),
          [this.props.yAxis]: 0,
        });
      }
    });
    const newData = missingData.concat(data);
    newData.sort((a, b) => a.date - b.date);

    return (
      <>
        <VictoryChart
          domainPadding={20}
          padding={this.props.padding || defaultPadding}
          scale={{ x: "time" }}
        >
          {labelText && (
            <VictoryLabel
              style={chartLabel}
              text={labelText}
              textAnchor="middle"
              x={225}
              y={30}
            />
          )}
          <VictoryAxis
            style={{
              axis: { stroke: "white" },
              axisLabel: { fontFamily: "Roboto" },
              tickLabels: { ...chartLabel, padding: 20 },
            }}
            /* https://momentjs.com/docs/#/displaying/format/ */
            tickFormat={(date) => moment(date).format("ddd, M/D")}
            tickLabelComponent={<VictoryLabel angle={-30} />}
            tickValues={tickValuesXAxis}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "white" },
              /* https://spectrum.chat/victory/general/grid-lines~7ca12536-c503-4c85-b103-f3ad5f48c20e */
              grid: { stroke: "#818e99", strokeWidth: 0.5 },
              tickLabels: chartLabel,
            }}
            tickFormat={(y) => `${y} ${this.props.tickFormatUnit}`}
            tickValues={tickValues}
          />
          <VictoryLine
            data={newData}
            style={{
              data: { stroke: "#1EB980" },
            }}
            x="date"
            y={this.props.yAxis}
          />
        </VictoryChart>
      </>
    );
  }
}

LineGraph.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.number, // could be null tho...
  labelText: PropTypes.string,
  padding: PropTypes.object,
  tickFormatUnit: PropTypes.string,
  tickValues: PropTypes.array,
  yAxis: PropTypes.string,
};

export default LineGraph;
