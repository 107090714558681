import "./App.css";
import React from "react";
import isElectron from "is-electron";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch, withRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { userSignedIn } from "./firebase/auth";
import { saveNotifData } from "./firebase/index";
import { logPageView } from "./analytics/index";
// import { launchIntercom } from "./intercom/index";
import { isTestEnv, TIME_MAP } from "./utils/utils";
import { shouldSendNotification } from "./utils/server";
import { darkTheme } from "./theme/theme";

import Home from "./components/Home";
import SessionParent from "./session/SessionParent";
import AllSessionsParent from "./previous-sessions/all-sessions/AllSessionsParent";
import PreviousSessionParent from "./previous-sessions/specific-session/PreviousSessionParent";
import LogIn from "./components/LogIn";
import Landing from "./landing-page/Landing";
import SignUp from "./components/SignUp";
import ResetPassword from "./components/ResetPassword";
import Loading from "./components/Loading";
import DeepWorkInfo from "./components/DeepWorkInfo";
import Tutorial from "./components/Tutorial";
import Onboarding from "./onboarding/Parent";
import ChoosePlan from "./onboarding/ChoosePlan";
import SideBar from "./components/SideBar";
import CustomAppBar from "./components/CustomAppBar";
import Settings from "./components/Settings";
import DesktopAuth from "./components/DesktopAuth";
import Contact from "./components/Contact";

/* https://stackoverflow.com/questions/52724447/how-to-use-google-analytics-with-react */
const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTask: false,
      isNewUser: false,
      loading: true,
      pathname: this.props.history.location.pathname,
      sideBarExpanded: true,
      user: null,
    };
  }

  componentDidMount() {
    userSignedIn(this.updateAuth);
    history.listen((location) => {
      logPageView(location.pathname + location.search);
      this.setState({
        pathname: location.pathname,
      });
    });
    if (!isTestEnv()) {
      window.addEventListener("beforeunload", this.handleUnload);
    }
    /* Generate notifications on interval for Electron */
    if (isElectron()) {
      /* Don't send if app was just opened up */
      setTimeout(
        this.handleElectronNotifs,
        isTestEnv() ? TIME_MAP.ONE_SECOND : TIME_MAP.ONE_HOUR_AND_A_HALF
      );
    }
  }

  componentWillUnmount() {
    if (!isTestEnv()) {
      window.removeEventListener("beforeunload", this.handleUnload);
    }
  }

  handleElectronNotifs = () => {
    this.notifIntervalId = setInterval(
      async () => {
        /* https://github.com/electron/electron/issues/7300#issuecomment-285885725 */
        const { ipcRenderer } = window.require("electron");
        const windowIsFocused = ipcRenderer.sendSync("synchronous-message");
        const shouldSendNotif = isTestEnv()
          ? true
          : await shouldSendNotification();
        if (shouldSendNotif && !windowIsFocused && !this.state.activeTask) {
          new Notification("Deep Work Time?", {
            body: `Here's your ${
              new Date().getHours() < 12 ? "morning" : "afternoon"
            } reminder to get a deep work session in!`,
          });
          /* Write notif data to DB */
          await saveNotifData();
        }
      },
      isTestEnv() ? TIME_MAP.ONE_SECOND * 5 : TIME_MAP.ONE_HOUR
    );
  };

  /**
   * In production, Electron loads /index.html which is not found by
   * React Router. Therefore, we need to redirect when we see this
   * happening.
   * https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron
   */
  handleElectronRoute = () => {
    return (
      window.location.pathname.includes("index.html") && <Redirect to="/" />
    );
  };

  handleUnload = (e) => {
    if (this.state.activeTask) {
      const message =
        "You have an active task running. You should finish it before closing this page.";
      (e || window.event).returnValue = message; // Gecko + IE
      return message;
    }
  };

  toggleSideBar = () => {
    this.setState({
      sideBarExpanded: !this.state.sideBarExpanded,
    });
  };

  updateAuth = ({ isNewUser, redirect, user }) => {
    // launchIntercom(user);
    this.setState(
      {
        isNewUser,
        loading: false,
        user: user ? { ...user } : null,
      },
      () => {
        if (user && redirect) {
          history.push(redirect);
        }
      }
    );
  };

  updateActiveTask = ({ activeTask, route }) => {
    this.setState(
      {
        activeTask,
      },
      () => {
        if (route) {
          history.push(route);
        }
      }
    );
  };

  render() {
    const { isNewUser, loading, pathname, user } = this.state;
    const userProps = {
      isNewUser,
      user,
    };

    const sharedComponents = [
      <Route
        exact
        path="/contact"
        render={(props) => <Contact {...props} {...userProps} />}
      />,
      <Route
        exact
        path="/deep-work"
        render={(props) => <DeepWorkInfo {...props} {...userProps} />}
      />,
      <Route
        exact
        path="/learn"
        render={(props) => <Tutorial {...props} {...userProps} />}
      />,
    ];

    if (loading) {
      return (
        <div>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Loading {...userProps} />
          </ThemeProvider>
        </div>
      );
    }

    if (pathname === "/desktop-auth") {
      return (
        <div>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <DesktopAuth />
          </ThemeProvider>
        </div>
      );
    }

    if (user) {
      return (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Router history={history}>
            {this.handleElectronRoute()}
            <div className="App" style={{ display: "flex" }}>
              {pathname === "/onboarding" ? (
                <div>
                  <CustomAppBar
                    fullWidth={true}
                    pathname={pathname}
                    {...userProps}
                  />
                </div>
              ) : (
                <>
                  <SideBar
                    activeTask={this.state.activeTask}
                    expanded={this.state.sideBarExpanded}
                    toggleSideBar={this.toggleSideBar}
                    updateActiveTask={this.updateActiveTask}
                  />
                  <CustomAppBar
                    activeTask={this.state.activeTask}
                    sideBarExpanded={this.state.sideBarExpanded}
                    pathname={pathname}
                    updateActiveTask={this.updateActiveTask}
                    {...userProps}
                  />
                </>
              )}
              <div
                style={{
                  /* Grows to take up all the available space */
                  flexGrow: 1,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingTop: 30 + 64,
                }}
              >
                <Switch>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {sharedComponents.map((route, index) => (
                      <div key={index}>{route}</div>
                    ))}
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <Home
                          {...props}
                          {...userProps}
                          sideBarExpanded={this.state.sideBarExpanded}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/choose"
                      render={(props) => (
                        <ChoosePlan {...props} {...userProps} />
                      )}
                    />
                    <Route
                      exact
                      path="/onboarding"
                      render={(props) => (
                        <Onboarding {...props} {...userProps} />
                      )}
                    />
                    <Route
                      path="/partners"
                      exact
                      render={(props) => (
                        <Onboarding {...props} {...userProps} partnerPage={2} />
                      )}
                    />
                    <Route
                      path="/previous"
                      exact
                      render={(props) => (
                        <AllSessionsParent {...props} {...userProps} />
                      )}
                    />
                    <Route
                      path="/previous/:sessionId"
                      render={(props) => (
                        <PreviousSessionParent {...props} {...userProps} />
                      )}
                    />
                    <Route
                      path="/settings"
                      exact
                      render={(props) => <Settings {...props} {...userProps} />}
                    />
                    <Route
                      path="/start-session"
                      exact
                      render={(props) => (
                        <SessionParent
                          {...props}
                          {...userProps}
                          activeTask={this.state.activeTask}
                          updateActiveTask={this.updateActiveTask}
                        />
                      )}
                    />
                    {/* <Route path="*">
                      <InvalidRoute/>
                    </Route> */}
                  </MuiPickersUtilsProvider>
                </Switch>
              </div>
            </div>
          </Router>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router history={history}>
          {this.handleElectronRoute()}
          <div className="App">
            <CustomAppBar fullWidth={true} pathname={pathname} {...userProps} />
            <div style={{ paddingTop: 30 + 64 }}>
              <Switch>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {sharedComponents.map((route, index) => (
                    <div key={index}>{route}</div>
                  ))}
                  <Route
                    exact
                    path="/"
                    render={(props) => <Landing {...props} {...userProps} />}
                  />
                  <Route
                    exact
                    path="/login"
                    render={(props) => <LogIn {...props} {...userProps} />}
                  />
                  <Route
                    exact
                    path="/pricing"
                    render={(props) => <ChoosePlan {...props} {...userProps} />}
                  />
                  <Route
                    path="/reset"
                    exact
                    render={(props) => (
                      <ResetPassword {...props} {...userProps} />
                    )}
                  />
                  <Route
                    path="/signup"
                    exact
                    render={(props) => <SignUp {...props} {...userProps} />}
                  />
                  {/* <Route path="*">
                    <InvalidRoute/>
                  </Route> */}
                </MuiPickersUtilsProvider>
              </Switch>
            </div>
          </div>
        </Router>
      </ThemeProvider>
    );
  }
}

export default withRouter(App);
