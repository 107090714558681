import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getMuiPropTypes } from "../utils/utils";

const styles = () => ({
  typography: {
    cursor: "pointer",
  },
});

class Footer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid
          alignItems="flex-start"
          container
          justify="center"
          style={{ paddingBottom: 50 }}
        >
          <Grid item sm={4} xs={3}>
            <Grid container justify="center">
              <Grid item sm={3} xs={1}></Grid>
              <Grid item sm={6} xs={10}>
                <Typography align="left" style={{ fontWeight: 600 }}>
                  Start
                </Typography>
                <Typography
                  align="left"
                  className={classes.typography}
                  onClick={() => this.props.history.push("/login")}
                >
                  Log In
                </Typography>
                <Typography
                  align="left"
                  className={classes.typography}
                  onClick={() => this.props.history.push("/signup")}
                >
                  Sign Up
                </Typography>
              </Grid>
              <Grid item sm={3} xs={1}></Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={6}>
            <Grid container justify="center">
              <Grid item sm={2} xs={1}></Grid>
              <Grid item sm={8} xs={10}>
                <Typography align="left" style={{ fontWeight: 600 }}>
                  Learn
                </Typography>
                <Typography
                  align="left"
                  className={classes.typography}
                  onClick={() => this.props.history.push("/deep-work")}
                >
                  What's Deep Work?
                </Typography>
                <Typography
                  align="left"
                  className={classes.typography}
                  onClick={() => this.props.history.push("/learn")}
                >
                  Watch Tutorial
                </Typography>
              </Grid>
              <Grid item sm={2} xs={1}></Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={3}>
            <Grid container justify="center">
              <Grid item sm={3} xs={1}></Grid>
              <Grid item sm={6} xs={10}>
                <Typography align="left" style={{ fontWeight: 600 }}>
                  Contact
                </Typography>
                <Typography align="left" className={classes.typography}>
                  <a
                    href="mailto:arjun@trywinston.com"
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    Email Us
                  </a>
                </Typography>
              </Grid>
              <Grid item sm={3} xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

Footer.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(Footer));
