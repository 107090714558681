import React from "react";
import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
} from "victory";

import { getAllDaysFromRange, getMuiPropTypes } from "../utils/utils";

class StackedGraph extends React.Component {
  render() {
    const { filter, labelText, legend, stackedData, tickValues } = this.props;

    /* Styling */
    const chartLabel = {
      fill: "white",
      fontFamily: "Roboto",
      fontSize: 10,
      marginBlock: 20,
    };
    const defaultPadding = { bottom: 50, left: 50, right: 50, top: 50 };

    let tickValuesXAxis = [];
    if (filter === "week") {
      /* Show last 7 days */
      tickValuesXAxis = [
        ...getAllDaysFromRange({
          earliestDate: moment().subtract(6, "days").startOf("day").toDate(),
          latestDate: new Date(),
        }),
      ];
    } else {
      /* Show 7 of the last 14 days */
      tickValuesXAxis = [
        ...getAllDaysFromRange({
          earliestDate: moment().subtract(13, "days").toDate(),
          latestDate: new Date(),
        }).filter((item, index) => index % 2 === 0),
      ];
    }

    return (
      <>
        <VictoryChart
          domainPadding={20}
          padding={this.props.padding || defaultPadding}
          scale={{ x: "time" }}
        >
          {labelText && (
            <VictoryLabel
              style={chartLabel}
              text={labelText}
              textAnchor="middle"
              x={225}
              y={30}
            />
          )}
          <VictoryAxis
            // fixLabelOverlap={true}
            style={{
              axis: { stroke: "white" },
              axisLabel: { fontFamily: "Roboto" },
              tickLabels: { ...chartLabel, padding: 20 },
            }}
            /* https://momentjs.com/docs/#/displaying/format/ */
            tickFormat={(date) => moment(date).format("ddd, M/D")}
            tickLabelComponent={<VictoryLabel angle={-30} />}
            tickValues={tickValuesXAxis}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "white" },
              /* https://spectrum.chat/victory/general/grid-lines~7ca12536-c503-4c85-b103-f3ad5f48c20e */
              grid: { stroke: "#818e99", strokeWidth: 0.5 },
              tickLabels: chartLabel,
            }}
            tickFormat={(y) => `${y} hrs`}
            tickValues={tickValues}
          />
          <VictoryStack>
            {stackedData.map((data, index) => (
              <VictoryBar
                data={data}
                key={index}
                style={{
                  data: { fill: legend[index].fill },
                }}
                x="date"
                y="hours"
              />
            ))}
          </VictoryStack>
        </VictoryChart>
      </>
    );
  }
}

StackedGraph.propTypes = {
  ...getMuiPropTypes(),
};

export default StackedGraph;
