import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import firebase from "firebase/app";
import "firebase/messaging";

import "./index.css";
import Root from "./Root";
import { registerServiceWorker } from "./serviceWorker";
import { logPageView } from "./analytics/index";
// import { initIntercom } from "./intercom/index";

/* Intercom */
// initIntercom();

/* Initialize Google Analytics */
ReactGA.initialize("UA-179952269-1");
/* Handles initial page */
logPageView(window.location.pathname + window.location.search);

/* Initialize Firebase */
const firebaseConfig = {
  apiKey: "AIzaSyAyD-vXi-L9r_Vxyf6sEvj4ObrIrspBy9g",
  authDomain: "deepwork-c7713.firebaseapp.com",
  databaseURL: "https://deepwork-c7713.firebaseio.com",
  projectId: "deepwork-c7713",
  storageBucket: "deepwork-c7713.appspot.com",
  messagingSenderId: "1065756874488",
  appId: "1:1065756874488:web:a47e0ad7188f9b0e1d4d0d",
  measurementId: "G-4CG3VMPF5V",
};
firebase.initializeApp(firebaseConfig);

/* Set up listener for notifcations */
/* TO DO - add engagement logic here */
/**
 * Tutorial: https://www.smashingmagazine.com/2020/06/firebase-push-notifications-react/
 * How tutorial uses toast: https://github.com/chidimo/Fireact/blob/master/client/src/Messaging.js
 */
if (firebase.messaging.isSupported()) {
  try {
    firebase.messaging().onMessage((payload) => {
      console.log("Message payload: ", payload);
    });
  } catch (error) {
    console.log("Error with firebase messaging: ", error);
  }
}

registerServiceWorker();

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
