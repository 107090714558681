import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getMuiPropTypes } from "../utils/utils";

const styles = (theme) => ({
  /* Switch --> */
  checked: {},
  switchRoot: {
    /* https://stackoverflow.com/questions/56812652/positioning-material-ui-label-to-the-left-with-left-alignment */
    display: "flex",
    justifyContent: "space-between",
  },
  switchBase: {
    color: "#bdbdbd",
    "&$checked": {
      color: theme.palette.customButtons.backgroundColor.default,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.customButtons.backgroundColor.default,
    },
  },
  track: {},
  /* <-- Switch */
});

class FindPartners extends React.Component {
  render() {
    const { selectedTimes, timezone } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Find an Accountability Buddy</Typography>
          <br />
          <Typography align="center" variant="body1">
            Want a partner to do deep work with? Select the best starting times
            for you. <b>Each session will be two hours and on weekdays.</b>
          </Typography>
          <br />
          <Typography align="center" variant="subtitle2">
            <i>(times are in the {timezone} time zone)</i>
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 40 }} xs={12}>
          <Grid container spacing={2}>
            {selectedTimes.map((calObj, index) => (
              <Grid item key={index} xs={3}>
                <Button
                  disabled={calObj.disabled}
                  onClick={() => this.props.handleTimeSelection(calObj.text)}
                  style={{ backgroundColor: calObj.selected ? "#BB86FC" : "" }}
                  variant={"outlined"}
                >
                  {calObj.text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FindPartners.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(FindPartners));
