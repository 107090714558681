import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";

import { getGoogleRedirectResult, googleRedirect } from "../firebase/auth";

import { getMuiPropTypes } from "../utils/utils";

import Loading from "../components/Loading";
import { generateAuthToken } from "../utils/server";

const styles = () => ({
  buttonGoogle: {
    background: "#2D46B9",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
});

class DesktopAuth extends React.Component {
  constructor(props) {
    super(props);
    const { redirectId = "" } = queryString.parse(
      this.props.history.location.search
    );
    this.state = {
      appWindow: window.open("winston://open=true"),
      loading: true,
      redirectId,
      success: false,
    };
  }

  async componentDidMount() {
    const result = await getGoogleRedirectResult();
    if (result.user) {
      const token = await result.user.getIdToken();
      const success = await generateAuthToken({
        electronId: this.state.redirectId,
        token,
      });
      if (!success) {
        alert("Error generating token, please try signing in again.");
      }
      this.setState(
        {
          loading: false,
          success,
        },
        this.redirectToElectron
      );
    } else {
      googleRedirect();
    }
  }

  redirectToElectron = () => {
    /* https://www.yaplex.com/blog/avoid-browser-pop-up-blockers */
    if (this.state.success && this.state.appWindow) {
      this.state.appWindow.focus();
    }
  };

  // handleSignInWithGoogle = async () => {
  //   logAuthEvent({ action: "Log In", label: "Google" });
  //   if (isElectron()) {
  //     electronGoogleAuth();
  //     return;
  //   }
  //   const { errorMessage, googleError, userSignedIn } = await signInWithGoogle(
  //     this.state.redirectId
  //   );
  //   if (!userSignedIn) {
  //     this.setState({
  //       errorMessage,
  //       googleError,
  //       userSignedIn,
  //     });
  //   }
  // };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.success) {
      return (
        <Container maxWidth="sm">
          <Grid
            alignItems="center"
            container
            justify="center"
            style={{
              height: "100vh",
            }}
          >
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                You're signed in! If you don't automatically redirect to the
                app, you can click <a href="winston://open=true">this</a> link.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    }

    return (
      <Container maxWidth="sm">
        <Grid container justify="center">
          <Grid item xs={12}>
            <Button
              classes={{ root: classes.buttonGoogle }}
              style={{ marginTop: 20, marginBottom: 10 }}
              variant="contained"
              fullWidth
              onClick={this.handleSignInWithGoogle}
            >
              Log In with Google
            </Button>
            <FormHelperText style={{ marginLeft: 8 }}>
              Please click to continue logging in via Google
            </FormHelperText>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

DesktopAuth.propTypes = {
  ...getMuiPropTypes(),
};

export default withRouter(withStyles(styles)(DesktopAuth));
