import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

import { logAcquisitionEvent } from "../analytics/index";

class TutorialVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentageWatched: 0,
    };
  }

  componentWillUnmount() {
    const { percentageWatched } = this.state;
    if (percentageWatched > 0) {
      const bracket =
        percentageWatched <= 25
          ? 25
          : percentageWatched <= 50
          ? 50
          : percentageWatched <= 75
          ? 75
          : 100;
      logAcquisitionEvent({
        action: "Watched Tutorial",
        label: this.props.analyticsLabel,
        value: bracket,
      });
    }
  }

  logVideoProgress = (data) => {
    const { played } = data;
    this.setState({
      percentageWatched: (played * 100).toFixed(0),
    });
  };

  render() {
    const { height, width } = this.props;
    return (
      <ReactPlayer
        controls={true}
        height={height || "100%"}
        onProgress={this.logVideoProgress}
        playbackRate={1.1}
        url={this.props.url}
        width={width || "100%"}
      />
    );
  }
}

TutorialVideo.propTypes = {
  analyticsLabel: PropTypes.string,
  height: PropTypes.number,
  url: PropTypes.string,
  width: PropTypes.number,
};

export default TutorialVideo;
